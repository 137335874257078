import React, { useEffect, useState, useRef } from 'react';
// import { APP_KEY, API_URL } from './../config';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { hideCCNumber, instantTopupClaimStatus, instantTopupStatus } from './../../Utils/Common';
import { INSTANT_TOPUP_GET } from './../../Utils/appConstants';
import { getRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { DatePicker } from 'antd';
// import { ArrowRightOutlined } from '@ant-design/icons';
import { getUTCToLocalDateTimeFormat, queryDate, toDayDate } from '../../Utils/function';

function InstantTopup(props) {
  // console.log("window.location.hostname ---------------",props.location.state.isClaimed)
  const history = useHistory();
  const csvLink = useRef()
  const limit = 10;
  const dateFormat = 'DD-MM-YYYY';
  const [endDate, setEndDate] = useState(toDayDate());
  const [startDate, setStartDate] = useState(toDayDate());
  const [getData, setData] = useState([]);
  const [getCsvData, setCsvData] = useState([]);
  const [getTotalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(1);
  let isClaimed = false
  if(props && props.location && props.location.state && props.location.state.isClaimed) {
    isClaimed = true;
  }

  const columns = [{
    name: 'Date',
    cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
    width: "200px"
  }, {
    name: 'Trans ID',
    selector: row => row.transactionId,
    width: "150px"
  }, {
    name: 'Amount',
    selector: row => row.amount,
    width: "100px"
  }, {
    name: 'Bank Name',
    selector: row => row.bankName,
    width: "150px"
  }, {
    name: 'Card No.',
    selector: row => row.cardNumber ? hideCCNumber(row.cardNumber, 4) : '-',
    width: "200px"
  }, {
    name: 'Card Holder Number',
    selector: row => row.cardHolderName,
    width: "180px"
  }, {
    name: 'Mobile No.',
    selector: row => row.customerMobile,
    width: "120px"
  }, {
    name: 'Card Type',
    selector: row => row.cardType,
  }, {
    name: 'Marchant Ref',
    selector: row => <>{row.responseData ? "-" : '-'}</>,
    width: "120px"
    // }, {
    //   name: 'Bank Ref Number',
    //   selector: row => row.responseData ? JSON.parse(row.responseData).bank_ref_num : '-',
  }, {
    name: 'TXN Status',
    cell: row => instantTopupStatus(row.status),
    width: "120px"
  }, {
    name: 'Claim',
    cell: row => <>{instantTopupClaimStatus(row.isClaimed)}</>,
    width: "120px"
    // "0 = Pending / 1 = inprocess / 2 = rejected / 3 = refund / 4 = completed"
  }]

  const objectToQueryString = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  const params = { page: page, limit: limit };

  const getDataReports = () => {
    if (startDate && endDate) {
      params.startDate = startDate ? queryDate(startDate) : "";
      params.endDate = endDate ? queryDate(endDate) : "";
    }

    if(isClaimed) {
      params.isClaimed = true;
    }

    // if (search) {
    //   params.search = search ? search : "";
    // }

    const queryString = objectToQueryString(params);
    getRequest(`${INSTANT_TOPUP_GET}?${queryString}`, history).then((response) => {
      if (response.success === true) {
        setData(response.data);
        setTotalRecord(response.data.totalDocs);
        setPage(response.data.page);
      } else {
        setData([]);
        setTotalRecord(0);
        setPage(1);
      }
    }).catch(function (error) {
      console.log(error)
      // setLoading(false);
    })
  } // eslint-disable-line react-hooks/exhaustive-deps

  const getCSVDataList = () => {
    let params = {}
    params.exportsCsv = true;
    if (startDate && endDate) {
      params.startDate = startDate ? queryDate(startDate) : "";
      params.endDate = endDate ? queryDate(endDate) : "";
    }
    // if(props && props.location && props.location.state && props.location.state.isClaimed) {
      if(isClaimed) { params.isClaimed = true; }
      // params.isClaimed = true;
    // }
    const queryString = objectToQueryString(params);
    getRequest(`${INSTANT_TOPUP_GET}?${queryString}`, history).then((response) => {
      if (response.success === true) {
        let csvdataRecord = [];
        let csvdata = response.data;
        csvdata.map((e) => {
          return csvdataRecord.push({ "Date": moment(e.createdAt).format("DD/MM/YYYY"), "Reference Number": e.referenceNumber, "Amount": e.amount, "Bank Name": e.bankName, "Card Number": e.ccnum, "Card Holder Name": e.cardHolderName, "Customer Mobile": e.customerMobile, "Card Type": e.cardType, "Marchant Ref": e.marchantRef, "Bank Ref Number": e.bankRefNumber, "TXT Status": (e.txtStatus) ? "success" : "failed", "Payment Status": (e.paymentStatus) ? "success" : "failed" });
        });
        setCsvData(csvdataRecord);
        csvLink.current.link.click()
      } else {
        setCsvData([]);
      }
    }).catch(function (error) {
      console.log(error)
      // setLoading(false);
    })
  }

  const handlePageChange = page => {
    setPage(page);
  };

  const onChangeStartDate = (date, dateString) => {
    // console.log(date, dateString);
    setStartDate(date)
  };

  const onChangeEndDate = (date, dateString) => {
    // console.log(date, dateString);
    setEndDate(date)
  };

  const handleSearch = () => {
    getDataReports();
  }

  const handleClear = () => {
    setStartDate('');
    setEndDate('');
  }
  // useEffect(() => {
  //   console.log("getDataField ----------", getDataField)
  // }, [getDataField]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getDataReports();
    // console.log("user ----------", user)
  }, [page]) // eslint-disable-line react-hooks/exhaustive-deps
  // debugger
  return (
    <div>
      {/* {afterSave && <AfterPayment />} */}
      <div className="content-wrapper pt-2">
        <div className="content-header my-0 py-0">
          <div className="container-fluid">
            <div className="row mb-0 align-items-center">
              <div className="col-sm-6">
                <h1 className='page-title text-blue font-merriweather'>Instant Topup <span className='text-red'> {isClaimed?"Claim Request":"History"} </span></h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid pt-2 pb-5">
            <div className="row">
              <div className="col-md-12">
                <div className='card rounded-0'>
                  <div className='card-body'>
                    <div className="content-header my-0 px-0">
                      <div className="row align-items-center">
                        <div className='col-md-7'>
                          <div className='date-search mb-0'>
                            <div className='row'>
                              <div className='col-sm-4 col-lg-3'>
                                <div className="form-group">
                                  <label className='date-search-label mb-0 text-blue'>From</label>
                                  <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                </div>
                              </div>
                              <div className='col-sm-4 col-lg-3'>
                                <div className="form-group">
                                  <label className='date-search-label mb-0 text-red'>To</label>
                                  <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                </div>
                              </div>
                              <div className='col-sm-4 col-lg-2'>
                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                              </div>
                              <div className='col-sm-4 col-lg-2'>
                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-5'>
                          <div className='float-sm-right'>
                            <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                            <CSVLink
                              data={getCsvData}
                              filename='instant-topup-request-history'
                              className='hidden'
                              ref={csvLink}
                              target='_blank'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='history-table'>
                      <div className="container-fluid pt-2 pb-5">
                        <div className="col-md-12">
                          <div className="box-body">
                            <DataTable
                              title={""}
                              columns={columns}
                              data={getData.docs}
                              highlightOnHover
                              pagination
                              paginationServer
                              paginationTotalRows={getTotalRecord}
                              onChangeRowsPerPage={getDataReports}
                              onChangePage={handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default InstantTopup;
import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { GET_AEPS_WITHDRAWAL_REPORT } from './../../../Utils/appConstants';
import { getRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { DatePicker, Tooltip, Select } from 'antd';
import { getUTCToLocalDateTimeFormat, toDayDate, queryDate} from '../../../Utils/function';
import { CSVLink } from 'react-csv';
import { aepsServiceInArray } from '../../../Utils/Common';

function AepsHistoryReport(props) {

    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    const [searchUserId, setSearchUserId] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    const [searchMobile, setSearchMobile] = useState(null);
    const [searchServiceName, setSearchServiceName] = useState(null);
    const [searchAadhar, setSearchAadhar] = useState(null);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.transactionId && row.transactionId}>{row.transactionId && row.transactionId}</Tooltip>,
        width: "150px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Reference ID',
        selector: row => row.userData && row.userData.username,
        width: "120px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Service Name',
        selector: row => <Tooltip placement="bottom" title={row.mode}>{row.mode}</Tooltip>,
        width: "180px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Name',
        selector: row => <Tooltip placement="bottom" title={row.userData && row.userData.name}>{row.userData && row.userData.name}</Tooltip>,
        width: "200px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Bank',
        selector: row => (!row.bankName) ? "-" : row.bankName,
        width: "130px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Aadhaar',
        selector: row => (!row.aadhaar) ? "-" : "XXXXXXXX" + row.aadhaar,
        width: "130px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Mobile',
        selector: row => row.mobile,
        width: "140px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Amount',
        selector: row => row.amount ? row.amount : 0,
        width: "100px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Bank RRN',
        selector: row => row.operatorId,
        width: "130px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Status',
        selector: row => (row.status === 1) ? "Success" : "Failed",
        width: "150px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    },{
        name: 'Message',
        selector: row => <Tooltip placement="bottom" title={row.message && row.message}>{row.message && row.message}</Tooltip>,
        width: "200px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }
        params.searchUserId = searchUserId ? searchUserId : "";
        params.searchTransId = searchTransId ? searchTransId : "";
        params.searchMobile = searchMobile ? searchMobile : "";
        params.searchServiceName = searchServiceName ? searchServiceName : "";
        params.searchAadhar = searchAadhar ? searchAadhar : "";

        const queryString = objectToQueryString(params);
        getRequest(`${GET_AEPS_WITHDRAWAL_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }
        params.searchUserId = searchUserId ? searchUserId : "";
        params.searchTransId = searchTransId ? searchTransId : "";
        params.searchMobile = searchMobile ? searchMobile : "";
        params.searchServiceName = searchServiceName ? searchServiceName : "";
        params.searchAadhar = searchAadhar ? searchAadhar : "";

        const queryString = objectToQueryString(params);
        getRequest(`${GET_AEPS_WITHDRAWAL_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date Time": getUTCToLocalDateTimeFormat(e.createdAt),
                        "Trans ID": e.transactionId,
                        "Reference ID": e.userData && e.userData.username,
                        "Service Name": e.mode,
                        "Name": e.userData && e.userData.name,
                        "Bank": e.bankName,
                        "Aadhaar": (!e.aadhaar) ? "-" : "XXXXXXXX" + e.aadhaar,
                        "Mobile": e.mobile,
                        "Amount": e.amount,
                        "Bank RRN": e.operatorId,
                        "Status": (e.status === 1) ? "Success" : "Failed"
                    });
                });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    useEffect(() => {
        handleSearch();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-md-6">
                                <h1 className='page-title text-blue font-merriweather'>AePS Withdrawal <span className='text-red'>Report</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Aadhar No." onChange={(e) => setSearchAadhar(e.target.value)} value={searchAadhar} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Mobile Number" onChange={(e) => setSearchMobile(e.target.value)} value={searchMobile} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <Select options={aepsServiceInArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Service Name --" name='mode' onChange={(e) => setSearchServiceName(e)} />
                                                        {/* <input type="text" className="form-control form-control-border" placeholder="Service Name" onChange={(e) => setSearchServiceName(e.target.value)} value={searchServiceName} /> */}
                                                    </div>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} >Export</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='float-sm-right'>
                                            {/* <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button> */}
                                            <CSVLink
                                                data={getCsvData}
                                                filename='aeps-withdrawal-report'
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AepsHistoryReport
import React, { useEffect, useState, useRef } from 'react';
// import { APP_KEY, API_URL } from './../config';
// import { CSVLink } from 'react-csv';
// import DataTable from 'react-data-table-component';
import { isNumber, FTRNumberMake } from '../../Utils/Common';
import { FUND_REQUEST_SAVE, UPLOAD_FILE, GET_ALL_BANK_DETAIL_INFO } from '../../Utils/appConstants';
// import { getPaymentMode, /* getBankMode */ } from './../../Utils/function';
import { getRequest, postRequest } from '../../Utils/AppApi';
// import AppHeader from '../Header/Header';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import AfterSave from './../../container/afterpayment';
// import Moment from 'react-moment';
import moment from 'moment';
import { DatePicker } from 'antd';
import PropagateLoader from "react-spinners/PropagateLoader";


function FundRequest(props) {
  const history = useHistory();
  const queryDateFormat = 'YYYY-MM-DD';
  const dateFormat = "DD-MM-YYYY";
  
  // const [search, setSearch] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  // const [startDate, setStartDate] = useState(null);
  const [getDataField, setDataField] = useState([]); // eslint-disable-line react-hooks/exhaustive-deps
  const [getDataError, setDataError] = useState({}); // eslint-disable-line react-hooks/exhaustive-deps
  const [getPaymentOptions, setPaymentOptions] = useState([]);
  // const [getCsvData, setCsvData] = useState([]);
  // const [getTotalRecord, setTotalRecord] = useState(0);
  const [isSubmit, setSubmit] = useState(false);
  // const [selectedDueDate, setSelectedDueDate] = useState(false);
  const [getBankOptions, setBankOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  // const getPaymentOptions = getPaymentMode();
  // const getBankOptions = getBankMode();

  const FTRReferenceNumberInput = useRef(null);
  const amountInput = useRef(null);
  const accountNumberInput = useRef(null);
  const transactionIdInput = useRef(null);
  const branchIfscInput = useRef(null);
  const locationInput = useRef(null);
  const paymentDepositDateInput = useRef(null);
  const uploadSlipInput = useRef(null);
  const modeInput = useRef(null);
  const bankNameInput = useRef(null);

  const clearFormField = () => {
    FTRReferenceNumberInput.current.value = "";
    amountInput.current.value = "";
    accountNumberInput.current.value = "";
    transactionIdInput.current.value = "";
    branchIfscInput.current.value = "";
    locationInput.current.value = "";
    paymentDepositDateInput.current.value = "";
    // setSelectedDueDate(false)
    uploadSlipInput.current.value = "";
    modeInput.current.value = "";
    bankNameInput.current.value = "";
    setDataField([])
    setDataError({})
  }

  const getBankDetailInfo = () => {
    getRequest(`${GET_ALL_BANK_DETAIL_INFO}`, history).then((response) => {
      if (response.success === true) {
        // console.log('response.data -----', response.data)
        setBankOptions(response.data)
        // setBankOptions(response.data.map((item) => {
        //   return { "value": item._id, "label": item.bankName };
        // }));
      } else {
        setBankOptions([]);
      }
    }).catch(function (error) {
      console.log(error)
      // setLoading(false);
    })
  } // eslint-disable-line react-hooks/exhaustive-deps

  const FormValidate = () => {
    let formValid = true;
    let formObject = {};
    setDataError({})
    if (getDataField && getDataField.length === 0) {
      formValid = false;
    }
    let array = [
      "FTRReferenceNumber", "amount", "bankName",
      "accountNumber", "mode", "branchIfsc", "transactionId",
      "location", "paymentDepositDate", "uploadSlip",
    ];

    array.forEach(element => {
      if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
        console.log("element -------", element)
        formObject[element] = true;
        formValid = false;
      }
    });

    setDataError(formObject);
    return formValid;
  }

  const handleFileChange = (event, name) => {
    // console.log("name ------", name);
    event.preventDefault();
    const data = new FormData();
    data.append('avatar', event.target.files[0], event.target.files[0].name);

    postRequest(UPLOAD_FILE, data, history).then((response) => {
      // console.log("response -----------------", response);
      if (response.success === true) {
        setDataField({ ...getDataField, uploadSlip: response.data.fullpath });
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      console.log(error)
    })
  } // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    if (FormValidate(true)) {
      setLoading(true)
      const payload = {
        FTRReferenceNumber: getDataField.FTRReferenceNumber,
        amount: getDataField.amount,
        bankName: getDataField.bankName,
        accountNumber: getDataField.accountNumber,
        mode: getDataField.mode,
        branchIfsc: getDataField.branchIfsc,
        location: getDataField.location,
        paymentDepositDate: getDataField.paymentDepositDate,
        uploadSlip: getDataField.uploadSlip,
        transactionId: getDataField.transactionId,
        devicetype: "W",
        remark: getDataField.remark,
      }
      postRequest(FUND_REQUEST_SAVE, payload, history).then((response) => {
        if (response.success === true) {
          setSubmit(true)
          setDataField([]);
          setDataError({});
          clearFormField();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
        setLoading(false);
      }).catch(function (error) {
        // console.log(error)
        setLoading(false);
      })
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeValueBankInfo = (event) => {
    const { value } = event.target;
    // console.log("name -----------", name)
    // console.log("value -----------", value)
    // setDataField({ ...getDataField, bankName: value });

    if(!value) {
      setDataField({ ...getDataField, accountNumber: "" });
    }
   
    getBankOptions.forEach((element) => {
      if (element._id === value) {
        if(element.modes) {
          setPaymentOptions(element.modes.split(','))
        }
        setDataField({ ...getDataField, bankName: value, accountNumber: element.accountNumber });
      } 
    }) // eslint-disable-line react-hooks/exhaustive-deps
  } // eslint-disable-line react-hooks/exhaustive-deps


  const onChangeValue = (event) => {
    const { name, value } = event.target;
    // console.log("name -----------", name)
    // console.log("value -----------", value)
    if(name==="branchIfsc") {
      setDataField({ ...getDataField, [name]: value.toUpperCase() });
    } else {
      setDataField({ ...getDataField, [name]: value });
    }
  }

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    let dateValue = moment(date).format(queryDateFormat);
    // console.log("dateValue -----------", dateValue)
    setDataField({ ...getDataField, paymentDepositDate: dateValue });
    // setSelectedDueDate(true)
  };

  useEffect(() => {
    getBankDetailInfo();
    setDataField({ ...getDataField, FTRReferenceNumber: FTRNumberMake(6), paymentDepositDate: moment().format(queryDateFormat) });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <div className="content text-center" style={{ margin: "250px 0" }}>
        <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
    </div>
  }

  return (
    <div>
      {isSubmit ? <AfterSave status={1} message={"Fund Request send successful"}/> :
      <div className="content-wrapper pt-2">
        <div className="content">
          <div className="container-fluid pt-0 pb-5">
            <div className="row">
              <div className="col-md-12">
                <div className="content-header my-0 py-0">
                  <div className="row mb-4 align-items-center">
                    <div className="col-sm-12">
                      <h1 className='page-title text-blue font-merriweather'>Fund <span className='text-red'>Request</span></h1>
                    </div>
                  </div>
                </div>
                <div className='card rounded-0'>
                  <div className='card-body'>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>FTR Reference Number</label>
                          <input type="text" className={`form-control normal-light  ${getDataError && getDataError.FTRReferenceNumber ? "is-invalid" : ""}`} name="FTRReferenceNumber" ref={FTRReferenceNumberInput} maxLength={30} readOnly={true} onChange={(e) => onChangeValue(e)} placeholder="FTR Reference Number*" value={getDataField && getDataField.FTRReferenceNumber} />
                          {getDataError && getDataError.FTRReferenceNumber && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Amount</label>
                          <input type="text" className={`form-control normal-light  ${getDataError && getDataError.amount ? "is-invalid" : ""}`} name="amount" ref={amountInput} maxLength={10} onChange={(e) => onChangeValue(e)} placeholder="Amount*" onKeyDown={isNumber} value={getDataField && getDataField.amount} />
                          {getDataError && getDataError.amount && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Bank</label>
                          <select className={`form-control normal-light  ${getDataError && getDataError.bankName ? "is-invalid" : ""}`} name="bankName" ref={bankNameInput} onChange={(e) => onChangeValueBankInfo(e)} value={getDataField && getDataField.bankName}>
                            <option value={""}>Select Bank</option>
                            {getBankOptions.map((element, index) => {
                              return <option key={index} value={element._id}>{element.bankName}</option>
                            })}
                          </select>
                          {getDataError && getDataError.bankName && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Account Number</label>
                          <input type="text" className={`form-control normal-light  ${getDataError && getDataError.accountNumber ? "is-invalid" : ""}`} name="accountNumber" ref={accountNumberInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Account Number*" onKeyDown={isNumber} readOnly={true} value={getDataField && getDataField.accountNumber} />
                          {getDataError && getDataError.accountNumber && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Bank Ref NO / TXN ID</label>
                          <input type="text" className={`form-control normal-light  ${getDataError && getDataError.transactionId ? "is-invalid" : ""}`} name="transactionId" ref={transactionIdInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Bank Ref NO / TXN ID*" value={getDataField && getDataField.transactionId} />
                          {getDataError && getDataError.transactionId && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Mode</label>
                          <select className={`form-control normal-light  ${getDataError && getDataError.mode ? "is-invalid" : ""}`} name="mode" ref={modeInput} onChange={(e) => onChangeValue(e)} value={getDataField && getDataField.mode}>
                            <option value={""}>Select Payment Mode</option>
                            {getPaymentOptions.map((element, index) => {
                              return <option key={index} value={element}>{element}</option>
                            })}
                          </select>
                          {getDataError && getDataError.mode && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Branch Code</label>
                          <input type="text" className={`form-control normal-light  ${getDataError && getDataError.branchIfsc ? "is-invalid" : ""}`} name="branchIfsc" ref={branchIfscInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Branch IFSC Code*" value={getDataField && getDataField.branchIfsc} />
                          {getDataError && getDataError.branchIfsc && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Branch Location</label>
                          <input type="text" className={`form-control normal-light  ${getDataError && getDataError.location ? "is-invalid" : ""}`} name="location" ref={locationInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Branch Location*" value={getDataField && getDataField.location} />
                          {getDataError && getDataError.location && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Payment Deposit Date</label>
                          <DatePicker disabledDate={(current) => current.isAfter(moment().subtract(0,"day"))} onChange={onChange} format={dateFormat} placeholder="Payment Deposit Date*" className={`form-control normal-light  ${getDataError && getDataError.paymentDepositDate ? "is-invalid" : ""}`} ref={paymentDepositDateInput} defaultValue={moment()} />
                          {getDataError && getDataError.paymentDepositDate && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputFile">Upload Slip</label>
                          <div className="input-group">
                            <div className="custom-file">
                              <input type="file" className={`custom-file-input ${getDataError && getDataError.uploadSlip ? "is-invalid" : ""}`} id="exampleInputFile" ref={uploadSlipInput} onChange={(event) => handleFileChange(event)} accept="image/*" />
                              <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                            </div>
                            <div className="input-group-append  uploadbtn cursor-pointer">
                              <span className="input-group-text">Upload</span>
                            </div>
                          </div>
                          {getDataError && getDataError.uploadSlip && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Remarks</label>
                          <textarea type="text" rows={3} className="form-control" placeholder="Remarks..." />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer text-right">
                      <button type="clear" className="btn btn-cancel mr-1 rounded-pill pl-4 pr-4" onClick={() => clearFormField()}>Cancel</button>
                      <button type="submit" className="btn btn-primary  rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Submit</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4" style={{ display: "none"}}>
                <div className="content-header my-0 py-0">
                  <div className="row mb-4 align-items-center">
                    <div className="col-sm-12">
                      <h1 className='page-title text-blue font-merriweather'>Account <span className='text-red'>Details</span></h1>
                    </div>
                  </div>
                </div>
                <div className='card rounded-0' >
                  <div className='card-body'>
                    <div className="table-responsive account-detail-table">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <th width={"40%"}>Subtotal:</th>
                            <td>$250.30</td>
                          </tr>
                          <tr>
                            <th>Tax (9.3%)</th>
                            <td>$10.34</td>
                          </tr>
                          <tr>
                            <th>Shipping:</th>
                            <td>$5.80</td>
                          </tr>
                          <tr>
                            <th>Total:</th>
                            <td>$265.24</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <hr />
                    <div className="table-responsive account-detail-table">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <th width={"40%"}>Subtotal:</th>
                            <td>$250.30</td>
                          </tr>
                          <tr>
                            <th>Tax (9.3%)</th>
                            <td>$10.34</td>
                          </tr>
                          <tr>
                            <th>Shipping:</th>
                            <td>$5.80</td>
                          </tr>
                          <tr>
                            <th>Total:</th>
                            <td>$265.24</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <hr />
                    <div className="table-responsive account-detail-table">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <th width={"40%"}>Subtotal:</th>
                            <td>$250.30</td>
                          </tr>
                          <tr>
                            <th>Tax (9.3%)</th>
                            <td>$10.34</td>
                          </tr>
                          <tr>
                            <th>Shipping:</th>
                            <td>$5.80</td>
                          </tr>
                          <tr>
                            <th>Total:</th>
                            <td>$265.24</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
      </div>}
    </div>
  )
}

export default FundRequest
import React, { useEffect, useState, useCallback } from 'react';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { INSTANT_TOPUP_GET, INSTANT_TOPUP_APPROVE_REQUEST} from './../../../Utils/appConstants';
import { getRequest, postRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
// import PropagateLoader from "react-spinners/PropagateLoader";
import { Button, DatePicker, Divider, Modal} from 'antd';
import { getUTCToLocalDateTimeFormat, toDayDate, queryDate } from '../../../Utils/function';
import { instantTopupStatus, instantTopupClaimStatus, hideCCNumber} from '../../../Utils/Common';
import ImageViewer from 'react-simple-image-viewer';
import ReactPlayer from 'react-player'

function InstantTopupHistory(props) {
    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';

    const [isConfirmModal, setConfirmModal] = useState(false);
    const [searchUserId, setSearchUserId] = useState(null);
    const [searchMobileNumber, setSearchMobileNumber] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);

    const [isSelectData, setSelectedData] = useState({});
    // const [searchCustomerId, setSearchCustomerId] = useState(null);
    const [isReason, setReason] = useState("");
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    // const [refundData, setRefundData] = useState(null);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [userId, setUserId] = useState(props.userId);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");

    const columns = [{
        name: 'Date',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px"
      }, {
        name: 'Trans ID',
        selector: row => row.transactionId,
        width: "150px"
      }, {
        name: 'User ID',
        selector: row => row.users && row.users.username,
        width: "150px"
      }, {
        name: 'Amount',
        selector: row => row.amount,
        width: "100px"
      }, {
        name: 'Bank Name',
        selector: row => row.bankName,
        width: "150px"
      }, {
        name: 'Card No.',
        selector: row => row.cardNumber ? hideCCNumber(row.cardNumber, 4) : '-',
        width: "200px"
      }, {
        name: 'Card Holder Number',
        selector: row => row.cardHolderName,
        width: "180px"
      }, {
        name: 'Mobile No.',
        selector: row => row.customerMobile,
        width: "120px"
      }, {
        name: 'Card Type',
        selector: row => row.cardType,
      }, {
        name: 'Marchant Ref',
        selector: row => <>{row.responseData ? "-" : '-'}</>,
        width: "120px"
        // }, {
        //   name: 'Bank Ref Number',
        //   selector: row => row.responseData ? JSON.parse(row.responseData).bank_ref_num : '-',
      }, {
        name: 'TXN Status',
        cell: row => instantTopupStatus(row.status),
        width: "120px"
      }, {
        name: 'Claim',
        cell: row => instantTopupClaimStatus(row.isClaimed),
        width: "120px"
      }, {
        name: 'Action',
        cell: row => <>{(row.isClaimed === 1 && row.status === 1) ? <Button className="btn bg-success" onClick={() => handleView(row)} >View</Button> : "-"}</>,
      }]

      const handleView = (data) => {
        setConfirmModal(true)
        setSelectedData(data)
    }

      const handleHideModal = () => {
        setConfirmModal(false)
        setSelectedData([])
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        if (props.userId) {
            params.userId = userId ? userId : props.userId;
        }

        params.searchMobileNumber = searchMobileNumber ? searchMobileNumber : '';
        // params.searchCustomerId = searchCustomerId ? searchCustomerId : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';
        params.isAdmin = true;

        const queryString = objectToQueryString(params);
        getRequest(`${INSTANT_TOPUP_GET}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate("")
        setEndDate("")
        setSearchMobileNumber('');
        setSearchTransId('');
        // setSearchCustomerId('');
        setSearchUserId('');
    }

    const openImageViewer = useCallback((image) => {
        setViewImage(image)
        setConfirmModal(false);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setViewImage("");
        setConfirmModal(true);
        setIsViewerOpen(false);
    };

    const handleSubmit = (status) => {
        console.log("0-------------", status)
        let payload = { id: isSelectData._id, status: status, reason: isReason }
        postRequest(`${INSTANT_TOPUP_APPROVE_REQUEST}`, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                getDataReports();
                handleHideModal();
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            handleHideModal();
        })
    }

    useEffect(() => {
        if(searchMobileNumber || searchTransId || searchUserId) {
            handleSearch();
        }
    }, [searchMobileNumber, searchTransId, searchUserId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.userId) {
            setUserId(props.userId)
        }
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps
    // trans id | IFSC code | AC | AMT | BANK RRN | Status

// if (isLoading) {
//     return <div className="content text-center" style={{ margin: "250px 0" }}>
//         <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
//     </div>
// }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Instant Topup  <span className='text-red'>History</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-lg-2'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Mobile Number" onChange={(e) => setSearchMobileNumber(e.target.value)} value={searchMobileNumber} />
                                                    </div>
                                                </div>
                                                {/* <div className='col-md-2 col-lg-2'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Customer ID" onChange={(e) => setSearchCustomerId(e.target.value)} value={searchCustomerId} />
                                                    </div>
                                                </div> */}
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isViewerOpen && (
                <ImageViewer
                    src={[viewImage]}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
            {isConfirmModal && <Modal title={isSelectData.users.username} width={600} open={isConfirmModal} onCancel={() => handleHideModal()} footer={false}>{isSelectData ?
                    <ul>
                        <li><strong>Name</strong> : {isSelectData.users.name}</li>
                        <li><strong>User ID</strong> : {isSelectData.users.username}</li>
                        <li><strong>Service Charge</strong> : {isSelectData.instanttopuptrans[0].payu_service_fee}</li>
                        <li><strong>Service Tax</strong> : {isSelectData.instanttopuptrans[0].payu_service_tax}</li>
                        <li><strong>Card Photo</strong> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectData.cardPhoto)}><u>Show Card Photo</u></span></div></li>
                        <Divider/>
                        <li><strong>KYC Video</strong> : <div className='text-right'><span className='pointer'></span></div></li>
                        {isSelectData.kycVideo && <ReactPlayer url={isSelectData.kycVideo} controls={true} width={"100%"} />}
                        <Divider/>
                    </ul>
                    : ""}
                    <div className="row ">
                    <div className='col-md-12 form-group'>
                        <label>Reason: </label>
                        <textarea className="form-group form-control" cols={"4"} rows={"5"} onChange={(e) => setReason(e.target.value)} >{isReason}</textarea>
                    </div>
                    <div className="col-md-12 d-flex">
                        <div className="mr-2">
                            <button className="btn btn-success" onClick={() => handleSubmit(4)}>Approve</button>
                        </div>
                        <div className="mr-2">
                            <button className="btn btn-danger" onClick={() => handleSubmit(2)}>Reject</button>
                        </div>
                        <div className="mr-2">
                            <button className="btn btn-default" onClick={() => handleHideModal()}>Cancel</button>
                        </div>
                    </div>
                </div></Modal>}
        </div>
    )
}

export default InstantTopupHistory;

import React, { useEffect, useState } from "react";
import { isNumber } from "../../Utils/Common";
import PropagateLoader from "react-spinners/PropagateLoader";
import { POST_DMT_PAY_SPRINT_CREATE_CUSTOMER, POST_DMT_PAY_SPRINT_OTP_VERIFICATION } from '../../Utils/appConstants';
import { useHistory } from "react-router-dom";
import { postRequest } from '../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";
import { Modal, DatePicker, Divider } from "antd";

function AddCustomer(props) {
    const dateFormat = 'DD-MM-YYYY';
    const history = useHistory();
    const customerMobile = props && props.mobile;
    const [isLoading, setLoading] = useState(false);
    const [isOtpModal, setOtpModal] = useState(false);
    const [otpCode, setOtpCode] = useState(null)
    const [otpReference, setOtpReference] = useState(null);
    const [getDataError, setDataError] = useState({});
    const [getDataField, setDataField] = useState({});
    const [dateOfBirth, setDateOfBirth] = useState('');

    const onChangeOtpValue = (value) => {
        setOtpCode(value);
    }

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        let array = ["firstname", "lastname", "address", "pincode", "customerMobile", "dateOfBirth"];
        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });
        setDataError(formObject);
        return formValid;
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const handleSubmit = () => {
        // console.log("1========== submit")
        // console.log("1========== FormValidate()", FormValidate())
        // console.log("1========== FormValidate()", getDataField)
        if (customerMobile) {
            setLoading(true)
            // console.log("========== submit")
            const payload = {
                customerMobile: customerMobile
            };

            postRequest(`${POST_DMT_PAY_SPRINT_CREATE_CUSTOMER}`, payload, history).then((response) => {
                if (response.success === true) {
                    if (response.isotp === true) {
                        // console.log("response========== ", response)
                        setOtpModal(true);
                        setOtpReference(response.otpReference);
                    } else {
                        props.findCustomerInfo();
                    }
                    // console.log("response========== ",response)
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                console.log("error -----------", error)
                setLoading(false)
            })
        }
    }

    const handleCustomerVerify = () => {
        console.log("========== verify")
        if (otpCode && FormValidate() === true) {
            setLoading(true)
            console.log("========== otpCode", otpCode)
            const payload = {
                otpReference: otpReference,
                otp: otpCode,
                customerMobile: customerMobile,
                firstname: getDataField.firstname,
                lastname: getDataField.lastname,
                address: getDataField.address,
                pincode: getDataField.pincode,
                dob: getDataField.dateOfBirth,
            };

            postRequest(`${POST_DMT_PAY_SPRINT_OTP_VERIFICATION}`, payload, history).then((response) => {
                if (response.success === true) {
                    setOtpModal(false);
                    props.findCustomerInfo();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        } else {
            toast.error("Please enter all required fields");
        }
    }

    const onChangeDateOfBirth = (date, dateString) => {
        console.log("-------", dateString);
        setDateOfBirth(date);
        setDataField({ ...getDataField, dateOfBirth: dateString });
    };

    useEffect(() => {
        handleSubmit()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // let lastDate = moment().subtract(19, 'years');
        // let dateValue = moment(lastDate).format('DD-MM-YYYY');
        setDataField({ ...getDataField, customerMobile: customerMobile });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }
    return (
        <div className="row">
            <div className="col-xl-12">
                {/* Account details card*/}
                <div className="card m-0 ">
                    {/* <div className="card-header m-0"></div> */}
                    <div className="card-body m-0 py-0">
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" >Mobile</label>
                                <input className="form-control" type="text" placeholder="First Name & Last Name" name="customerMobile" value={customerMobile} onChange={(e) => onChangeValue(e)} readOnly />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-12">
                <button className="btn btn-primary float-right" onClick={() => handleSubmit()} type="button">Add Customer</button>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
            {isOtpModal && <Modal title={"Customer Verification OTP"} width={600} open={isOtpModal} onOk={() => handleCustomerVerify()} onCancel={() => setOtpModal(false)} okText={"Submit"} >
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="small mb-1" >Date Of Birth <i className="text-danger">*</i></label>
                            <DatePicker className="form-control" value={dateOfBirth} format={dateFormat} onChange={onChangeDateOfBirth} disabledDate={(current) => {
                                let customDate = moment(moment().subtract(19, 'years')).format("YYYY-MM-DD");
                                return current && current > moment(customDate, "YYYY-MM-DD");
                            }} />
                            {getDataError && getDataError.dateOfBirth && <span className="error text-danger">The field is required</span>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="small mb-1" >First Name <i className="text-danger">*</i></label>
                            <input className="form-control" type="text" placeholder="First Name" name="firstname" value={getDataField.firstname} onChange={(e) => onChangeValue(e)} maxLength={20} />
                            {getDataError && getDataError.firstname && <span className="error text-danger">The field is required</span>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="small mb-1" >Last Name <i className="text-danger">*</i></label>
                            <input className="form-control" type="text" placeholder="Last Name" name="lastname" value={getDataField.lastname} onChange={(e) => onChangeValue(e)} maxLength={20} />
                            {getDataError && getDataError.lastname && <span className="error text-danger">The field is required</span>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="small mb-1" >Address <i className="text-danger">*</i></label>
                            <input className="form-control" type="text" placeholder="Address" name="address" value={getDataField.address} onChange={(e) => onChangeValue(e)} maxLength={40} />
                            {getDataError && getDataError.address && <span className="error text-danger">The field is required</span>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="small mb-1" >Pincode <i className="text-danger">*</i></label>
                            <input className="form-control" type="text" placeholder="Pincode" name="pincode" value={getDataField.pincode} onChange={(e) => onChangeValue(e)} maxLength={6} onKeyDown={isNumber} />
                            {getDataError && getDataError.pincode && <span className="error text-danger">The field is required</span>}
                        </div>
                    </div>
                </div>
                <Divider/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>OTP Code <i className="text-danger">*</i></label>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="text" className="form-control" name="otpCode" placeholder="OTP Code" onChange={(e) => onChangeOtpValue(e.target.value)} autoComplete="off" value={otpCode} maxLength={6} onKeyDown={isNumber} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>}
        </div>
    )
}

export default AddCustomer;
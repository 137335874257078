import { React, /*lazy, Suspense */ } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import PublicRoute from './Utils/PublicRoute';
import PrivateRoute from './Utils/PrivateRoute';
import Login from './components/Login';
import JoinUs from './components/JoinUs';
import HomeWebPage from './components/webPage/home';
import AboutUsWebPage from './components/webPage/aboutus';
import AboutUsHindiWebPage from './components/webPage/aboutushindi';
import ServicesWebPage from './components/webPage/services';
import ContactUsWebPage from './components/webPage/contactus';
import TermConditionWebPage from './components/webPage/term_condition';
import PrivacyPolicyWebPage from './components/webPage/privacypolicy';
import RefundPolicyWebPage from './components/webPage/refund_policy';
import DownloadWebPage from './components/webPage/download';
import Forget from './components/Forget';
import ForgetUserId from './components/ForgetUserId';
import KycDetails from './components/KycDetails';

import HomeWebPageSrpay1 from './components/webPage/srpay1/home';
import ServicesWebPageSrpay1 from './components/webPage/srpay1/services';
import BankingServices from './components/webPage/srpay1/bankingServices';
import BbpsService from './components/webPage/srpay1/bbpsService';
import TravelServices from './components/webPage/srpay1/travelServices';
import UtilityServices from './components/webPage/srpay1/utilityServices';

// if (process.env.REACT_APP_SRPAY_LINK) {
//   // HomeWebPage;
// }ˀ

// useEffect(() => {

// },[]) // eslint-disable-line react-hooks/exhaustive-deps
// function delayForDemo(promise) {
//   return new Promise(resolve => {
//     setTimeout(resolve, 2000);
//   }).then(() => promise);
// }

// const Login = lazy(() => delayForDemo(import('./components/Login.js')));
// function Loading() {
//   return <div className="content text-center" style={{marginTop : "200px"}}>
//             <PropagateLoader color="#5851D2" loading={'true'} size={25} />
//           </div>
// }
const routes = (
  <Router>
    <Switch>
      {/* <Suspense fallback={<Loading />}> */}
      <PublicRoute exact path="/" component={(process.env.REACT_APP_SRPAY_LINK) ? HomeWebPageSrpay1 : HomeWebPage} />

      <PublicRoute exact path="/services" component={(process.env.REACT_APP_SRPAY_LINK) ? ServicesWebPageSrpay1 : ServicesWebPage} />

      <PublicRoute exact path="/banking-services" component={(process.env.REACT_APP_SRPAY_LINK) ? BankingServices : ""} />
      <PublicRoute exact path="/bbps-service" component={(process.env.REACT_APP_SRPAY_LINK) ? BbpsService : ""} />
      <PublicRoute exact path="/travel-services" component={(process.env.REACT_APP_SRPAY_LINK) ? TravelServices : ""} />
      <PublicRoute exact path="/utility-services" component={(process.env.REACT_APP_SRPAY_LINK) ? UtilityServices : ""} />
      <PublicRoute exact path="/aboutus" component={AboutUsWebPage} />
      <PublicRoute exact path="/aboutus-hindi" component={AboutUsHindiWebPage} />
      <PublicRoute exact path="/contact-us" component={ContactUsWebPage} />
      <PublicRoute exact path="/term-condition" component={TermConditionWebPage} />
      <PublicRoute exact path="/privacy-policy" component={PrivacyPolicyWebPage} />
      <PublicRoute exact path="/refund-policy" component={RefundPolicyWebPage} />
      <PublicRoute exact path="/download" component={DownloadWebPage} />
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute exact path="/joinus" component={JoinUs} />
      <PublicRoute exact path="/forget-password" component={Forget} />
      <PublicRoute exact path="/forget-userid" component={ForgetUserId} />
      <PrivateRoute exact path="/kyc-details" component={KycDetails} />
      <Route path="/" component={App} />
      {/* </Suspense> */}
    </Switch>
  </Router>
)

// ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(routes, document.getElementById('root'));
import React, { useEffect, useState, useCallback, useRef } from 'react';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_SHOP_REGISTRATION_LIST, POST_AEPS_SHOP_CONTROL_STATUS } from './../../../Utils/appConstants';

import { CSVLink } from 'react-csv';
import { Select } from 'antd';
import { shopStatusValue, shopStatusValueArray } from './../../../Utils/Common';
import { getRequest, postRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { DatePicker, Tooltip, Modal } from 'antd';
import { getUTCToLocalDateTimeFormat } from '../../../Utils/function';
import ImageViewer from 'react-simple-image-viewer';

function ShopRegistration(props) {
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    const [getCsvData, setCsvData] = useState([]);

    const [searchUserId, setSearchUserId] = useState(null);
    const [searchPincode, setPincode] = useState(null);
    const [searchStatus, setSearchStatus] = useState(null);

    const [isReason, setReason] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    // const [refundData, setRefundData] = useState(null);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    // const [userId, setUserId] = useState(props.userId);
    const [isSelectData, setSelectedData] = useState([]);
    const [isConfirmModal, setConfirmModal] = useState(false);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px",
    }, {
        name: 'Updated',
        cell: row => getUTCToLocalDateTimeFormat(row.updatedAt),
        width: "200px",
    }, {
        name: 'Shop Name',
        selector: row => <Tooltip placement="bottom" title={row.shopName}>{row.shopName}</Tooltip>,
        width: "200px",
    }, {
        name: 'Name',
        selector: row => row.userData.name,
        width: "150px",
    }, {
        name: 'Username',
        selector: row => row.userData.username,
        width: "120px",
    }, {
        name: 'Status',
        selector: row => shopStatusValue(row.status),
    }, {
        name: 'Action',
        selector: row => <><button type="button" className='btn btn-sm border border-success text-success' onClick={() => handleUpdate(row)} title='Print Invoice'><i class="fa fa-pencil" aria-hidden="true"></i></button></>
    }, {
        name: 'City',
        selector: row => row.city,
        width: "120px",
    }, {
        name: 'State',
        selector: row => row.state,
        width: "120px",
    }, {
        name: 'Pincode',
        selector: row => row.postcode,
        width: "100px",
    }, {
        name: 'Latitude',
        selector: row => row.latitude,
        width: "120px",
    }, {
        name: 'Longitude',
        selector: row => row.longitude,
        width: "120px",
    }, {
        name: 'Reject Reason',
        selector: row => <Tooltip placement="bottom" title={row.rejectReason}>{row.rejectReason}</Tooltip>,
        width: "130px",
    }]

    const handleUpdate = (data) => {
        setConfirmModal(true)
        setSelectedData(data)
    }

    const handleHideModal = () => {
        setConfirmModal(false)
        setSelectedData([])
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate) {
            params.startDate = startDate ? startDate : "";
            // params.endDate = endDate ? endDate : "";
        }

        params.searchPincode = searchPincode ? searchPincode : '';
        params.searchUserId = searchUserId ? searchUserId : '';
        params.searchStatus = searchStatus ? searchStatus : '';

        const queryString = objectToQueryString(params);
        getRequest(`${GET_SHOP_REGISTRATION_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate) {
            params.startDate = startDate ? startDate : "";
            // params.endDate = endDate ? endDate : "";
        }

        params.searchPincode = searchPincode ? searchPincode : '';
        params.searchUserId = searchUserId ? searchUserId : '';
        params.searchStatus = searchStatus;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_SHOP_REGISTRATION_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date Time": getUTCToLocalDateTimeFormat(e.createdAt),
                        "Shop Name": e.shopName,
                        "Name": e.userData.name,
                        "Username": e.userData.username,
                        "Pincode": e.responseObject && e.responseObject.address && e.responseObject.address.postcode,
                        "Latitude": e.latitude,
                        "Longitude": e.longitude,
                        "Reject Reason": e.rejectReason,
                    });
                });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(dateString)
    };

    const onChangeType = (value) => {
        // const { value } = event;
        console.log(`selected ${(value).toString()}`);
        setSearchStatus((value).toString());
    }

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setPincode('');
        setSearchUserId('');
    }

    const handleSubmit = (status) => {
        let payload = { id: isSelectData._id, status: status, reason: isReason }
        postRequest(`${POST_AEPS_SHOP_CONTROL_STATUS}`, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                getDataReports();
                handleHideModal();
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            handleHideModal();
        })
    }

    useEffect(() => {
        handleSearch();
    }, [searchPincode, searchUserId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps
    // trans id | IFSC code | AC | AMT | BANK RRN | Status

    const openImageViewer = useCallback((image) => {
        setViewImage(image)
        setConfirmModal(false);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setViewImage("");
        setConfirmModal(true);
        setIsViewerOpen(false);
    };
    // if (isLoading) {
    //     return <div className="content text-center" style={{ margin: "250px 0" }}>
    //         <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
    //     </div>
    // }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Shops <span className='text-red'>List</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-2 col-lg-2 d-flex'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
                                                    </div> */}
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Pincode" onChange={(e) => setPincode(e.target.value)} value={searchPincode} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-lg-2 d-flex'>
                                                    <div className="form-group col-md-12 col-lg-12">
                                                        <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                                        <Select options={shopStatusValueArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Status --" name='status' onChange={(e) => onChangeType(e)} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className='float-sm-right'>
                                                        <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                                        <CSVLink
                                                            data={getCsvData}
                                                            filename='shop-lists'
                                                            className='hidden'
                                                            ref={csvLink}
                                                            target='_blank'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isViewerOpen && (
                <ImageViewer
                    src={[viewImage]}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
            {isConfirmModal && <Modal title={isSelectData.userData.username} width={500} open={isConfirmModal} onCancel={handleHideModal} footer={false}>
                {isSelectData ?
                    <ul>
                        <li><strong>Shop Name</strong> : {isSelectData.shopName}</li>
                        <li><strong>Name</strong> : {isSelectData.userData.name}</li>
                        <li><strong>User ID</strong> : {isSelectData.userData.username}</li>
                        <li><strong>Address</strong> : {isSelectData.address}</li>
                        <li><strong>Latitude</strong> : {isSelectData.latitude}</li>
                        <li><strong>Longitude</strong> : {isSelectData.longitude}</li>
                        <li><strong>Aadhar Photo</strong> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectData.aadhar)}><u>Show Image</u></span></div></li>
                        <li><strong>Shop Photo</strong> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectData.shopPhoto)}><u>Show Image</u></span></div></li>
                        <li><strong>Shop Proof</strong> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectData.shopProof)}><u>Show Image</u></span></div></li>
                    </ul>
                    : ""}
                <div className="row ">
                    <div className='col-md-12 form-group'>
                        <label>Reason: </label>
                        <textarea className="form-group form-control" cols={"4"} rows={"5"} onChange={(e) => setReason(e.target.value)} >{isReason}</textarea>
                    </div>
                    <div className="col-md-12 d-flex">
                        <div className="mr-2">
                            <button className="btn btn-success" onClick={() => handleSubmit(2)}>Accept</button>
                        </div>
                        <div className="">
                            <button className="btn btn-danger" onClick={() => handleSubmit(3)}>Reject</button>
                        </div>
                    </div>
                </div>
            </Modal>}
        </div>
    )
}

export default ShopRegistration

import React, { useEffect, useState } from "react";
import { isNumber } from "../../Utils/Common";
import PropagateLoader from "react-spinners/PropagateLoader";
import { POST_DMT_INSTANT_PAY_CREATE_CUSTOMER, POST_DMT_INSTANT_PAY_OTP_VERIFICATION } from '../../Utils/appConstants';
import { useHistory } from "react-router-dom";
import { postRequest } from '../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";
import { Modal } from "antd";

function AddCustomer(props) {
    const history = useHistory();
    const customerMobile = props && props.mobile;
    const [isLoading, setLoading] = useState(false);
    const [isOtpModal, setOtpModal] = useState(false);
    const [otpCode, setOtpCode] = useState(null)
    const [otpReference, setOtpReference] = useState(null);
    const [getDataError, setDataError] = useState({});
    const [getDataField, setDataField] = useState({});

    const onChangeOtpValue = (value) => {
        setOtpCode(value);
    }

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        let array = ["customerName", "customerMobile", "pincode"];

        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        if(getDataField && getDataField.customerName) {
            let name = getDataField.customerName.split(" ");

            if(!name[1]) {
                formObject.customerName = 2;
                formValid = false;
            }
        }

        setDataError(formObject);
        return formValid;
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const handleSubmit = () => {
        // console.log("1========== submit")
        // console.log("1========== FormValidate()", FormValidate())
        // console.log("1========== FormValidate()", getDataField)
        if (FormValidate() === true) {
            setLoading(true)
            // console.log("========== submit")
            const payload = {
                customerMobile: customerMobile,
                customerName: getDataField.customerName,
                pincode: getDataField.pincode,
            };

            postRequest(`${POST_DMT_INSTANT_PAY_CREATE_CUSTOMER}`, payload, history).then((response) => {
                if (response.success === true) {
                    if(response.isotp === true) {
                        setOtpModal(true);
                        setOtpReference(response.data.otpReference);
                    } else {
                        props.findCustomerInfo();
                    }
                    console.log("response========== ",response)
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        }
    }

    const handleCustomerVerify = () => {
        console.log("========== verify")
        if (otpCode) {
            setLoading(true)
            console.log("========== otpCode", otpCode)
            const payload = {
                otpReference: otpReference,
                otp: otpCode,
                customerMobile: customerMobile
            };

            postRequest(`${POST_DMT_INSTANT_PAY_OTP_VERIFICATION}`, payload, history).then((response) => {
                if (response.success === true) {
                    setOtpModal(false);
                    props.findCustomerInfo();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        } else {
            toast.error("OTP code is required");
        }
    }

    useEffect(() => {
        let lastDate = moment().subtract(19, 'years');
        let dateValue = moment(lastDate).format('YYYY-MM-DD');
        setDataField({ ...getDataField, customerMobile: customerMobile, dateOfBirth: dateValue });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
          <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
      }
    return (
        <div className="row">
            <div className="col-xl-12">
                {/* Account details card*/}
                <div className="card m-0 ">
                    {/* <div className="card-header m-0"></div> */}
                    <div className="card-body m-0 py-0">
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" >Name</label>
                                <input className="form-control" type="text" placeholder="Enter First Name & Last Name" name="customerName" value={getDataField.customerName} onChange={(e) => onChangeValue(e)} minLength={4} maxLength={20} />
                                {getDataError && getDataError.customerName === 1 && <span className="error text-danger">The field is required</span>}
                                {getDataError && getDataError.customerName === 2 && <span className="error text-danger">Please enter customer first name & last name</span>}

                            </div>
                            <div className="col-md-6">
                                <label className="small mb-1" >PinCode</label>
                                <input className="form-control" type="text" placeholder="Pin Code" name="pincode" onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} value={getDataField.pincode} maxLength={6} />
                                {getDataError && getDataError.pincode && <span className="error text-danger">The field is required</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-12">
                <button className="btn btn-primary float-right" onClick={() => handleSubmit()} type="button">Add Customer</button>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
            {isOtpModal && <Modal title={"Customer Verification OTP"} width={600} open={isOtpModal} onOk={() => handleCustomerVerify()} onCancel={() => setOtpModal(false)} okText={"Submit"} >
                <div className="form-group">
                    <label>OTP Code</label>
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="text" className="form-control" name="otpCode" placeholder="OTP Code" onChange={(e) => onChangeOtpValue(e.target.value)} autoComplete="off" value={otpCode} maxLength={6} onKeyDown={isNumber} />
                        </div>
                    </div>
                </div>
            </Modal>}
        </div>
    )
}

export default AddCustomer;

import React, { useEffect, useState, useRef } from 'react';
import { isNumber, FTRNumberMake, getParentPageTitleLable } from './../../Utils/Common';
import { saveDateInDatabase } from './../../Utils/function';

// import { Tabs, Tab } from 'react-bootstrap-tabs';
import { SAVE_PAYMENT_DEPOSIT, GET_PARENT_PROFILE } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
import PaymentHistory from './history';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import AfterSave from './../../container/afterpayment';
// import Moment from 'react-moment';
import moment from 'moment';
import { DatePicker } from 'antd';

// const user = getUser();
function PaymentDeposit(props) {
    
    const history = useHistory();
    const dateFormat = 'DD-MM-YYYY';
    const [isLabel, setLabel] = useState(null);
    const [getDataField, setDataField] = useState([]); // eslint-disable-line react-hooks/exhaustive-deps
    const [getDataError, setDataError] = useState({}); // eslint-disable-line react-hooks/exhaustive-deps
    const [currentOutStanding, setCurrentOutStanding] = useState(0);
    const [isSubmit, setSubmit] = useState(false);

    const UTRReferenceNumberInput = useRef(null);
    const depositAmountInput = useRef(null);
    const dateTimeInput = useRef(null);
    // const currentOutStandingBalanceInput = useRef(null);
    const paymentModeSelect = useRef(null);
    const remarkInput = useRef(null);
    // const mobile = useFormInput('');

    const clearFormField = () => {
        UTRReferenceNumberInput.current.value = "";
        depositAmountInput.current.value = "";
        dateTimeInput.current.value = "";
        // currentOutStandingBalanceInput.current.value = "";
        paymentModeSelect.current.value = "";
        remarkInput.current.value = "";
    }

    const getParentProfile = () => {
        getRequest(`${GET_PARENT_PROFILE}`, history).then((response) => {
          if (response.success === true) {
            setCurrentOutStanding(response.data.outstanding.closingBalance);
          } else {
            setCurrentOutStanding(0);
          }
        }).catch(function (error) {
          console.log(error)
        })
      } // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        let array = ["UTRReferenceNumber", "dateTime","depositAmount", "paymentMode", "remark"];
        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        if(formValid && getDataField.depositAmount <= 0) {
            toast.error("Deposit amount is should be greater than 0")
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }

    const handleSubmit = () => {
        if (FormValidate()) {
            const payload = {
                UTRReferenceNumber: getDataField.UTRReferenceNumber,
                dateTime: getDataField.dateTime,
                currentOutStandingBalance: currentOutStanding,
                depositAmount: getDataField.depositAmount,
                paymentMode: getDataField.paymentMode,
                remark: getDataField.remark,
                devicetype: "W"
            }
            postRequest(SAVE_PAYMENT_DEPOSIT, payload, history).then((response) => {
                if (response.success === true) {
                    setDataField([]);
                    setDataError({});
                    clearFormField();
                    setSubmit(true)
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
                // setLoading(false);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps


    const onChangePaymentModeValue = (event) => {
        const {name, value} = event.target;
        setDataField({ ...getDataField, [name]: value });
    };

    const onChangeDepositDate = (date, dateString) => {
        // console.log("saveDateInDatabase -----", saveDateInDatabase(dateString))
        setDataField({ ...getDataField, "dateTime": saveDateInDatabase(dateString) });
    };
    useEffect(() => {
        getParentProfile();
    },[])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLabel(getParentPageTitleLable())
        setDataField({ ...getDataField, UTRReferenceNumber: FTRNumberMake(6), dateTime: saveDateInDatabase(moment().format(dateFormat)) });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {isSubmit ? <AfterSave status={1} message={"Payment deposit successful"}/> :
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Payment Deposit <span className='text-red'>{isLabel}</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card rounded-0'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                                <div className="form-group">
                                                    <label>Reference Number</label>
                                                    <input type="text" className={`form-control normal-light  ${getDataError && getDataError.UTRReferenceNumber ? "is-invalid" : ""}`}
                                                    disabled name="UTRReferenceNumber" ref={UTRReferenceNumberInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Reference Number*" value={getDataField && getDataField.UTRReferenceNumber} />
                                                    {getDataError && getDataError.UTRReferenceNumber && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                                <div className="form-group">
                                                    <label>Date & Time</label>
                                                    <DatePicker className={`form-control normal-light  ${getDataError && getDataError.dateTime ? "is-invalid" : ""}`} format={dateFormat} ref={dateTimeInput} onChange={onChangeDepositDate} defaultValue={moment()} />
                                                    {getDataError && getDataError.dateTime && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                                <div className="form-group">
                                                    <label>Current Outstanding</label>
                                                    <input type="text" className={`form-control normal-light  ${getDataError && getDataError.currentOutStandingBalance ? "is-invalid" : ""}`} name="currentOutStandingBalance" maxLength={30} onChange={(e) => onChangeValue(e)} placeholder="Current Outstanding Balance*" value={currentOutStanding} readOnly={true} />
                                                    {getDataError && getDataError.currentOutStandingBalance && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                                <div className="form-group">
                                                    <label>Deposit Amount</label>
                                                    <input type="text" className={`form-control normal-light  ${getDataError && getDataError.depositAmount ? "is-invalid" : ""}`} name="depositAmount" ref={depositAmountInput} maxLength={10} onChange={(e) => onChangeValue(e)} placeholder="Deposit Amount*" onKeyDown={isNumber} value={getDataField && getDataField.depositAmount} />
                                                    {getDataError && getDataError.depositAmount && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                                <div className="form-group">
                                                    <label>Mode</label>
                                                    <select className={`form-control normal-light ${getDataError && getDataError.paymentMode ? "is-invalid" : ""}`} ref={paymentModeSelect} name='paymentMode' onChange={(e) => onChangePaymentModeValue(e)}>
                                                        <option value={""}>Select Mode</option>
                                                        <option value={"Cash"}>{"Cash"}</option>
                                                        <option value={"Online"}>{"Online"}</option>
                                                        <option value={"Bank"}>{"Bank"}</option>
                                                    </select>
                                                    {getDataError && getDataError.paymentMode && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                                <div className="form-group">
                                                    <label>Bank / UTR Number / Remark</label>
                                                    <input type="text" className={`form-control normal-light  ${getDataError && getDataError.remark ? "is-invalid" : ""}`} name="remark" ref={remarkInput} maxLength={50} onChange={(e) => onChangeValue(e)} placeholder="Bank / UTR Number / Remark" value={getDataField && getDataField.remark} />
                                                    {getDataError && getDataError.remark && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row"> 
                                            <div className='col-md-12 d-flex'>
                                                <div className="form-group  pull-right text-lg-right mr-4">
                                                    <button type="submit" className="btn btn-default rounded-pill pl-4 pr-4" onClick={() => clearFormField()} >Cancel</button>
                                                </div>
                                                <div className="form-group text-lg-right">
                                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Process</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <PaymentHistory />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
            </div>}
        </div>
    )
}

export default PaymentDeposit
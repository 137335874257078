import React, { useEffect, useState } from 'react';
import { Modal, Button, Space, Spin, Tooltip } from 'antd';
import { ToWords } from 'to-words';
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { ADMIN_MAKER_UTILITY_PAYMENT_OFFLINE, ADMIN_UTILITY_PAYMENT_OFFLINE_STATUS_CHANGE_REQUEST } from '../../../Utils/appConstants';
import { getRequest, postRequest } from '../../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import ImageViewer from 'react-simple-image-viewer';
import { INRFormat } from '../../../Utils/Common';
import { ArrowRightOutlined } from '@ant-design/icons';
// import Moment from 'react-moment';
import { getUTCToLocalDateTimeFormat } from '../../../Utils/function';

function Maker(props) {
    const history = useHistory();
    const toWords = new ToWords();
    const limit = 10;
    const getMode = props.mode ? props.mode : "";
    const startDate = props.startDate ? props.startDate : "";
    const endDate = props.endDate ? props.endDate : "";
    const search = props.search ? props.search : "";

    const [getData, setData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isShowDetailInfo, setShowDetailInfo] = useState(false);
    const [isSelectedRecord, setIsSelectedRecord] = useState([]);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const [apiId, setApiId] = useState("");
    const [apiIdError, setApiIdError] = useState(false);
    const [loading, setLoading] = useState(false);

    const columns = [{
        name: 'Date',
        cell: row => getUTCToLocalDateTimeFormat(row.updatedAt),
        width: "180px"
    }, {
        name: 'Transaction ID',
        cell: row => row.transactionId,
        width: "150px"
    },{
        name: 'Provider',
        selector: row => <Tooltip title={row.electricityproviders.name}>{row.provider}</Tooltip>,
        width: "100px"
    }, {
        name: 'Name',
        selector: row => row.user.name,
        width: "150px"
    }, {
        name: 'Username',
        selector: row => row.user.username,
        width: "100px"
    }, {
        name: 'Account Number',
        selector: row => row.accountNumber ? row.accountNumber : row.policyNumber,
        width: "150px"
    }, {
        name: 'Bill No',
        selector: row => row.billNumber ? row.billNumber : "-",
        width: "100px"
    }, {
        name: 'Amount',
        selector: row => row.amount ? INRFormat(row.amount) : INRFormat(row.billAmount),
        width: "100px"
    }, {
        name: 'Due Date',
        selector: row => row?.billDueDateOn ? row?.billDueDateOn : row?.duedatefromto ? row?.duedatefromto.split(' - ')[0] : '-',
    }, {
        name: 'Bill Date',
        selector: row => row?.billDate ? row?.billDate : row?.duedatefromto ? row?.duedatefromto.split(' - ')[1] : '-',
    }, {
        name: 'Action',
        cell: row => <><div className='icon-border border border-primary text-primary pointer' title='View Details'><i className="fa fa-eye " onClick={(event) => handleRequestDetail(event, row)} aria-hidden="true"></i></div></>,
    }]

    const handleRequestDetail = (event, data) => {
        setShowDetailInfo(true)
        setIsSelectedRecord(data)
        // console.log("data ---------------", data)
    }

    const handleSave = (status) => {
        if (apiId) {
            const payload = {
                apiId: apiId,
                status: status,
                mode: getMode,
                isChecker: "maker"
            }
            handleSubmit(payload);
        } else {
            setApiIdError(true)
        }
    }

    const handleSubmit = (data) => {
        // const queryString = objectToQueryString(payload);
        setLoading(true);
        postRequest(`${ADMIN_UTILITY_PAYMENT_OFFLINE_STATUS_CHANGE_REQUEST + "/" + isSelectedRecord._id}`, data, history).then((response) => {
            if (response.success === true) {
                getDataReports()
                handleHideModal();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            handleHideModal();
            setLoading(false);
        })
        // console.log("accept ----",value)
    }

    const handleHideModal = () => {
        setShowDetailInfo(false);
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        params.mode = getMode;

        if (search) {
            params.search = search ? search : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${ADMIN_MAKER_UTILITY_PAYMENT_OFFLINE}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = page => {
        setPage(page);
    };

    const onChangeValue = (event) => {
        const { value } = event.target;
        // console.log("name -----------", name)
        // console.log("value -----------", value)
        setApiIdError(false)
        setApiId(value);
    }

    const closeImageViewer = () => {
        setViewImage("");
        setShowDetailInfo(true);
        setIsViewerOpen(false);
    };

    useEffect(() => {
        getDataReports();
    }, [page, props.handleSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {loading &&
                <div className={'parentDisable'} width="100%">
                    <Space direction="vertical" style={{ width: '100%', }} >
                        <Spin tip="Loading" size="large">
                            <div className="spin-loader" />
                        </Spin>
                    </Space>
                </div>}
            <div className="container-fluid pt-2 pb-5">
                <div className="col-md-12">
                    {/* <div className='float-sm-right d-flex mb-1'>
                        <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                        <CSVLink
                            data={getCsvData}
                            filename='dth-recharge-report.csv'
                            className='hidden'
                            ref={csvLink}
                            target='_blank'
                        />
                        <button type="button" className="btn btn-danger">
                            <i className="fa fa-file-pdf"></i> Export to PDF
                        </button>
                    </div> */}
                    <div className="box-body">
                        <DataTable
                            // title={getMode}
                            columns={columns}
                            data={getData.docs}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={getTotalRecord}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            {isViewerOpen && (
                <ImageViewer
                    src={[viewImage]}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}

            {isShowDetailInfo && <Modal title={isSelectedRecord.FTRReferenceNumber} width={500} open={isShowDetailInfo} onCancel={handleHideModal} footer={false}>
                {isSelectedRecord ?
                    <><ul>
                        <li><span>Amount</span> : <b>{INRFormat(isSelectedRecord.billAmount ? isSelectedRecord.billAmount : isSelectedRecord.amount)}</b></li>
                        <br />
                        <li><span>Amount (Word)</span> : <b className='text-primary'>{toWords.convert(isSelectedRecord.amount ? isSelectedRecord.amount : isSelectedRecord.billAmount)}</b></li>
                        <br />
                        <li><span>Provider</span> : {isSelectedRecord.electricityproviders.name}</li>
                        <li><span>Account Number</span> : {isSelectedRecord.accountNumber ? isSelectedRecord.accountNumber : isSelectedRecord.policyNumber}</li>
                        <li><span>Date Of Birth</span> : {isSelectedRecord.dateOfBirth}</li>
                        <li><span>Email Address</span> : {isSelectedRecord.emailAddress}</li>
                        <li><span>Name</span> : {isSelectedRecord.name}</li>
                        <li><span>Bill Number</span> : {isSelectedRecord.billNumber}</li>
                        <li><span>Bill Date</span> :  {isSelectedRecord.billDate ? isSelectedRecord.billDate : isSelectedRecord.duedatefromto ? isSelectedRecord.duedatefromto.split(' - ')[0] : '-'}</li>
                        <li><span>Due Date</span> : {isSelectedRecord.billDueDateOn ? isSelectedRecord.billDueDateOn : isSelectedRecord.duedatefromto ? isSelectedRecord.duedatefromto.split(' - ')[1] : '-'}</li>
                    </ul>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>API Id</label>
                                    <input type="text" className={`form-control normal-light pr-110`} name="apiId" maxLength={25} onChange={(e) => onChangeValue(e)} value={apiId} placeholder="API ID / Reason" />
                                    {apiIdError && <span className="error text-danger">The field is required</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <Button type="primary" size={"large"} icon={<ArrowRightOutlined />} block onClick={() => handleSave(2)} >Process</Button>
                            </div>
                            <div className="col-md-6 text-right">
                                <Button type="primary" size={"large"} onClick={() => handleSave(4)} danger block>Reject</Button>
                            </div>
                        </div>
                    </>
                    : ""}
            </Modal>}
        </div>
    )
}

export default Maker
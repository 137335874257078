import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import { getRequest } from '../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { setUserSession } from '../Utils/Common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APP_KEY, API_URL } from '../config';
import bgshape from './../assets/bgshape.svg';
import Footer from "./Footer/Footer";
import Spinner from "./spinner";
import OtpInput from "react-otp-input";
import { Button, Modal } from "antd";
import { reactLocalStorage } from 'reactjs-localstorage';
import { APP_INFO } from '../Utils/appConstants';
import AdvertiseSlider from "./AdvertiseSlider";

function Login(props) {
  const history = useHistory()
  const [isLoading, setLoading] = useState(false);
  const [appInfo, setAppInfo] = useState("");

  const [userAgent, setUserAgent] = useState(null);
  const [loginData, setLoginData] = useState(null);
  const [isOTPModal, setOTPModal] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [isEye, setEye] = useState("fas fa-eye-slash");
  const [OTPShowMessage, setOTPShowMessage] = useState("");
  const [otpCode, setOtpCode] = useState(0);
  const username = useFormInput('');
  const password = useFormInput('');
  const [timer, setTimer] = useState(120); // Timer in seconds
  // const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    // Start the timer countdown
    const interval = setInterval(() => {
      setTimer(prevTimer => prevTimer - 1);
    }, 1000);

    // Clear the interval when the timer reaches 0
    if (timer === 0) {
      clearInterval(interval);
      // Close the modal automatically after timer reaches 0
      setOTPModal(false);
      window.location.reload()
    }

    // Clear the interval when component unmounts
    return () => clearInterval(interval);
  }, [timer]);

  const countDown = () => {
    setLoading(true);
    let secondsToGo = 3;
    // const instance = modal.success({
    //   title: <><h3>Processing Login {"("+ secondsToGo+")"}</h3></>,
    //   // content: `This modal will be destroyed after ${secondsToGo} second.`,
    //   okText: ""
    // });
    <div className="content text-center" style={{ margin: "250px 0" }}>
      <Spinner />
      {/* <PropagateLoader color="#5851D2" aria-label="Loading Spinner" loading={isLoading} size={25} /> */}
    </div>
    const timer = setInterval(() => {
      secondsToGo -= 1;
      console.log('loginData---------', loginData);
      <div className="content text-center" style={{ margin: "250px 0" }}>
        <Spinner />
        {/* <PropagateLoader color="#5851D2" aria-label="Loading Spinner" loading={isLoading} size={25} /> */}
      </div>
      // instance.update({
      //   title: <><h3>Processing Login {"("+ secondsToGo+")"}</h3></>,
      //   // content: `This modal will be destroyed after ${secondsToGo} second.`,
      //   okText: ""
      // });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      // instance.destroy();
      // setLoading(false);
      window.location.reload()
      // if(loginData) {
      //   loginRedirect(loginData);
      // }
    }, secondsToGo * 1000);
  };

  const headers = {
    headers: {
      "appKey": APP_KEY,
      "Content-Type": "application/json",
    }
  }

  const redirectJoinUs = () => {
    history.push({
      pathname: "/joinus"
    });
  }

  const degreesToRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  }

  const calculateHaversine = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
  }


  function handleLocationClick() {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, function (error) {
        console.log("error -----------------------", error);
        setLoading(false);
        toast.error("Please allow location before login");
      });
    } else {
      setLoading(false);
      console.log("Geolocation not supported");
    }
  }

  const success = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const lat1 = 26.26722;
    const lon1 = 72.975279;
    const lat2 = latitude;
    const lon2 = longitude;

    const distance = calculateHaversine(lat1, lon1, lat2, lon2);
    console.log(`Distance between the two points: ${distance} km`);
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    if (username.value && password.value) {
      let data = {}
      data.latitude = latitude;
      data.longitude = longitude;
      data.macAddress = userAgent;
      data.devicetype = "W";
      handleLogin(data)
    } else {
      toast.error("Please enter your username and password")
    }
  }

  // const loginRedirect = (record) => {
  //   if (!record.isAdminApprovedStatus) {
  //     // props.history.push("/kyc-details");
  //     window.location.href = '/kyc-details';
  //   } else if (!record.userDetails || record.kycStatus === 0 || record.kycStatus === 2) {
  //     // props.history.push("/kyc-details");
  //     window.location.href = '/kyc-details';
  //   } else {
  //     if (window.location.hostname === "localhost") {
  //       props.history.push('/dashboard');
  //       // } else if(record.role.code === "D") {
  //       //   window.location.href = process.env.REACT_APP_PUBLIC_URL + "/home";
  //     } else {
  //       window.location.href = process.env.REACT_APP_PUBLIC_URL + "/dashboard";
  //     }
  //   }
  // }
  // handle button click of login form
  const handleLogin = (data) => {
    setLoading(true);
    if (isOTPModal && !otpCode) {
      setLoading(false);
      toast.error("Please enter OTP")
      return false;
    }
    axios.post(`${API_URL}/users/login`, { username: username.value, password: password.value, macAddress: data.macAddress, devicetype: data.devicetype, latitude: data.latitude, longitude: data.longitude, otpCode: otpCode }, headers).then(async data => {
      setLoading(false);
      if (data.data.success === true && data.data.otpcheck === true) {
        setOTPShowMessage(data.data.message);
        setOTPModal(true);
      } else if (data.data.otpcheck === true && !data.data.success) {
        toast.error(data.data.message)
        setOTPModal(true);
      } else if (data.data.success === true) {
        let record = data.data.data;
        setUserSession(record.token, data.data);
        // toast.success(data.data.message)
        setOTPModal(false);
        setOtpCode(0)
        setLoginData(data.data.data);
        countDown();
        // console.log('record---------', record);
        // if (!record.isAdminApprovedStatus) {
        //   props.history.push("/kyc-details");
        // } else if (!record.userDetails || record.kycStatus === 0 || record.kycStatus === 2) {
        //   props.history.push("/kyc-details");
        // } else {
        //   if (window.location.hostname === "localhost") {
        //     props.history.push('/dashboard');
        //     // } else if(record.role.code === "D") {
        //     //   window.location.href = process.env.REACT_APP_PUBLIC_URL + "/home";
        //   } else {
        //     window.location.href = process.env.REACT_APP_PUBLIC_URL + "/dashboard";
        //   }
        // }
        // navigator.geolocation.getCurrentPosition((data) => {
        //   console.log('---------',data);
        // });
      } else {
        toast.error(data.data.message)
      }
    }).catch(error => {
      setLoading(false);
      console.log(error);
      toast.error(error && error.response && error.response.data && error.response.data.message)
    });
  }

  const getAppInfo = async () => {
    getRequest(`${API_URL}/appinfo`).then(async (data) => {
      setLoading(false);
      if (data) {
        // console.log("========================", data)
        await setAppInfo(data)
        reactLocalStorage.set(APP_INFO, JSON.stringify(data), true);
      } else {
        toast.error(data.data.message)
      }
    }).catch(function (error) {
      setLoading(false);
      console.log(error);
    })
  }

  const redirectForgetPage = () => {
    history.push({
      pathname: "/forget-password"
    });
  }

  const redirectForgetUserIDPage = () => {
    history.push({
      pathname: "/forget-userid"
    });
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      setEye("fas fa-eye")
      return;
    }
    setPasswordType("password")
    setEye("fas fa-eye-slash")
  }

  useEffect(() => {
    const platform = navigator.platform
    const userAgent = navigator.userAgent;
    // console.log('User Agent:', platform+"-"+userAgent);
    // const browserName = navigator.appName;
    // const browserVersion = navigator.appVersion;
    // console.log('Browser:', browserName, 'Version:', browserVersion);
    // console.log('latitude:', latitude, 'longitude:', longitude);
    setUserAgent(platform + "-" + userAgent);
  }, []);

  useEffect(() => {
    console.log('useEffect---------');
    setLoading(true)
    getAppInfo();
    // navigator.geolocation.getCurrentPosition(
    //   function (position) {
    //     // console.log(position);
    //   },
    //   function (error) {
    //     toast.error("Please allow location before login")
    //     // console.log(error);
    //   }
    // );
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <div className="content text-center" style={{ margin: "250px 0" }}>
      <Spinner />
      {/* <PropagateLoader color="#5851D2" loading={isLoading} size={25} /> */}
    </div>
  }

  return (
    <div className='wrapper'>
      {/* {contextHolder} */}
      <div className='topbar'>
        <nav className="main-header navbar navbar-expand navbar-light flex-wrap">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a href="\#" className="brand-link">
                <img src="../../logo.png" alt="Logo" className="brand-image" />
              </a>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a href={`https://wa.me/${appInfo.helpNumber}`} target="_blank" rel="noreferrer" className='nav-link'>
                {/* <i className='fas fa-phone-alt mr-1 call-icon'></i> */}

                <i class="fa-brands fa-whatsapp fa-fade fa-lg mr-1 call-icon"></i>
                <span className='helpline-text'>{appInfo.helpNumber}</span>
              </a>
            </li>
            <li className="nav-item pl-0 pl-md-4">
              <a href="mailto:{appInfo.email}" className='nav-link'><i className='fas fa-envelope mr-1 call-icon'></i> <span className='helpline-text'>{appInfo.email}</span></a>
            </li>
          </ul>
        </nav>
      </div>
      <div className='content-wrapper login-outer' style={{ backgroundImage: `url(${bgshape})`, backgroundRepeat: "no-repeat" }}>
        <div className='login-container' >
          <div className=''>
            <div className='row login-background' >
              <div className='col-lg-2 text-center'></div>
              <div className='col-md-6 col-lg-5 text-center'>
                <AdvertiseSlider/>
              </div>
              {/* <div className='col-md-6 col-lg-7 text-center'>
                <img src='../../festival/offer.jpeg' width={"540"} alt='' />
                <img src='../../festival/offer1.jpeg' width={"540"} alt='' />
              </div> */}
              <div className='col-md-6 col-lg-5'>
                <div className="login-box">
                  <div className="">
                    <div className="card-body" style={{ textAlign: "center" }}>
                      <img src="../../logo.png" alt="Logo" width={"222"} className="brand-image" />
                      <h2 className='login-box-heading mt-3 mb-3'>Login to Continue</h2>
                      <div className="input-group mb-3">
                        <input type="text" className="form-control" name='username' {...username} placeholder="User Id" maxLength={15} />
                      </div>
                      <div className="input-group mb-3">
                        <input type={passwordType} className="form-control" {...password} placeholder="Password" />
                        <div className="group-append cursor-pointer" onClick={() => togglePassword()}>
                          <span className={isEye} />
                        </div>
                      </div>

                      <div className="mt-3">
                        <button type="submit" onClick={() => handleLocationClick()} className="btn btn-block login-button ">Log In {isLoading ? "..." : ""}</button>
                      </div>
                      <div className="mt-3 text-center">
                        <p className='forgot pointer'>
                          <span onClick={() => redirectForgetPage()}>Forgot Password?</span>  &nbsp;|&nbsp; <span onClick={() => redirectForgetUserIDPage()}>Forgot User id?</span>
                        </p>
                      </div>

                      <div className="mt-4 text-center">
                        <p className='member '>
                          Not a registered member?
                          {/* <span onClick={() => redirectJoinUs()} className='joinnow-text'>Join now</span> */}
                        </p>
                      </div>

                      <div className="mt-3 text-center" onClick={() => redirectJoinUs()}>
                        <button className='join-button px-5 py-1'>Join now</button>
                      </div>
                      <div className='my-4'><hr /></div>
                      <div className='mb-5 pointer' target="_blank" onClick={() => {
                        // if (process.env.REACT_APP_SRPAY_LINK === "www.srpay1.com") {
                        window.open('https://play.google.com/store/apps/details?id=com.srpaypartner', '_blank')
                        // }
                      }}>
                        <img src='../../googleplay1@3x.png' alt='google' width={"213.74"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      </div>
      {isOTPModal && <Modal width={400} title="Login Verification" open={isOTPModal} footer={false} okText={"Continue"} ><div className="input-group">
        <p className="text-center">{OTPShowMessage}</p>
        <div className="col-md-10 mb-3 mt-3 otp-justify">
          <OtpInput
            value={otpCode}
            separator={
              <span>
                <strong></strong>
              </span>
            }
            onChange={(value) => setOtpCode(value)}
            isInputNum={true}
            numInputs={4}
            className="justify-content-md-around"
            inputStyle={{
              width: "3rem",
              height: "3rem",
              // margin: "0 0.9rem",
              fontSize: "2rem",
              borderRadius: 4,
              border: "1px solid rgba(0,0,0,0.3)"
            }}
          />
        </div>
        <div className="col-md-2 mb-3 mt-3 otp-justify"><span className="numberCircle">{timer}</span></div>
        <Button block className="btn btn-primary bg-primary pointer" onClick={() => handleLocationClick()}>Submit </Button>
      </div>
      </Modal>}
      <Footer />
    </div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    if (e.target.name === 'username') {
      setValue(e.target.value.toUpperCase());
    } else {
      setValue(e.target.value);
    }
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;
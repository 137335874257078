import React, { useEffect, useState, useRef, useCallback } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_MATM_REGISTRATION_LIST, POST_MATM_REGISTRATION_STATUS_APPROVED, DELETE_MATM_REGISTRATION_API } from './../../../Utils/appConstants';
import { deleteRequestNewApi, getRequest, postRequest } from './../../../Utils/AppApi';
import { PaymentDepositStatus, requestStatus } from './../../../Utils/Common';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { DatePicker, Select, Modal, Popconfirm } from 'antd';
import { getUTCToLocalDateTimeFormat, queryDate, toDayDate } from '../../../Utils/function';
import { toast } from 'react-toastify';
import ImageViewer from 'react-simple-image-viewer';

function MatmRegistration(props) {
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';
    const dateDBFormat = 'YYYY-MM-DD';
    const dateDBFormatCSV = 'YYYY-MM-DD HH:MM:SS';

    const [searchUserId, setSearchUserId] = useState(null);
    const [searchSerialNumber, setSearchSerialNumber] = useState(null);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isSelected, setIsSelected] = useState(null);
    const [isModal, setIsModal] = useState(false)
    const [isStatus, setStatus] = useState(null)

    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");

    const columns = [{
        name: 'Created Date',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        // width: "120px"
    },{
        name: 'Updated Date',
        cell: row => getUTCToLocalDateTimeFormat(row.updatedAt),
        // width: "120px"
    }, {
        name: 'User ID',
        selector: row => row.users.username,
        // width: "120px"
    }, {
        name: 'Serial Number',
        selector: row => row.serialNumber,
        // width: "180px"
    }, {
        name: 'Mobile',
        selector: row => row.mobile,
        // width: "120px"
    }, {
        name: 'Status',
        cell: row => <>{PaymentDepositStatus(row.status)}</>,
        // width: "120px"
    }, {
        name: 'Action',
        cell: row => <><div className='icon-border border border-primary text-primary pointer' onClick={(event) => handleUserDetail(event, row)}><i className="fa fa-eye" aria-hidden="true"></i></div><Popconfirm placement="left" title={"Are you sure want to Delete?"} onConfirm={(event) => handleDelete(event, row._id)} okText="Yes" cancelText="No"><div className='icon-border border border-danger text-danger pointer'><i className="fa fa-trash" aria-hidden="true"></i></div></Popconfirm></>,
    }]

    const handleDelete = (event, id) => {
        let payload = { id: id };
        deleteRequestNewApi(DELETE_MATM_REGISTRATION_API, payload, history).then((response) => {
            if (response.success === true) {
                getDataReports();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleUserDetail = (event, data) => {
        setIsModal(true)
        setIsSelected(data)
        // let user = JSON.parse(JSON.stringify(data.aadharNumberVerifiedDetail));
        // setisSelectedAadharDetail(user)
        // console.log("data ---------------", user.name)
    }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }
        params.searchUserId = searchUserId ? searchUserId : "";
        params.searchSerialNumber = searchSerialNumber ? searchSerialNumber : "";
        params.searchStatus = isStatus;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_MATM_REGISTRATION_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                // setIsClear(false)
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }
        params.searchUserId = searchUserId ? searchUserId : "";
        params.searchSerialNumber = searchSerialNumber ? searchSerialNumber : "";
        params.searchStatus = isStatus;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_MATM_REGISTRATION_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "created Date": moment(e.createdAt, dateDBFormat).format(dateDBFormatCSV),
                        "Update Date": moment(e.updatedAt, dateDBFormat).format(dateDBFormatCSV),
                        "User Id": e.users.username,
                        "Serial Number": e.serialNumber,
                        "Mobile": e.mobile,
                        "Status": (e.status)
                    });
                });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    const openImageViewer = useCallback((image) => {
        setViewImage(image)
        setIsModal(false);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setViewImage("");
        setIsModal(true);
        setIsViewerOpen(false);
    };

    const handleApproved = (data) => {
        if (data) {
            const payload = {
                id: data._id,
                status: 1,
                comment: ''
            }

            handleSubmit(payload);
        } else {
            toast.error("You have not permission for a approved user")
        }
    }

    const handleReject = (data) => {
        if (data) {
            const payload = {
                id: data._id,
                status: 2,
                comment: ''
            }

            handleSubmit(payload);
        }
    }

    const handleSubmit = (data) => {
        // const queryString = objectToQueryString(payload);
        postRequest(`${POST_MATM_REGISTRATION_STATUS_APPROVED}`, data, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                // setIsReason("")
                // setIsReasonVisble(false)
                setIsModal(false)
                getDataReports();
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
        // console.log("accept ----",value)
    }

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        // setStartDate(moment(dateString, dateFormat).format(dateDBFormat))
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        // setEndDate(moment(dateString, dateFormat).format(dateDBFormat))
        setEndDate(date)
    };

    const onChangeType = (value) => {
        // const { value } = event;
        console.log(value);
        setStatus(value);
    }

    const handleClear = () => {
        setStartDate('');
        setEndDate('');
        setStartDate(null);
        setEndDate(null);
        setStatus(null);
        // setIsClear(true)
    }

    const handleSearch = () => {
        getDataReports();
    }

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (isClear) {
    //         getDataReports();
    //     }
    // }, [isClear]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>MATM Registration <span className='text-red'>List</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card rounded-0 col-md-12'>
                                <div className="row align-items-center mt-4 mx-3">
                                    <div className='col-md-11'>
                                        <div className='date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} allowClear />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} allowClear />
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-lg-4 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Serial Number" onChange={(e) => setSearchSerialNumber(e.target.value)} value={searchSerialNumber} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-lg-2'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0'> Status</label>
                                                        <Select options={requestStatus} className={`normal-light w-100`} classNamePrefix="select" placeholder="Select" name='mode' onChange={(e) => onChangeType(e)} value={isStatus} />
                                                    </div>
                                                </div>

                                                <div className='col-md-3 col-lg-3 mt-3'>
                                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                    <button type="button" className="btn btn-default rounded-pill ml-3 pl-4 pr-4 pull-right" onClick={() => handleClear()}>Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-1 m-0 p-0'>
                                        <div className='float-sm-right'>
                                            <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                            <CSVLink
                                                data={getCsvData}
                                                filename='fund-request-reports'
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isViewerOpen && (
                <ImageViewer
                    src={[viewImage]}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
            {isModal && <Modal title={""} width={600} open={isModal} onCancel={() => setIsModal(false)} footer={false}>
                {isSelected ?
                    <><ul>
                        <li><span>User ID</span> : {isSelected.users.username}</li>
                        <li><span>Serial Number</span> : {isSelected.serialNumber}</li>
                        <li><span>Photo</span> : <div className='text-right'>
                            <img src={isSelected.mPhoto} width={'1000px'} alt="" />
                            <button className='btn btn-xs bg-primary pointer m-1' onClick={() => openImageViewer(isSelected.mPhoto)}>Show Image</button>
                            <hr className='m-0' />
                        </div>
                        </li>
                    </ul>
                    <div className='col-md-12 text-right mt-3'>
                        <button className='btn bg-primary pointer m-1' onClick={() => handleApproved(isSelected)}>Approve</button>
                        <button className='btn bg-danger pointer m-1' onClick={() => handleReject(isSelected)}>Reject</button>
                    </div></>
                    : ""}
            </Modal>}
        </div>
    )
}


export default MatmRegistration
import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_QR_CODE_MINI_REPORT, SAVE_QR_CODE_MINI_CHANGE_STATUS } from './../../../Utils/appConstants';

import { Modal, Divider, Button } from 'antd';
import { qrCodeMiniStatus } from './../../../Utils/Common';
import { getRequest, postRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import { DatePicker, Tooltip } from 'antd';
import { getUTCToLocalDateTimeFormat, toDayDate, queryDate, getExportFormat } from '../../../Utils/function';
import { toast } from 'react-toastify';

function QrCodeMini(props) {
    const history = useHistory();
    const csvLink = useRef();
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';

    const [getDataError, setDataError] = useState({});
    const [searchUserId, setSearchUserId] = useState(null);
    const [searchBankRRN, setSearchBankRRN] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    const [searchBankName, setSearchBankName] = useState(null);

    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    // const [userId, setUserId] = useState(props.userId);
    const [isModel, setModel] = useState(false);
    const [isModelData, setModelData] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const [rejectReason, setRejectReason] = useState('');
    const [isAdminBankRRN, setAdminBankRRN] = useState('');

    const [acceptDisable, setAcceptDisable] = useState(true);

    const columns = [{
        name: 'Date Time',
        selector: row => <><Tooltip title={row.createdAtDate}>{getUTCToLocalDateTimeFormat(row.createdAt)}</Tooltip></>,
        width: "200px"
    }, {
        name: 'CallBack Date Time',
        selector: row => <><Tooltip title={row.callDateTime}>{row.callDateTime && getUTCToLocalDateTimeFormat(row.callDateTime)}</Tooltip></>,
        width: "200px"
    }, {
        name: 'CallBack Status',
        selector: row => <><Tooltip title={row.statusText}>{row.statusText}</Tooltip></>,
        width: "180px"
    }, {
        name: 'PayerAccountType',
        selector: row => <><Tooltip title={row.payerAccountType}>{row.payerAccountType}</Tooltip></>,
        width: "180px"
    }, {
        name: 'Tran Id',
        selector: row => <><Tooltip title={row.transactionId}>{row.transactionId}</Tooltip></>,
        width: "180px"
    }, {
        name: 'Amount',
        selector: row => <><Tooltip title={row.amount}>{row.amount}</Tooltip></>,
    }, {
        name: 'User ID',
        selector: row => <><Tooltip title={row.userData.name}>{row.userData && row.userData.username}</Tooltip></>,
    }, {
        name: 'User Type',
        selector: row => <><Tooltip title={row.roleData.name}>{row.roleData && row.roleData.name}</Tooltip></>,
        width: "120px"
    }, {
        name: 'Name',
        selector: row => <><Tooltip title={row.userData.name}>{row.userData && row.userData.name}</Tooltip></>,
        width: "150px"
    }, {
        name: 'Customer Name',
        selector: row => <><Tooltip title={row.aadharName}>{row.aadharName ? row.aadharName : "-"}</Tooltip></>,
        width: "200px"
    }, {
        name: 'Bank Name',
        selector: row => <><Tooltip title={row.bankName}>{row.bankName ? row.bankName : "-"}</Tooltip></>,
        width: "150px"
    }, {
        name: 'User Bank RRN',
        selector: row => <><Tooltip title={row.bankRRN}>{row.bankRRN ? row.bankRRN : "-"}</Tooltip></>,
        width: "150px"
    }, {
        name: 'Bank RRN',
        selector: row => <><Tooltip title={row.isAdminBankRRN}>{row.isAdminBankRRN ? row.isAdminBankRRN : "-"}</Tooltip></>,
        width: "150px"
    }, {
        name: 'Merchant Tran Id',
        selector: row => <><Tooltip title={row.orderid}>{row.orderid ? row.orderid : "-"}</Tooltip></>,
        width: "210px"
    }, {
        name: 'VPA Customer',
        selector: row => <><Tooltip title={row.payerName}>{row.payerName ? row.payerName : "-"}</Tooltip></>,
        width: "200px"
    }, {
        name: 'UPI ID',
        selector: row => <><Tooltip title={row.payerVA}>{row.payerVA ? row.payerVA : "-"}</Tooltip></>,
        width: "200px"
    }, {
        name: 'Status',
        selector: row => qrCodeMiniStatus(row.status),
        width: "120px"
    }, {
        name: 'Action',
        cell: row => <>{([2,4].includes(row.status)) ? "-" : <div onClick={() => handleChangeStatus(row)} className='icon-border border border-danger text-danger pointer'><i className="fa fa-edit" aria-hidden="true"></i></div>}</>,
    }, {
        name: 'Remark',
        selector: row => <><Tooltip title={row.rejectReason}>{row.rejectReason}</Tooltip></>,
        width: "250px"
    }]

    const handleChangeStatus = (data) => {
        setModel(true);
        setModelData(data);
    }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const FormValidate1 = (status) => {
        if (parseInt(status) !== 2) {
            return true;
        } else {
            let formValid = true;
            let formObject = {};
            setDataError({})
            if (!isAdminBankRRN || isAdminBankRRN === "") {
                formObject.isAdminBankRRN = "The Field is required";
                formValid = false;
            } else if (isAdminBankRRN && (isAdminBankRRN.length > 12 || isAdminBankRRN.length < 9)) {
                formObject.isAdminBankRRN = "Bank RRN must be between 9 to 12 digits.";
                formValid = false;
            }
            if(!rejectReason || rejectReason === "") {
                formObject.rejectReason = "The Field is required";
                formValid = false;
            }

            setDataError(formObject);
            return formValid;
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleEdit = (status) => {
        if (status && FormValidate1(status)) {
            setModel(false)
            setLoading(true);
            const payload = {
                id: isModelData && isModelData._id,
                status: status,
                isAdminBankRRN: isAdminBankRRN,
                rejectReason: rejectReason
            }
            postRequest(SAVE_QR_CODE_MINI_CHANGE_STATUS, payload, history).then((response) => {
                if (response.success === true) {
                    getDataReports();
                    setModelData(null)
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchBankRRN = searchBankRRN ? searchBankRRN : '';
        params.searchBankName = searchBankName ? searchBankName : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';
        params.searchStatus = "1,4,5";//'["1","4"]';
        params.isAdmin = true;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_QR_CODE_MINI_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchBankRRN = searchBankRRN ? searchBankRRN : '';
        params.searchBankName = searchBankName ? searchBankName : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';
        params.searchStatus = "1,4"; //'["1","4"]';
        params.isAdmin = true;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_QR_CODE_MINI_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date Time": getExportFormat(e.createdAt),
                        "Trans ID": e.transactionId,
                        "Amount": e.amount,
                        "User ID": e.userData && e.userData.username,
                        "Bank Name": e.bankName,
                        "User Bank RRN": e.bankRRN,
                        "Bank RRN": e.isAdminBankRRN,
                        "Status": qrCodeMiniStatus(e.status),
                        "Remark": e.rejectReason,
                    });
                });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate("");
        setEndDate("");
        setSearchBankRRN('');
        setSearchTransId('');
        setSearchBankName('');
        setSearchUserId('');
    }

    // useEffect(() => {
    //     if(searchBankRRN || searchTransId || searchBankName || searchUserId) {
    //         handleSearch();
    //     }
    // }, [searchBankRRN, searchTransId, searchBankName, searchUserId]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleRRN = (value) => {
        setAdminBankRRN(value)
        if(isModelData.isAdminBankRRN === value) {
            setAcceptDisable(false);
        } else {
            setAcceptDisable(true);
        }
    }

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>QR Mini  <span className='text-red'> Report</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-1">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-lg-4 d-flex'>
                                                    <div className="form-group mr-3 col-6">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Bank RRN" onChange={(e) => setSearchBankRRN(e.target.value)} value={searchBankRRN} />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Bank Name" onChange={(e) => setSearchBankName(e.target.value)} value={searchBankName} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-8'></div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                    </div>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                                </div>
                                                <div className='col-md-2'>
                                                    <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                                    <CSVLink
                                                        data={getCsvData}
                                                        filename='qrcode-mini-report'
                                                        className='hidden'
                                                        ref={csvLink}
                                                        target='_blank'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModel && <Modal title={"User Detail"} width={600} open={isModel} onCancel={() => setModel(false)} footer={null}>
                <>
                    <div className='row'>
                        <div className='col-md-12 form-group'>
                            <ul>
                                <li><strong>User Name:</strong> <span>{isModelData && isModelData.userData.name}</span></li>
                                <li><strong>User Mobile:</strong> <span>{isModelData && isModelData.userData.mobile}</span></li>
                                <li><strong>User ID:</strong> <span>{isModelData && isModelData.userData.username}</span></li>
                                <li><strong>Bank Name:</strong> <span>{isModelData && isModelData.bankName}</span></li>
                                <li><strong>User Bank RRN:</strong> <span>{isModelData && isModelData.bankRRN}</span></li>
                                {isModelData && isModelData.photo && <li><strong>Photo:</strong> <span><img alt={isModelData && isModelData.photo} src={isModelData && isModelData.photo} /></span></li>}
                            </ul>
                        </div>
                        <Divider className='mt-0' />
                        <div className='col-md-12 form-group'>
                            <input type="text" className={`form-control normal-light ${getDataError && getDataError.isAdminBankRRN ? "is-invalid" : ""}`} placeholder="Admin Bank RRN" maxLength={"12"} onChange={(e) => handleRRN(e.target.value)} value={isAdminBankRRN} />
                            {getDataError && getDataError.isAdminBankRRN && <span className="error text-danger">{getDataError.isAdminBankRRN}</span>}
                            <br />
                            <br />
                            <textarea onChange={(e) => setRejectReason(e.target.value)} className={`form-control normal-light ${getDataError && getDataError.rejectReason ? "is-invalid" : ""}`} cols={4} rows={3} placeholder="Comments..." >{rejectReason}</textarea>
                            {getDataError && getDataError.rejectReason && <span className="error text-danger">{getDataError.rejectReason}</span>}
                        </div>
                        <div className='col-md-12 form-group text-right'>
                            <Button className="btn pointer bg-primary mr-2" onClick={() => handleEdit(2)} size={"large"} disabled={acceptDisable}>Accept</Button>
                            <Button className="btn pointer bg-danger mr-2" onClick={() => handleEdit(3)} size={"large"}>Reject</Button>
                            <Button className="btn pointer bg-warning mr-2" onClick={() => handleEdit(4)} size={"large"}>ReCheck</Button>
                        </div>
                    </div>
                </>
            </Modal>}
        </div>
    )
}

export default QrCodeMini;
import React, { useEffect, useState, useRef } from 'react';
// import { APP_KEY, API_URL } from './../config';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
// import { Tabs, Tab } from 'react-bootstrap-tabs';
import { GET_MY_LEDGER_REPORTS } from './../../Utils/appConstants';
import { getRequest } from './../../Utils/AppApi';
import { INRFormat, getUser } from './../../Utils/Common';
// import AppHeader from './../Header/Header';
import { useHistory } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import AppFooter from './../Footer/Footer';
// import Moment from 'react-moment';
import moment from 'moment';

import { DatePicker, Tooltip} from 'antd';
import { getUTCToLocalDateTimeFormat, toDayDate, queryDate } from '../../Utils/function';

const user = getUser();

function Ledger(props) {

    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';
    
    const [isLoading, setLoading] = useState(false);
    const [searchUserId, setSearchUserId] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    const [searchServiceName, setSearchServiceName] = useState(null);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.transactionId && row.transactionId}>{row.transactionId && row.transactionId}</Tooltip>,
        width: "150px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Ref ID / Name',
        selector: row => row.toUserData && row.toUserData.username +" / "+ row.toUserData.name,
        width: "250px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Service Name',
        selector: row => <Tooltip placement="bottom" title={row.serviceName}>{row.serviceName}</Tooltip>,
        width: "200px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    // }, {
    //     name: 'Product',
    //     selector: row => <Tooltip placement="bottom" title={row.product}>{row.product}</Tooltip>,
    //     width: "200px",
    //     headerStyle: (selector, id) => {
    //       return { textAlign: "center" };   // removed partial line here
    //     },
    }, (user.data.role.code === "A") ? {
        name: 'Tran Amt',
        selector: row => row.transactionAmt ? INRFormat(row.transactionAmt) : "-",
        width: "150px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }: {name: 'Tran Amt',
        selector: row => row.transactionAmt ? INRFormat(row.transactionAmt) : "-",
        width: "150px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        }}, {
        name: 'Opening Balance',
        selector: row => row.openingBalance ? INRFormat(row.openingBalance) : "-",
        width: "150px",
    }, {
        name: 'Debit',
        selector: row => (!row.debit) ? "-" : INRFormat(row.debit),
        width: "130px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Credit',
        selector: row => (!row.credit) ? "-" : INRFormat(row.credit),
        width: "130px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Ser. Charge',
        selector: row => row.serviceCharge ? INRFormat(row.serviceCharge) : "-",
    }, {
        name: 'Commission',
        selector: row => row.commission ? INRFormat(row.commission) : "-",
    }, {
        name: 'TDS',
        selector: row => row.tds ? INRFormat(row.tds) : "-",
    }, {
        name: 'Closing Balance',
        selector: row => INRFormat(row.closingBalance),
        width: "150px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Description',
        selector: row => row.description ? <Tooltip placement="bottom" title={row.description && row.description}>{row.description && row.description}</Tooltip> : "-",
        width: "250px",
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = ( data ) => {
        setLoading(true)
        if(data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchUserId = searchUserId ? searchUserId : "";
        params.searchTransId = searchTransId ? searchTransId : "";
        params.searchServiceName = searchServiceName ? searchServiceName : "";

        const queryString = objectToQueryString(params);
        getRequest(`${GET_MY_LEDGER_REPORTS}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
            setLoading(false);
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchUserId = searchUserId ? searchUserId : "";
        params.searchTransId = searchTransId ? searchTransId : "";
        params.searchServiceName = searchServiceName ? searchServiceName : "";

        const queryString = objectToQueryString(params);
        getRequest(`${GET_MY_LEDGER_REPORTS}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({ "Created Date": moment(e.createdAt).format("YYYY-MM-DD HH:mm:ss"), "Updated Date": moment(e.updatedAt).format("YYYY-MM-DD HH:mm:ss"), "TXT Id": e.transactionId, "Ref ID": e.toUserData.username, "Name": e.toUserData.name, "Transaction Amt": e.transactionAmt, "Service Name": e.serviceName, "Debit": e.debit, "Credit": e.credit, "Service Charge": e.serviceCharge, "Commission": e.commission, "TDS": e.tds, "Opening Balance": e.openingBalance, "Closing Balance": e.closingBalance, "Remark": e.description });
                });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        console.log("-----", size)
        setPage(page);
    };
    
    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate("");
        setEndDate("");
        setSearchServiceName('');
        setSearchTransId('');
        setSearchUserId('');
    }

    useEffect(() => {
        if(searchServiceName || searchTransId || searchUserId) {
            handleSearch();
        }
    }, [searchServiceName, searchTransId, searchUserId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Wallet <span className='text-red'>Ledger</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className='card col-md-12'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className='col-md-10 date-search mb-0'>
                                        <div className='row'>
                                            <div className='col-md-4 col-lg-4 d-flex'>
                                                <div className="form-group mr-3">
                                                    <label className='date-search-label mb-0 text-blue'>From</label>
                                                    <DatePicker className="form-control form-control-border" format={dateFormat} value={startDate} onChange={onChangeStartDate} />
                                                </div>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'>To</label>
                                                    <DatePicker className="form-control form-control-border" format={dateFormat} value={endDate} onChange={onChangeEndDate} />
                                                </div>
                                            </div>
                                            {(user.data.role.code !== "P") && <div className='col-md-2 col-lg-2'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'></label>
                                                    <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                </div>
                                            </div>}
                                            <div className='col-md-2 col-lg-2'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'></label>
                                                    <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                </div>
                                            </div>
                                            <div className='col-md-2 col-lg-2'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'></label>
                                                    <input type="text" className="form-control form-control-border" placeholder="Service Name" onChange={(e) => setSearchServiceName(e.target.value)} value={searchServiceName} />
                                                </div>
                                            </div>
                                            <div className='col-md-1 col-lg-1'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="submit" className="btn btn-primary rounded-pill px-3" onClick={() => handleSearch()}>Search</button>
                                            </div>
                                            <div className='col-md-1 col-lg-1'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="button" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className='float-sm-right'>
                                            <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                            <CSVLink
                                                data={getCsvData}
                                                filename='ledger'
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                            {/* <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf"></i> Export to PDF</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid pt-2 pb-5">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        progressPending={isLoading}
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <AppFooter /> */}
        </div>
    )
}

export default Ledger
import React, { useEffect, useState } from 'react';
import { MOBILE_VERFIY_SEND, MOBILE_SEND_VERFIY, PARTNER_REGISTER, JOIN_US_PANCARD_VERIFY, CREATE_USER_AADHARCARD_VERIFY, CREATE_USER_AADHARCARD_OTP_VERIFY } from './../Utils/appConstants';
import { postRequest, getRequest } from '../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../config';
import bgshape from './../assets/bgshape.svg';
import Footer from "./Footer/Footer";
import OtpInput from "react-otp-input";
import { isNumberKey } from '../Utils/Common';
import AdvertiseSlider from "./AdvertiseSlider";

function JoinUs(props) {
  const history = useHistory()
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [appInfo, setAppInfo] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pancardData, setPancardData] = useState("");
  const mobile = useFormInput('');
  const pancard = useFormInput('');
  const aadhaar = useFormInput('');
  const outletName = useFormInput('');
  const email = useFormInput('');

  const [otpAadharCode, setOtpAadharCode] = useState(0);

  const [isAadharNumberVerify, setAadharNumberVerify] = useState(false);
  const [aadharData, setAadharData] = useState("");
  const [isOtpAadharNumber, setOtpAadharNumber] = useState(false);
  //   const [error, setError] = useState(null);
  //   const headers = {
  //     headers: {
  //       "appKey": APP_KEY,
  //     }
  //   }
  // handle button click of login form

  const handleStep1 = () => {
    const payload = {
      mobile: mobile.value
    }
    setLoading(true);
    postRequest(MOBILE_VERFIY_SEND, payload, history).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
        // let code = response.message.split("");
        setStep(step + 1)
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    }).catch(function (error) {
      // console.log(error)
      setLoading(false);
    })
  }

  const handleStep2 = () => {
    const payload = {
      mobile: mobile.value,
      otp: otpValue
      // otp : firstOTP.value + secondOTP.value + thirdOTP.value + fourthOTP.value
    }
    setLoading(true);
    postRequest(MOBILE_SEND_VERFIY, payload, history).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
        setStep(step + 1)
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    }).catch(function (error) {
      // console.log(error)
      setLoading(false);
    })
  }

  const pancardValidation = (text) => {
    let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (regex.test(text)) {
      return true;
    }
    return false;
  }

  const handleStep3 = () => {
    if (pancard.value.toUpperCase()) {
      if (pancardValidation(pancard.value.toUpperCase())) {

        const payload = { pancard: pancard.value.toUpperCase() };
        postRequest(`${JOIN_US_PANCARD_VERIFY}`, payload, history).then((response) => {
          if (response.success === true) {
            setStep(step + 1);
            setPancardData(response.data);
            let checkName = response.data.registered_name.trim();
            const [first, middle, last] = checkName.split(' ');
            setFirstName(first);
            let lastNameAdd = '';
            if (last) {
              lastNameAdd = middle + ' ' + last;
            } else {
              lastNameAdd = middle;
            }
            setLastName(lastNameAdd);
            toast.success(response.message);
          } else {
            toast.error(response.message);
          }
        }).catch(function (error) {
          // console.log(error)
          // setLoading(false);
        })
      } else {
        toast.error("Pancard number is invalid");
      }
    } else {
      toast.error("Pancard is required");
    }
  }

  const handleStep4 = async () => {
    // console.log("aadhaar -----------------", aadhaar.value.length)
    if (aadhaar.value.length === 12) {
      setLoading(true)
      const payload = {
        aadharcard: aadhaar.value,
        roleId: "P",
        name: pancardData && pancardData.registered_name
      };
      postRequest(`${CREATE_USER_AADHARCARD_VERIFY}`, payload, history).then((response) => {
        if (response.success) {
          setOtpAadharNumber(true);
          setAadharData(response.data);
          if (response.alreadysuccess) {
            setAadharNumberVerify(true);
            setStep(step + 1)
          }
          if (!response.alreadysuccess && response.alreadysuccess === "undefined") {
            toast.warning(response.message);
          } else {
            toast.success(response.message);
          }
        } else {
          toast.error(response.message);
        }
        setLoading(false);
      }).catch(function (error) {
        // console.log(error)
        setLoading(false);
      })
    } else {
      toast.error("Aadhar number length should be 12 digit")
    }
  }

  const handleVerifyOtpAadharNumber = () => {
    setLoading(true);
    const payload = {
      aadharcard: aadhaar.value,
      aadharcardOtp: otpAadharCode,
      ref_id: aadharData && aadharData.ref_id
    }
    postRequest(CREATE_USER_AADHARCARD_OTP_VERIFY, payload, history).then((response) => {
      if (response.success === true) {
        setOtpAadharNumber(false);
        setAadharNumberVerify(true);
        setAadharData(response.data);
        setStep(step + 1);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    }).catch(function (error) {
      // console.log(error)
      setLoading(false);
    })
  }

  const handleStep6 = () => {

    setLoading(true);
    let name;
    if (lastName) {
      name = firstName + " " + lastName;
    } else {
      name = firstName;
    }
    const payload = {
      mobile: mobile.value,
      pancard: pancard.value.toUpperCase(),
      firstName: firstName,
      lastName: lastName,
      name: name,
      pancardData: pancardData,
      email: email.value,
      outletName: outletName.value,
      aadharcard: aadhaar.value,
      aadharNumberVerify: isAadharNumberVerify,
      aadharData: aadharData
    }
    postRequest(PARTNER_REGISTER, payload, history).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
        setUsername(response.data.username)
        setPassword(response.data.password)
        setStep(step + 1)
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    }).catch(function (error) {
      // console.log(error)
      setLoading(false);
    })
  }

  const redirectLoginPage = () => {
    history.push({
      pathname: "/login"
    });
  }

  const getAppInfo = () => {
    getRequest(`${API_URL}/appinfo`).then(async (data) => {
      setLoading(false);
      if (data) {
        // console.log("========================", data)
        await setAppInfo(data)
      } else {
        toast.error(data.data.message)
      }
    }).catch(function (error) {
      setLoading(false);
      console.log(error);
    })
  }

  const handleOTPChange = (otp) => {
    setOtpValue(otp)
  }

  useEffect(() => {
    getAppInfo()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className='wrapper'>
      <div className='topbar'>
        <nav className="main-header navbar navbar-expand navbar-light flex-wrap">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a href="\#" className="brand-link">
                <img src="../../logo.png" alt="Logo" className="brand-image" />
              </a>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a href={`https://wa.me/${appInfo.helpNumber}`} target="_blank" rel="noreferrer" className='nav-link'>
                {/* <i className='fas fa-phone-alt mr-1 call-icon'></i> */}
                <i class="fa-brands fa-whatsapp fa-fade fa-lg mr-1 call-icon"></i>
                <span className='helpline-text'>{appInfo.helpNumber}</span>
              </a>
            </li>
            <li className="nav-item pl-0 pl-md-4">
              <a href="mailto:{appInfo.email}" className='nav-link'><i className='fas fa-envelope mr-1 call-icon'></i> <span className='helpline-text'>{appInfo.email}</span></a>
            </li>
          </ul>
        </nav>
      </div>
      <div className='content-wrapper login-outer' style={{ backgroundImage: `url(${bgshape})`, backgroundRepeat: "no-repeat" }}>
        <div className='login-container' >
          <div className=''>
            <div className='row login-background' >
              <bgshape />
              <div className='col-lg-2 text-center'></div>
              <div className='col-md-6 col-lg-5 text-center'>
                <AdvertiseSlider/>
              </div>
              <div className='col-md-6 col-lg-5'>
                <div className="login-box">
                  <div className="">
                    <div className="card-body" style={{ textAlign: "center" }}>
                      {step !== 6 &&
                        <img src="../../logo.png" alt="Logo" width={"222"} className="brand-image" />
                      }
                      {step === 1 && <><h2 className='login-box-heading mt-5 mb-5'>Enter your mobile <br /> number to Continue</h2>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" {...mobile} placeholder="Mobile Number" maxLength={"10"} onKeyPress={() => isNumberKey} />
                        </div>
                        <div className="mt-3">
                          <button type="submit" onClick={() => handleStep1()} className="btn btn-block login-button ">Continue {loading ? "..." : ""}</button>
                        </div>
                        <div className="mt-3 join-button px-5 py-1 pointer" onClick={() => redirectLoginPage()}>
                          <span className='my-1'>Login Now</span>
                        </div>
                      </>}

                      {step === 2 && <><h2 className='login-box-heading mt-5 mb-5'>Enter OTP</h2>
                        <div className="mb-3 mt-3 otp-justify">
                          <OtpInput
                            value={otpValue}
                            separator={
                              <span>
                                <strong></strong>
                              </span>
                            }
                            onChange={(value) => handleOTPChange(value)}
                            isInputNum={true}
                            className="justify-content-md-around"
                            inputStyle={{
                              width: "3rem",
                              height: "3rem",
                              margin: "0 0.9rem",
                              fontSize: "2rem",
                              borderRadius: 4,
                              border: "1px solid rgba(0,0,0,0.3)"
                            }}
                          />
                        </div>

                        <div className="mt-5">
                          <button type="submit" onClick={() => handleStep2()} className="btn btn-block login-button ">Continue {loading ? "..." : ""}</button>
                        </div></>}

                      {step === 3 && <><h2 className='login-box-heading mt-5 mb-5'>Enter your pan number</h2>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" {...pancard} placeholder="Pancard number" maxLength={"10"} />
                        </div>

                        <div className="mt-3">
                          <button type="submit" onClick={() => handleStep3()} className="btn btn-block login-button ">Continue {loading ? "..." : ""}</button>
                        </div></>}

                      {step === 4 && <><h2 className='login-box-heading mt-5 mb-5'>Enter your Detail</h2>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" readOnly={true} placeholder="Pancard First Name" value={firstName} />
                        </div>

                        <div className="input-group mb-3">
                          <input type="text" className="form-control" readOnly={true} placeholder="Pancard Last Name" value={lastName} />
                        </div>

                        <div className="input-group mb-3">
                          <input type="text" className="form-control" {...aadhaar} placeholder="Aadhar Number" maxLength={"12"} onKeyPress={() => isNumberKey} />
                        </div>

                        {isOtpAadharNumber && !isAadharNumberVerify &&
                          <div className="input-group">
                            <div className="col-md-12 mb-3 mt-3 otp-justify">
                              <OtpInput
                                value={otpAadharCode}
                                separator={
                                  <span>
                                    <strong></strong>
                                  </span>
                                }
                                onChange={(value) => setOtpAadharCode(value)}
                                isInputNum={true}
                                numInputs={6}
                                className="justify-content-md-around"
                                inputStyle={{
                                  width: "3rem",
                                  height: "3rem",
                                  // margin: "0 0.9rem",
                                  fontSize: "2rem",
                                  borderRadius: 4,
                                  border: "1px solid rgba(0,0,0,0.3)"
                                }}
                              />
                            </div>
                          </div>}

                        {!isOtpAadharNumber ?
                          <div className="mt-3">
                            <button type="submit" onClick={() => handleStep4()} className="btn btn-block login-button ">Submit to continue {loading ? "..." : ""}</button>
                          </div> : <div className="mt-3">
                            <button type="submit" onClick={() => handleVerifyOtpAadharNumber()} className="btn btn-block login-button ">Submit to continue {loading ? "..." : ""}</button>
                          </div>
                        }</>}

                      {step === 5 && <><div className="mt-3">
                        {aadharData && aadharData.address && aadharData.name && aadharData.dob && <div className="form-group">
                          <div className="custom-file">
                            <ul>
                              <li>Address: <strong>{aadharData.address}</strong></li>
                              <li>Aadhar Name: <strong>{aadharData.name}</strong></li>
                              <li>Date Of Birth: <strong>{aadharData.dob}</strong></li>
                            </ul>
                          </div>
                        </div>}
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" {...outletName} placeholder="Outlet Name" />
                        </div>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" {...email} placeholder="Email Address" />
                        </div>
                        <div className="mt-3">
                          <button type="submit" onClick={() => handleStep6()} className="btn btn-block login-button ">Continue {loading ? "..." : ""}</button>
                        </div>
                      </div>
                      </>}

                      {step === 6 && <>
                        <div>
                          <div className="content-div16">
                            <img className="vector-icon188" alt={"vector"} src="../../images/vector188.svg" />
                            <div className="dear-partner-welcome-to-srpay mt-4">
                              Dear Partner welcome to SR PAY Private Limited Family.
                            </div>
                            <div className="your-registration-is-successfull mt-3">
                              <p className="msg1">
                                Your Registration is successfully. Please login using
                              </p>
                              <p className="user-id-xxxxx-one-time-pas">
                                User ID - <span className="xxxxx-span">{username}</span> &amp; One time Password
                                - <span className="xxxxx-span">{password}</span>
                              </p>
                            </div>
                            <div className="my-4">To</div>
                            <div className="flex-row justify-content-center d-flex" onClick={() => redirectLoginPage()}>
                              <div className="continue">Continue</div>
                              <img className="arrow-icon" alt={"arrow"} src="../../images/arrow-1.svg" />
                            </div>
                          </div>
                        </div>
                      </>}
                      {step !== 6 &&
                        <div className="mt-1 text-center">
                          <p className='forgot'>
                            By continuing you accept our T&C and Privacy Policy
                          </p>
                        </div>
                      }
                      <div className='my-4'><hr /></div>
                      <div className='mb-5 pointer' target="_blank" onClick={() => {
                        // if (process.env.REACT_APP_SRPAY_LINK === "www.srpay1.com") {
                        window.open('https://play.google.com/store/apps/details?id=com.srpaypartner', '_blank')
                        // }
                      }}>
                        <img src='../../googleplay1@3x.png' alt='google' width={"213.74"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      </div>
      <Footer />
    </div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default JoinUs;
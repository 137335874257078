import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_MATM_ORDER_LIST_API, POST_MATM_ORDER_STATUS_API } from './../../Utils/appConstants';
import { getExportFormat, getUTCToLocalDateTimeFormat, toDayDate, queryDate } from './../../Utils/function';
import { getRequest, postRequest } from './../../Utils/AppApi';
import { INRFormat, matmOrderStatus } from './../../Utils/Common';
import { useHistory } from "react-router-dom";
import { DatePicker, Tooltip, Modal, Button } from 'antd';
import { toast } from 'react-toastify';

function PurchaseList() {

    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    const [searchUserId, setSearchUserId] = useState(null);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);

    const [isModal, setModal] = useState(false);
    const [isComment, setComment] = useState('');
    const [isSelectData, setSelectData] = useState({});

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px"
    }, {
        name: 'Name',
        selector: row => row.name,
        width: "180px"
    }, {
        name: 'Mobile',
        selector: row => <Tooltip placement="bottom" title={row.mobile}>{row.mobile}</Tooltip>,
        width: "150px"
    }, {
        name: 'Product',
        selector: row => <Tooltip placement="bottom" title={row.product}>{row.product}</Tooltip>,
        width: "180px"
    }, {
        name: 'Quantity',
        selector: row => <Tooltip placement="bottom" title={row.quantity}>{row.quantity}</Tooltip>,
        width: "160px"
    }, {
        name: 'Amount',
        selector: row => INRFormat(row.amount),
        width: "100px"
    }, {
        name: 'Total Amount',
        selector: row => INRFormat(row.totalAmount),
        width: "100px"
    }, {
        name: 'User ID',
        selector: row => <Tooltip placement="bottom" title={row.users.username + ' / ' + row.users.name}>{row.users.username + ' / ' + row.users.name}</Tooltip>,
        width: "220px"
    }, {
        name: 'Status',
        selector: row => matmOrderStatus(row.orderStatus),
        width: "120px"
    }, {
        name: 'Action',
        selector: row => (row.orderStatus ? "-" : <div className="pointer" onClick={() => handleModal(row)}><button className='btn btn-sm btn-success'>Accept</button></div>),
        width: "120px"
    }, {
        name: 'Remark',
        selector: row => <Tooltip placement="bottom" title={row.comment}>{row.comment}</Tooltip>,
        width: "220px"
    }, ]

    const handleModal = (data) => {
        setModal(true)
        setSelectData(data);
    }
    const handleStatus = (data, status) => {
        const payload = { id: data._id, status: status, comment: isComment };
        postRequest(`${POST_MATM_ORDER_STATUS_API}`, payload, history).then((response) => {
            setModal(false)
            if (response.success === true) {
                getDataReports()
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
    }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchUserId = searchUserId ? searchUserId : "";

        const queryString = objectToQueryString(params);
        getRequest(`${GET_MATM_ORDER_LIST_API}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchUserId = searchUserId ? searchUserId : "";

        const queryString = objectToQueryString(params);
        getRequest(`${GET_MATM_ORDER_LIST_API}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date Time": getExportFormat(e.createdAt),
                        "name": e.name,
                        "Mobile": e.mobile,
                        "Product": e.product,
                        "Quantity": e.quantity,
                        "Amount": e.amount,
                        "Total Amount": e.totalAmount,
                        "User Id": e.users.username + ' / ' + e.users.name,
                        "Status": matmOrderStatus(e.orderStatus),
                        "Remark": e.comment,
                    });
                });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleClear = () => {
        setStartDate("");
        setEndDate("");
        setSearchUserId('');
    }

    const handleSearch = () => {
        getDataReports();
    }

    // useEffect(() => {
    //     if(searchTransId || searchUserId) {
    //         handleSearch();
    //     }
    // }, [searchTransId, searchUserId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Purchase <span className='text-red'>List</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className='card col-md-12'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className='col-md-10 date-search mb-0'>
                                        <div className='row'>
                                            <div className='col-md-4 col-lg-4 d-flex'>
                                                <div className="form-group mr-3">
                                                    <label className='date-search-label mb-0 text-blue'>From</label>
                                                    <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                </div>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'>To</label>
                                                    <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                </div>
                                            </div>
                                            <div className='col-md-2 col-lg-2'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'></label>
                                                    <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                </div>
                                            </div>
                                            <div className='col-md-1 col-lg-1'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="submit" className="btn btn-primary rounded-pill px-3" onClick={() => handleSearch()}>Search</button>
                                            </div>
                                            <div className='col-md-1 col-lg-1'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="button" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className='float-sm-right'>
                                            <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                            <CSVLink
                                                data={getCsvData}
                                                filename='purchase-list'
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                            {/* <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf"></i> Export to PDF</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid pt-2 pb-5">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModal && <Modal title={""} width={600} open={isModal} onCancel={() => setModal(false)} footer={null}>
                <>
                    <div className='row'>
                        <div className='col-md-12 form-group'>
                            <label>Remark: </label>
                            <textarea className='form-group form-control' rows={4} cols={4} onChange={(e) => setComment(e.target.value)}>{isComment}</textarea>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 form-group text-right'>
                        {/* <Popconfirm placement="top" title={"Are you sure want to failed this transaction?"} onConfirm={() => handleStatus(5)} okText="Yes" cancelText="No"><Button className="bg-default pointer mr-2" >Cancel</Button></Popconfirm> */}
                            <Button className="bg-primary pointer" onClick={() => handleStatus(isSelectData, 1)}>Submit</Button>
                        </div>
                    </div>
                </>
            </Modal>}
        </div>
    )
}

export default PurchaseList
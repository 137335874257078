import React, { useEffect, useState, useRef } from 'react';
// import CsvDownloader from 'react-csv-downloader';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { getUTCToLocalDateTimeFormat } from './../../../Utils/function';
import { useHistory } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
import { Tooltip} from 'antd';

import { MOBILE_RECHARGE_REPORT } from './../../../Utils/appConstants';
import { getRequest } from './../../../Utils/AppApi';
// import Moment from 'react-moment';
// import { ToastContainer, toast } from 'react-toastify';
// import moment from 'moment';
// import { DatePicker, Input, Button, Select } from 'antd';
// const { Option } = Select;
// const { RangePicker } = DatePicker;

// // import EletricityBillPayment from './eletricityBillPayment';
// // import WaterBillPayment from './waterBillPayment';
// // import GasBillPayment from './gasBillPayment';
// // import InsuranceBillPayment from './insuranceBillPayment';

function PrepaidReport(props) {
    console.log("props -------",props.search)
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const getMode = props.mode ? props.mode : "";
    const startDate = props.startDate ? props.startDate : "";
    const endDate = props.endDate ? props.endDate : "";
    const search = props.search ? props.search : "";
    
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    
    const columns = [{
        name: 'Date',
        cell: row => getUTCToLocalDateTimeFormat(row.updatedAt),
    }, {
        name: 'TXN ID',
        cell: row => row.transactionId,
    }, {
        name: 'Mobile Number',
        selector: row => row.mobileNumber,
    }, {
        name: 'Mobile Operator',
        selector: row => row.mobileOperator,
    }, {
        name: 'States',
        selector: row => row.states,
    }, {
        name: 'Operator ID',
        selector: row => (row.states) ? row.apiResponse && row.apiResponse.field1 : "-",
    }, {
        name: 'Amount',
        selector: row => row.amount,
    }, {
        name: 'Status',
        cell: row => <>{(row.status) ? <div className="text-success">Success</div> : <div className="text-danger">Failed</div>}</>,
    }, {
        name: 'Remark',
        cell: row => <>{(row.apiResponse) ? <Tooltip placement="bottom" title={row.apiResponse.remark}>{row.apiResponse.status}</Tooltip> : '-'}</>,
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = {
        page: page,
        limit: limit
    };

    const getDataReports = () => {
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        if (search) {
            params.search = search ? search : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${MOBILE_RECHARGE_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        if (search) {
            params.search = search ? search : "";
        }
        const queryString = objectToQueryString(params);
        getRequest(`${MOBILE_RECHARGE_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                console.log(" CSV ------------")
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({ "Mobile Number": e.mobileNumber, "Mobile Operator": e.mobileOperator, "States": e.states, "Amount": e.amount, "Status": e.status, "Date": e.createdAt });
                });
                // await this.setState({ data: res.data.data, csvdata : csvdata });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    useEffect(() => {
        getDataReports();
    }, [page, props.handleSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="container-fluid pt-2 pb-5">
                <div className="col-md-12">
                    <div className='float-sm-right d-flex mb-1'>
                        <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                        <CSVLink
                            data={getCsvData}
                            filename='mobile-recharge-report.csv'
                            className='hidden'
                            ref={csvLink}
                            target='_blank'
                        />
                        <button type="button" className="btn btn-danger">
                            <i className="fa fa-file-pdf"></i> Export to PDF
                        </button>
                    </div>
                    <div className="box-body">
                        <DataTable
                            title={getMode}
                            columns={columns}
                            data={getData.docs}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={getTotalRecord}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrepaidReport
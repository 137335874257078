import CommingSoon from "../../../container/commingsoon";

export default CommingSoon;
// import React, { useEffect, useState, useRef } from 'react';
// // import { APP_KEY, API_URL } from './../config';
// import { CSVLink } from 'react-csv';
// import DataTable from 'react-data-table-component';
// // import { Tabs, Tab } from 'react-bootstrap-tabs';
// import { GET_MY_FUND_REQUEST_REPORT } from './../../../Utils/appConstants';
// import { getRequest } from './../../../Utils/AppApi';
// import { INRFormat, FundRequestValue, setDefaultStatus, FundRequestValueCSV } from './../../../Utils/Common';
// // import AppHeader from './../Header/Header';
// import { useHistory } from "react-router-dom";
// // import { ToastContainer, toast } from 'react-toastify';
// // import AppFooter from './../Footer/Footer';
// // import Moment from 'react-moment';
// import moment from 'moment';

// import { DatePicker, Select } from 'antd';
// import { getUTCToLocalDateFormat, getUTCToLocalDateTimeFormat } from '../../../Utils/function';

// function MyFundRequestReport(props) {
//     const history = useHistory();
//     const csvLink = useRef()
//     const limit = 10;

//     const dateFormat = 'DD-MM-YYYY';
//     const dateDBFormat = 'YYYY-MM-DD';
//     const dateDBFormatCSV = 'YYYY-MM-DD HH:MM:SS';

//     // const [search, setSearch] = useState(null);
//     const [endDate, setEndDate] = useState(null);
//     const [startDate, setStartDate] = useState(null);
//     const [getData, setData] = useState([]);
//     const [getCsvData, setCsvData] = useState([]);
//     const [getTotalRecord, setTotalRecord] = useState(0);
//     const [page, setPage] = useState(1);
//     const [isClear, setIsClear] = useState(false);
//     const [isStatus, setStatus] = useState(null)

//     const columns = [{
//         name: 'DP Date',
//         cell: row => getUTCToLocalDateFormat(row.paymentDepositDate),
//     }, {
//         name: 'FTR ID',
//         selector: row => row.FTRReferenceNumber,
//     }, {
//         name: 'Amount',
//         selector: row => INRFormat(row.amount),
//     }, {
//         name: 'Trans Id',
//         selector: row => row.transactionId,
//     }, {
//         name: 'Bank Name',
//         selector: row => row.bankdetails.bankName,
//     }, {
//         //     name: 'AC Number',
//         //     selector: row => row.accountNumber,
//         // }, {
//         name: 'Mode',
//         selector: row => row.mode,
//     }, {
//         //     name: 'Location',
//         //     selector: row => row.location,
//         // }, {
//         name: 'Bank Ref',
//         selector: row => row.tranReasonId,
//     }, {
//         name: 'Create Date',
//         cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
//     }, {
//         name: 'Update Date',
//         cell: row => getUTCToLocalDateTimeFormat(row.updatedAt),
//     }, {
//         name: 'Status',
//         cell: row => <>{FundRequestValue(row.status)}</>,
//         // }, {
//         //     name: 'Bank Remark',
//         //     selector: row => row.bankRemark,
//         // }, {
//         //     name: 'Reject Reason',
//         //     selector: row => row.rejectReason,
//     }]

//     const objectToQueryString = (obj) => {
//         var str = [];
//         for (var p in obj)
//             if (obj.hasOwnProperty(p)) {
//                 str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
//             }
//         return str.join("&");
//     }

//     const params = { page: page, limit: limit };

//     const getDataReports = () => {
//         if (startDate && endDate) {
//             params.startDate = startDate ? startDate : "";
//             params.endDate = endDate ? endDate : "";
//         }

//         params.status = isStatus;

//         const queryString = objectToQueryString(params);
//         getRequest(`${GET_MY_FUND_REQUEST_REPORT}?${queryString}`, history).then((response) => {
//             if (response.success === true) {
//                 setIsClear(false)
//                 setData(response.data);
//                 setTotalRecord(response.data.totalDocs);
//                 setPage(response.data.page);
//             } else {
//                 setData([]);
//                 setTotalRecord(0);
//                 setPage(1);
//             }
//         }).catch(function (error) {
//             console.log(error)
//             // setLoading(false);
//         })
//     } // eslint-disable-line react-hooks/exhaustive-deps

//     const getCSVDataList = () => {
//         let params = {}
//         params.exportsCsv = true;
//         if (startDate && endDate) {
//             params.startDate = startDate ? startDate : "";
//             params.endDate = endDate ? endDate : "";
//         }

//         params.status = isStatus;

//         const queryString = objectToQueryString(params);
//         getRequest(`${GET_MY_FUND_REQUEST_REPORT}?${queryString}`, history).then((response) => {
//             if (response.success === true) {
//                 let csvdataRecord = [];
//                 let csvdata = response.data;
//                 csvdata.map((e) => {
//                     return csvdataRecord.push({
//                         "Deposit Date": moment(e.paymentDepositDate).format("DD/MM/YYYY"),
//                         "FTR Reference Number": e.FTRReferenceNumber,
//                         "Amount": INRFormat(e.amount),
//                         "Trans ID": e.transactionId,
//                         "Mode": e.mode,
//                         "Bank Ref": e.tranReasonId,
//                         "Bank Name": e.bankdetails.bankName,
//                         "Account Number": e.accountNumber.toString(),
//                         "Branch IFSC": e.branchIfsc,
//                         "Location": e.location,
//                         "created Date": moment(e.createdAt, dateDBFormat).format(dateDBFormatCSV),
//                         "Update Date": moment(e.updatedAt, dateDBFormat).format(dateDBFormatCSV),
//                         "Status": FundRequestValueCSV(e.status),
//                         "My Remark": e.bankRemark,
//                         "Reject Reason": e.rejectReason,
//                     });
//                 });
//                 setCsvData(csvdataRecord);
//                 csvLink.current.link.click()
//             } else {
//                 setCsvData([]);
//             }
//         }).catch(function (error) {
//             console.log(error)
//             // setLoading(false);
//         })
//     }

//     const handlePageChange = page => {
//         setPage(page);
//     };

//     const onChangeStartDate = (date, dateString) => {
//         // console.log(date, dateString);
//         setStartDate(moment(dateString, dateFormat).format(dateDBFormat))
//     };

//     const onChangeEndDate = (date, dateString) => {
//         // console.log(date, dateString);
//         setEndDate(moment(dateString, dateFormat).format(dateDBFormat))
//     };

//     const onChangeType = (value) => {
//         // const { value } = event;
//         console.log(value);
//         setStatus(value);
//     }

//     const handleClear = () => {
//         setStartDate(null);
//         setEndDate(null);
//         setStatus(null);
//         setIsClear(true)
//     }

//     const handleSearch = () => {
//         getDataReports();
//     }

//     useEffect(() => {
//         getDataReports();
//     }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

//     useEffect(() => {
//         if (isClear) {
//             getDataReports();
//         }
//     }, [isClear]) // eslint-disable-line react-hooks/exhaustive-deps

//     return (
//         <div>
//             {/* <AppHeader /> */}
//             <div className="content-wrapper pt-2">
//                 <div className="content-header my-0 py-0">
//                     <div className="container-fluid">
//                         <div className="row mb-0 align-items-center">
//                             <div className="col-sm-6">
//                                 <h1 className='page-title text-blue font-merriweather'>Fund Request <span className='text-red'>Status</span></h1>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="content">
//                     <div className="container-fluid pt-2 pb-5">
//                         <div className="row">
//                             <div className='card rounded-0 col-md-12'>
//                                 <div className="row align-items-center mt-4 mx-3">
//                                     <div className='col-md-8'>
//                                         <div className='date-search mb-0'>
//                                             <div className='row'>
//                                                 <div className='col-sm-3 col-lg-2'>
//                                                     <div className="form-group">
//                                                         <label className='date-search-label mb-0 text-blue'>From</label>
//                                                         <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} allowClear />
//                                                     </div>
//                                                 </div>
//                                                 <div className='col-sm-3 col-lg-2'>
//                                                     <div className="form-group">
//                                                         <label className='date-search-label mb-0 text-red'>To</label>
//                                                         <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} allowClear />
//                                                     </div>
//                                                 </div>
//                                                 <div className='col-sm-3 col-lg-3'>
//                                                     <div className="form-group">
//                                                         <label className='date-search-label mb-0'>FTR Status</label>
//                                                         <Select options={setDefaultStatus} className={`normal-light w-100`} classNamePrefix="select" placeholder="Select" name='mode' onChange={(e) => onChangeType(e)} value={isStatus} />
//                                                     </div>
//                                                 </div>

//                                                 <div className='col-sm-3 col-lg-3 mt-3 d-flex'>
//                                                     {/* <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div> */}
//                                                     <div>
//                                                         <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
//                                                     </div><div>
//                                                         <button type="button" className="btn btn-default rounded-pill ml-3 pl-4 pr-4 pull-right" onClick={() => handleClear()}>Reset</button>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className='col-md-4'>
//                                         <div className='float-sm-right'>
//                                             <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
//                                             <CSVLink
//                                                 data={getCsvData}
//                                                 filename='my-fund-request-reports'
//                                                 className='hidden'
//                                                 ref={csvLink}
//                                                 target='_blank'
//                                             />
//                                             <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf"></i> Export to PDF</button>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="container-fluid">
//                                     <DataTable
//                                         title={""}
//                                         columns={columns}
//                                         data={getData.docs}
//                                         highlightOnHover
//                                         pagination
//                                         paginationServer
//                                         paginationTotalRows={getTotalRecord}
//                                         onChangeRowsPerPage={getDataReports}
//                                         onChangePage={handlePageChange}
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {/* <AppFooter /> */}
//         </div>
//     )
// }


// export default MyFundRequestReport
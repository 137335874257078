import React, { useEffect, useState, useRef } from 'react';
import { isNumber, FTRNumberMake, getParentPageTitleLable } from './../../Utils/Common';
import { SEND_COD_REQUEST_MONEY_SAVE, POST_FUND_TOPUP_SAVE, GET_PARENT_PROFILE } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
import AfterSave from './../../container/afterpayment';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import AppFooter from './Footer/Footer';

function CodRequest(props) {
    const history = useHistory();
    const [isLabel, setLabel] = useState(null);
    const [getDataField, setDataField] = useState([]); // eslint-disable-line react-hooks/exhaustive-deps
    const [getDataError, setDataError] = useState({}); // eslint-disable-line react-hooks/exhaustive-deps
    const [distributorId, setDistributorId] = useState("");
    const [distributorName, setDistributorName] = useState("");
    const [distributorMobile, setDistributorMobile] = useState("");
    const [myWalletBalance, setWalletBalance] = useState(0);
    const [currentOutStanding, setCurrentOutStanding] = useState(0);
    const [isSubmit, setSubmit] = useState(false);

    const FTRReferenceNumberInput = useRef(null);
    const distributorIdInput = useRef(null);
    const distributorNameInput = useRef(null);
    const contactNumberInput = useRef(null);
    const currentWalletBalanceInput = useRef(null);
    const amountRequestedInput = useRef(null);
    const currentOutstandingInput = useRef(null);
    const remarkInput = useRef(null);

    const clearFormField = () => {
        amountRequestedInput.current.value = "";
        setDataField([])
        setDataError({})
    }

    const getParentProfile = () => {
        let URL = "";
        if (props.requestMoneyUserId) {
            URL = GET_PARENT_PROFILE + '?userId=' + props.requestMoneyUserId
        } else {
            URL = GET_PARENT_PROFILE
        }
        getRequest(URL, history).then((response) => {
            if (response.success === true) {
                setDistributorMobile(response.data.parentdetail.mobile);
                setDistributorId(response.data.userdetail.username);
                setDistributorName(response.data.userdetail.name);
                setWalletBalance(response.data.walletdetail.balance);
                setCurrentOutStanding(response.data.outstanding.closingBalance);
            } else {
                setDistributorMobile("");
                setDistributorId("");
                setDistributorName("");
                setWalletBalance(0);
                setCurrentOutStanding(0);
            }
        }).catch(function (error) {
            console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        let array = ["amountRequested"];

        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                // console.log("element -------", element)
                formObject[element] = true;
                formValid = false;
            }
        });

        setDataError(formObject);
        return formValid;
    }

    const handleSubmit = () => {
        if (FormValidate()) {
            const payload = {
                FTRReferenceNumber: getDataField.FTRReferenceNumber,
                parentId: distributorId,
                parentName: distributorName,
                currentOutstanding: currentOutStanding,
                currentWalletBalance: myWalletBalance,
                amountRequested: getDataField.amountRequested,
                contactNumber: distributorMobile,
                remark: getDataField.remark,
                devicetype: "W",
            }
            let URL = "";
            if (props.requestMoneyUserId) {
                payload.amount = payload.amountRequested
                payload.userId = props.requestMoneyUserId
                URL = POST_FUND_TOPUP_SAVE;
            } else {
                URL = SEND_COD_REQUEST_MONEY_SAVE;
            }
            postRequest(URL, payload, history).then((response) => {
                if (response.success === true) {
                    setSubmit(true)
                    setDataField([]);
                    setDataError({});
                    clearFormField();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
                // setLoading(false);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    useEffect(() => {
        getParentProfile();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLabel(getParentPageTitleLable());
        setDataField({ ...getDataField, FTRReferenceNumber: FTRNumberMake(6) });
    }, [isSubmit]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {isSubmit ? <AfterSave status={1} message={"COD Request send"}/> :
            (props.isRequestMoney ?
                <>
                    <div className='card rounded-0'>
                        <div className='card-body'>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>FTR Reference Number</label>
                                        <input type="text" className={`form-control normal-light pr-110`} name="FTRReferenceNumber" ref={FTRReferenceNumberInput} maxLength={30} readOnly={true} onChange={(e) => onChangeValue(e)} placeholder="FTR Reference Number*" value={getDataField && getDataField.FTRReferenceNumber} />
                                        {getDataError && getDataError.FTRReferenceNumber && <span className="error text-danger">The field is required</span>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Parent ID</label>
                                        <input type="text" className={`form-control normal-light pr-110`} name="distributorId" ref={distributorIdInput} maxLength={10} onChange={(e) => onChangeValue(e)} placeholder={isLabel + " ID*"} onKeyDown={isNumber} value={distributorId} readOnly={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{isLabel} Name</label>
                                        <input type="text" className={`form-control normal-light pr-110`} name="distributorName" ref={distributorNameInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder={isLabel + " Name*"} onKeyDown={isNumber} readOnly={true} value={distributorName} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Contact Number</label>
                                        <input type="text" className={`form-control normal-light pr-110`} name="contactNumber" ref={contactNumberInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Contact Number*" value={distributorMobile} readOnly={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Current Outstanding</label>
                                        <input type="text" className={`form-control normal-light pr-110`} name="currentOutstanding" ref={currentOutstandingInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Current Outstanding*" value={currentOutStanding} readOnly={true} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Current Wallet Balance</label>
                                        <input type="text" className={`form-control normal-light pr-110`} name="currentWalletBalance" ref={currentWalletBalanceInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Current Wallet Balance*" value={myWalletBalance} readOnly={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Amount Requested</label>
                                        <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.amountRequested ? "is-invalid" : ""}`} name="amountRequested" ref={amountRequestedInput} maxLength={6} onChange={(e) => onChangeValue(e)} placeholder="Amount Requested*" value={getDataField && getDataField.amountRequested} onKeyDown={isNumber} />
                                        {getDataError && getDataError.amountRequested && <span className="error text-danger">The field is required</span>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.remark ? "is-invalid" : ""}`} name="remark" ref={remarkInput} maxLength={160} onChange={(e) => onChangeValue(e)} placeholder="" value={getDataField && getDataField.remark} />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">
                                <button type="clear" className="btn btn-default mr-1 rounded-pill pl-4 pr-4 border border-danger" onClick={() => clearFormField()}>Cancel</button>
                                <button type="submit" className="btn btn-default rounded-pill pl-4 pr-4 border border-danger" onClick={() => handleSubmit()}>Submit</button>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    {/* <AppHeader /> */}
                    <div className="content-wrapper pt-2">
                        <div className="content">
                            <div className="container-fluid pt-0 pb-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="content-header my-0 py-0">
                                            <div className="row mb-4 align-items-center">
                                                <div className="col-sm-12">
                                                    <h1 className='page-title text-blue font-merriweather'>COD <span className='text-red'>Request</span></h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card rounded-0'>
                                            <div className='card-body'>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>FTR Reference Number</label>
                                                            <input type="text" className={`form-control normal-light pr-110`} name="FTRReferenceNumber" ref={FTRReferenceNumberInput} maxLength={30} readOnly={true} onChange={(e) => onChangeValue(e)} placeholder="FTR Reference Number*" value={getDataField && getDataField.FTRReferenceNumber} />
                                                            {getDataError && getDataError.FTRReferenceNumber && <span className="error text-danger">The field is required</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{isLabel} ID</label>
                                                            <input type="text" className={`form-control normal-light pr-110`} name="distributorId" ref={distributorIdInput} maxLength={10} onChange={(e) => onChangeValue(e)} placeholder={isLabel + " ID*"} onKeyDown={isNumber} value={distributorId} readOnly={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{isLabel} Name</label>
                                                            <input type="text" className={`form-control normal-light pr-110`} name="distributorName" ref={distributorNameInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder={isLabel + " Name*"} onKeyDown={isNumber} readOnly={true} value={distributorName} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Contact Number</label>
                                                            <input type="text" className={`form-control normal-light pr-110`} name="contactNumber" ref={contactNumberInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Contact Number*" value={distributorMobile} readOnly={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Current Outstanding</label>
                                                            <input type="text" className={`form-control normal-light pr-110`} name="currentOutstanding" ref={currentOutstandingInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Current Outstanding*" value={currentOutStanding} readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Current Wallet Balance</label>
                                                            <input type="text" className={`form-control normal-light pr-110`} name="currentWalletBalance" ref={currentWalletBalanceInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Current Wallet Balance*" value={myWalletBalance} readOnly={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Amount Requested</label>
                                                            <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.amountRequested ? "is-invalid" : ""}`} name="amountRequested" ref={amountRequestedInput} maxLength={6} onChange={(e) => onChangeValue(e)} placeholder="Amount Requested*" value={getDataField && getDataField.amountRequested} onKeyDown={isNumber} />
                                                            {getDataError && getDataError.amountRequested && <span className="error text-danger">The field is required</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Remark</label>
                                                            <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.remark ? "is-invalid" : ""}`} name="remark" ref={remarkInput} maxLength={160} onChange={(e) => onChangeValue(e)} placeholder="" value={getDataField && getDataField.remark} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer text-right">
                                                    <button type="clear" className="btn btn-default mr-1 rounded-pill pl-4 pr-4 border border-danger" onClick={() => clearFormField()}>Cancel</button>
                                                    <button type="submit" className="btn btn-default rounded-pill pl-4 pr-4 border border-danger" onClick={() => handleSubmit()}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="content-header my-0 py-0">
                                        </div>
                                        <div className='card rounded-0'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
                    </div>
                    {/* <AppFooter /> */}
                </>
            )}
        </div>
    )
}

export default CodRequest;
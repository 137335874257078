import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_QR_CODE_MINI_REPORT } from './../../../Utils/appConstants';
import { qrCodeMiniStatusArray, qrCodeMiniStatus } from './../../../Utils/Common';
import PropagateLoader from "react-spinners/PropagateLoader";
import { getRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { DatePicker, Tooltip, Select } from 'antd';
import { getUTCToLocalDateTimeFormat, toDayDate, queryDate, getExportFormat } from '../../../Utils/function';

function QrCodeMiniHistory() {
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    
    const [searchUserId, setSearchUserId] = useState(null);
    const [searchBankRRN, setSearchBankRRN] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    const [searchBankName, setSearchBankName] = useState(null);
    const [searchStatus, setSearchStatus] = useState(null);

    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [isLoading, setLoading] = useState(false);

    const columns = [{
        name: 'Date Time',
        selector: row => <><Tooltip title={row.createdAt}>{getUTCToLocalDateTimeFormat(row.createdAt)}</Tooltip></>,
        width: "200px"
    }, {
        name: 'CallBack Date Time',
        selector: row => <><Tooltip title={row.callDateTime}>{row.callDateTime && getUTCToLocalDateTimeFormat(row.callDateTime)}</Tooltip></>,
        width: "200px"
    }, {
        name: 'CallBack Status',
        selector: row => <><Tooltip title={row.statusText}>{row.statusText}</Tooltip></>,
        width: "180px"
    }, {
        name: 'PayerAccountType',
        selector: row => <><Tooltip title={row.payerAccountType}>{row.payerAccountType}</Tooltip></>,
        width: "180px"
    }, {
        name: 'Tran Id',
        selector: row => <><Tooltip title={row.transactionId}>{row.transactionId}</Tooltip></>,
        width: "180px"
    }, {
        name: 'Amount',
        selector: row => <><Tooltip title={row.amount}>{row.amount}</Tooltip></>,
    }, {
        name: 'User ID',
        selector: row => <><Tooltip title={row.userData.name}>{row.userData && row.userData.username}</Tooltip></>,
    }, {
        name: 'User Type',
        selector: row => <><Tooltip title={row.roleData.name}>{row.roleData && row.roleData.name}</Tooltip></>,
        width: "120px"
    }, {
        name: 'Name',
        selector: row => <><Tooltip title={row.userData.name}>{row.userData && row.userData.name}</Tooltip></>,
        width: "150px"
    }, {
        name: 'Customer Name',
        selector: row => <><Tooltip title={row.aadharName}>{row.aadharName ? row.aadharName : "-"}</Tooltip></>,
        width: "200px"
    }, {
        name: 'Bank Name',
        selector: row => <><Tooltip title={row.bankName}>{row.bankName ? row.bankName : "-"}</Tooltip></>,
        width: "150px"
    }, {
        name: 'User Bank RRN',
        selector: row => <><Tooltip title={row.bankRRN}>{row.bankRRN ? row.bankRRN : "-"}</Tooltip></>,
        width: "150px"
    }, {
        name: 'Bank RRN',
        selector: row => <><Tooltip title={row.isAdminBankRRN}>{row.isAdminBankRRN ? row.isAdminBankRRN : "-"}</Tooltip></>,
        width: "150px"
    }, {
        name: 'Merchant Tran Id',
        selector: row => <><Tooltip title={row.orderid}>{row.orderid ? row.orderid : "-"}</Tooltip></>,
        width: "210px"
    }, {
        name: 'VPA Customer',
        selector: row => <><Tooltip title={row.payerName}>{row.payerName ? row.payerName : "-"}</Tooltip></>,
        width: "200px"
    }, {
        name: 'UPI ID',
        selector: row => <><Tooltip title={row.payerVA}>{row.payerVA ? row.payerVA : "-"}</Tooltip></>,
        width: "200px"
    }, {
        name: 'Status',
        selector: row => qrCodeMiniStatus(row.status),
        width: "120px"
    }, {
        name: 'Remark',
        selector: row => <><Tooltip title={row.rejectReason}>{row.rejectReason}</Tooltip></>,
        width: "250px"
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchBankRRN = searchBankRRN ? searchBankRRN : '';
        params.searchBankName = searchBankName ? searchBankName : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';
        params.searchStatus = searchStatus ? searchStatus : '';
        params.isAdmin = true;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_QR_CODE_MINI_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response && response.data);
                setTotalRecord(response && response.data.totalDocs);
                setPage(response && response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        setLoading(true);
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchBankRRN = searchBankRRN ? searchBankRRN : '';
        params.searchBankName = searchBankName ? searchBankName : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';
        params.searchStatus = searchStatus ? searchStatus : '';
        params.isAdmin = true;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_QR_CODE_MINI_REPORT}?${queryString}`, history).then((response) => {
            if (response.success) {
                setLoading(false);
                let csvdataRecord = [];
                let csvdata = response.data;
                
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date Time": getExportFormat(e.createdAt),
                        "CallBack Date Time": e.callDateTime ? getExportFormat(e.callDateTime): "",
                        "CallBack Status": e.statusText,
                        "Payer Account Type": e.payerAccountType,
                        "Trans ID": e.transactionId,
                        "Amount": e.amount,
                        "User ID": e.userData && e.userData.username,
                        "User Type": e.roleData && e.roleData.name,
                        "User Name": e.userData && e.userData.name,
                        "Customer Name": e.aadharName,
                        "Bank Name": e.bankName,
                        "User Bank RRN": e.bankRRN,
                        "Bank RRN": e.isAdminBankRRN,
                        "Marchat Trans Id": e.orderid,
                        "VPA Customer": e.payerName,
                        "UPI ID": e.payerVA,
                        "Status": qrCodeMiniStatus(e.status),
                        "Remark": e.rejectReason,
                    });
                });
                console.log("change -----------1", csvLink)
                setCsvData(csvdataRecord);
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 500);
                // if(csvResponse) {
                //     setLoading(false);
                // }
            } else {
                setCsvData([]);
                setLoading(false);
            }
            
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const onChangeType = (value) => {
        // const { value } = event;
        setSearchStatus(value);
    }

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate("");
        setEndDate("");
        setSearchBankRRN('');
        setSearchTransId('');
        setSearchBankName('');
        setSearchUserId('');
    }

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>QR Mini  <span className='text-red'> History</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-1">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-lg-4 d-flex'>
                                                    <div className="form-group mr-3 col-6">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Bank RRN" onChange={(e) => setSearchBankRRN(e.target.value)} value={searchBankRRN} />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Bank Name" onChange={(e) => setSearchBankName(e.target.value)} value={searchBankName} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-lg-2 d-flex'>
                                                    <div className="form-group col-12">
                                                        <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                                        <Select options={qrCodeMiniStatusArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Status --" name='status' onChange={(e) => onChangeType(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-8'></div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                    </div>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                                </div>
                                                <div className='col-md-2'>
                                                    <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                                    <CSVLink
                                                        data={getCsvData}
                                                        filename='qrcode-mini-history'
                                                        className='hidden'
                                                        ref={csvLink}
                                                        target='_blank'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QrCodeMiniHistory;
import React, { useEffect, useState, /* useRef */ } from 'react';
// import { APP_KEY, API_URL } from './../config';
// import { CSVLink } from 'react-csv';
// import DataTable from 'react-data-table-component';
// import { hideCharters } from './../Utils/Common';
// import { Tabs, Tab } from 'react-bootstrap-tabs';
import { GET_ALL_BANK_DETAIL_INFO } from './../Utils/appConstants';
import { getRequest } from './../Utils/AppApi';
// import AppHeader from './Header/Header';
import { useHistory } from "react-router-dom";
import { ToastContainer, /*toast*/ } from 'react-toastify';
// import AppFooter from './Footer/Footer';
// import Moment from 'react-moment';
// import moment from 'moment';
// import { Popconfirm } from 'antd';
// const text = 'Are you sure to delete this data?';
function BankDetail(props) {

    const history = useHistory();  // eslint-disable-line react-hooks/exhaustive-deps
    // const csvLink = useRef()
    const limit = 10;
    // const dateFormat = 'DD-MM-YYYY';
    // const [search, setSearch] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    // const [startDate, setStartDate] = useState(null);
    // const [getDataField, setDataField] = useState([]); // eslint-disable-line react-hooks/exhaustive-deps
    // const [getDataError, setDataError] = useState({}); // eslint-disable-line react-hooks/exhaustive-deps
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    // const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    // const [isEdit, setIsEdit] = useState(false);
    // const nameInput = useRef(null);
    // const accountNumberInput = useRef(null);
    // const branchAddressInput = useRef(null);
    // const bankNameInput = useRef(null);
    // const ifscCodeInput = useRef(null);
    // const remarkInput = useRef(null);  // eslint-disable-line react-hooks/exhaustive-deps
    // const customerMobileInput = useRef(null); 
    // const mobile = useFormInput('');

    // const clearFormField = () => {
    //     nameInput.current.value = "";
    //     accountNumberInput.current.value = "";
    //     branchAddressInput.current.value = "";
    //     bankNameInput.current.value = "";
    //     ifscCodeInput.current.value = "";
    //     remarkInput.current.value = "";
    //     setDataField([]);
    //     setDataError({});
    //     setIsEdit(false);
    // }

    // const columns = [{
    //     name: 'Bank Name',
    //     selector: row => row.name,
    // }, {
    //     name: 'Account Number',
    //     selector: row => hideCharters(row.accountNumber),
    // }, {
    //     name: 'Bank Name',
    //     selector: row => row.bankName,
    // }, {
    //     name: 'IFSC Code',
    //     selector: row => row.ifscCode,
    // }, {
    //     name: 'Branch Address',
    //     selector: row => row.branchAddress,
    // }, {
    //     name: 'remark',
    //     selector: row => row.remark,
    // }] // eslint-disable-line react-hooks/exhaustive-deps

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    } // eslint-disable-line react-hooks/exhaustive-deps

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        // if (startDate && endDate) {
        //   params.startDate = startDate ? startDate : "";
        //   params.endDate = endDate ? endDate : "";
        // }

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ALL_BANK_DETAIL_INFO}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                // setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                // setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    // const getCSVDataList = () => {
    //   let params = {}
    //   params.exportsCsv = true;
    //   if (startDate && endDate) {
    //     params.startDate = startDate ? startDate : "";
    //     params.endDate = endDate ? endDate : "";
    //   }

    //   const queryString = objectToQueryString(params);
    //   getRequest(`${BANK_DETAIL}?${queryString}`, history).then((response) => {
    //     if (response.success === true) {
    //       let csvdataRecord = [];
    //       let csvdata = response.data;
    //       csvdata.map((e) => {
    //         return csvdataRecord.push({ "Date": moment(e.createdAt).format("DD/MM/YYYY"), "Reference Number": e.accountNumber, "bankName": e.bankName, "Bank Name": e.bankName, "Card Number": e.branchAddress, "Card Holder Name": e.remark, "Customer Mobile": e.customerMobile, "Card Type": e.cardType, "Marchant Ref": e.marchantRef, "Bank Ref Number": e.bankRefNumber, "TXT Status": (e.txtStatus) ? "success" : "failed", "Payment Status": (e.paymentStatus) ? "success" : "failed" });
    //       });
    //       setCsvData(csvdataRecord);
    //       csvLink.current.link.click()
    //     } else {
    //       setCsvData([]);
    //     }
    //   }).catch(function (error) {
    //     console.log(error)
    //     // setLoading(false);
    //   })
    // } // eslint-disable-line react-hooks/exhaustive-deps

    // const handlePageChange = page => {
    //     setPage(page);
    // }; // eslint-disable-line react-hooks/exhaustive-deps

    // const onChangeValue = (event) => {
    //     const { name, value } = event.target;
    //     setDataField({ ...getDataField, [name]: value });
    // } // eslint-disable-line react-hooks/exhaustive-deps

    // const FormValidate = () => {
    //     let formValid = true;
    //     let formObject = {};
    //     setDataError({})
    //     if (getDataField && getDataField.length === 0) {
    //         formValid = false;
    //     }
    //     let array = [
    //         "name", "bankName", "accountNumber", "branchAddress", "remark", "ifscCode",
    //     ];

    //     array.forEach(element => {
    //         if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
    //             formObject[element] = true;
    //             formValid = false;
    //         }
    //     });

    //     setDataError(formObject);
    //     return formValid;
    // } // eslint-disable-line react-hooks/exhaustive-deps

    // const handleEditView = (event, data) => {
    //     setDataField(data)
    //     setIsEdit(true);
    // }

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Bank Detail <span className='text-red'>Info</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                                {getData.map((element) => {
                                    return <div className="card mr-2 col-md-3 d-flex">
                                        <div className="card-body">
                                            <h5 className="card-title">{element.bankName}</h5>
                                            {/* <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
                                            <p className="card-text m-0">A/C Name: {element.name}</p>
                                            <p className="card-text m-0">A/C Number: {element.accountNumber}</p>
                                            <p className="card-text m-0">IFSC Code: {element.ifscCode}</p>
                                            <p className="card-text m-0">Branch Address: {element.branchAddress}</p>
                                            <p className="card-text m-0">Mode: {element.modes.toString()}</p>
                                            <p className="card-text m-0">Remark: {element.remark}</p>
                                        </div>
                                    </div>
                                })}
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
            </div>
            {/* <AppFooter /> */}
        </div>
    )
}

export default BankDetail;
import moment from 'moment';
// return the user data from the session storage
export const getParentPageTitleLable = () => {
  let user = getUser();
  let code = user.data.role.code;
  let label;
  switch (code) {
    case "P": label = "Distributor"; break;
    case "D": label = "Master Distributor"; break;
    case "MD": label = "Super Distributor"; break;
    case "SD": label = "State Head"; break;
    default: break;
  }
  return label;
}

export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token');
}

export const getPayUToken = () => {
  const userStr = sessionStorage.getItem('user');
  const data = JSON.parse(userStr);
  return data.data.bbps_access_token;
  // return sessionStorage.getItem('user.data.bbps_access_token');
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
}

export const updateUserSession = (user) => {
  sessionStorage.setItem('user', JSON.stringify(user));
}

export function FTRNumberMake(length) {
  var result = '';
  // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var characters = '0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return "S" + moment().utcOffset('+05:30').format('YYMMDD') + result;
  // return "FTR" + moment(Date.now(),"ddd MMM DD YYYY HH:mm:ss ZZ").format("YYYY");
}

export const isNumber = (event) => {
  // console.log("----------",event.key)
  // const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')
  if (event.key === "Backspace" || event.key === "." || event.key === "Tab" || event.key === "v") {
    return true
  } else if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
}

export const isAlphabetOnly = (value) => {
  // const value = event.target.value;
  const isValid = (/^[a-zA-Z\s]*$/).test(value);
  if (!isValid) {
    return false;
  } else {
    return true;
  }
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const isNumberKey = (txt, evt) => {
  console.log("evt--------", evt)
  console.log("kkk0--------", txt)

  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;

  // var charCode = (evt.which) ? evt.which : evt.keyCode;
  // if (charCode === 46) {
  //   //Check if the text already contains the . character
  //   if (txt.value.indexOf('.') === -1) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // } else {
  //   if (charCode > 31 && (charCode < 48 || charCode > 57))
  //     return false;
  // }
  // return true;
}

export const kycStatusValue = (val) => {
  // console.log("-------", val)
  if (val === 0) {
    return <div className="text-warning">Pending</div>;
  } else if (val === 1) {
    return <div className="text-primary">Inprocess</div>;
  } else if (val === 2) {
    return <div className="text-danger">Rejected</div>;
  } else if (val === 3) {
    return <div className="text-success">Success</div>;
  }
}


export const shopStatusValue = (val) => {
  // console.log("-------", val)
  if (val === 0) {
    return <div className="text-warning">Pending</div>;
  } else if (val === 1) {
    return <div className="text-primary">Inprocess</div>;
  } else if (val === 2) {
    return <div className="text-success">Success</div>;
  } else if (val === 3) {
    return <div className="text-danger">Rejected</div>;
  }
}

export const shopStatusValueArray = [
  { "value": 0, "label": 'Pending' },
  { "value": 1, "label": 'InProcess' },
  { "value": 2, "label": 'Success' },
  { "value": 3, "label": 'Rejected' }
]

export const FundRequestValue = (val) => {
  // console.log("-------", val)
  if (val === 0) {
    return <div className="text-info">Pending</div>;
  } else if (val === 1) {
    return <div className="text-primary">Inprocess</div>;
  } else if (val === 2) {
    return <div className="text-danger">Rejected</div>;
  } else if (val === 3) {
    return <div className="text-success">Success</div>;
  } else if (val === 4) {
    return <div className="text-warning">Re-Check</div>;
  }
}

export const FundRequestValueCSV = (val) => {
  // console.log("-------", val)
  if (val === 0) {
    return "Pending";
  } else if (val === 1) {
    return "Inprocess";
  } else if (val === 2) {
    return "Rejected";
  } else if (val === 3) {
    return "Success";
  } else if (val === 4) {
    return "Pending";
  }
}

export const UitilityOfflineStatus = (val) => {
  // console.log("-------", val)
  // 0 - Pending / 1 - Submitted / 2 - Inprocess / 3 - success / 4 - failed
  if (val === 0) {
    return "Pending";
  } else if (val === 1) {
    return "Submitted";
  } else if (val === 2) {
    return "Inprocess";
  } else if (val === 3) {
    return "Success";
  } else if (val === 4) {
    return "Failed";
  }
}

export const uitilityOfflineStatusInArray = [
  // { "value" : "0", "label" : 'Pending'},
  { "value": "1", "label": 'Submitted' },
  { "value": "2", "label": 'Inprocess' },
  { "value": "3", "label": 'Success' },
  { "value": "4", "label": 'Failed' }
]

export const channelStatus = (val) => {
  if (parseInt(val) === 1 || parseInt(val) === 4) {
    return "NEFT";
  } else if (parseInt(val) === 2 || parseInt(val) === 5) {
    return "IMPS";
  }
}

export const dmtStatus = (val) => {
  // console.log("-------", val)
  if (val === 0) {
    return <div className="text-warning">Initiated</div>;
  } else if (val === 1) {
    return <div className="text-primary">Success</div>;
  } else if (val === 2) {
    return <div className="text-danger">Unknown Success</div>;
  } else if (val === 3) {
    return <div className="text-success">Completed</div>;
  } else if (val === 4) {
    return <div className="text-danger">Refunded</div>;
  }
}

export const dmtStatusExport = (val) => {
  // console.log("-------", val)
  if (val === 0) {
    return "Initiated";
  } else if (val === 1) {
    return "Success";
  } else if (val === 2) {
    return "Unknown Success";
  } else if (val === 3) {
    return "Completed";
  } else if (val === 4) {
    return "Refunded";
  }
}

export const dmtDetailStatus = (value) => {
  // console.log("-------", value)
  let val = parseInt(value);
  if (val === 0) {
    return <div className="text-success">Success</div>;
  } else if (val === 1) {
    return <div className="text-danger">Fail</div>;
  } else if (val === 2) {
    return <div className="text-warning">Bank Pending</div>;
  } else if (val === 3) {
    return <div className="text-warning">Refund Pending</div>;
  } else if (val === 4) {
    return <div className="text-warning">Refunded</div>;
  } else if (val === 5) {
    return <div className="text-primary">Hold</div>;
  } else if (val === 6) {
    return <div className="text-primary">Refund</div>;
  } else {
    return <div className="text-default">Initiated</div>;
  }
}

export const complaintStatus = (value) => {
  // console.log("-------", value)
  let val = parseInt(value);
  if (val === 0) {
    return <div className="text-warning">Pending</div>;
  } else if (val === 1) {
    return <div className="text-primary">Inprocess</div>;
  } else if (val === 2) {
    return <div className="text-danger">Rejected</div>;
  } else if (val === 3) {
    return <div className="text-success">Solved</div>;
  }
}

export const hideCharters = (originalString) => {
  if (originalString) {
    const length = originalString.length;
    const lastFourDigits = originalString.substring(length - 4);
    const maskedString = "X".repeat(length - 4) + lastFourDigits;
    return maskedString;
  }
  // let strl = string.length;
  // let re = /(.{1,5})$/;
  // return string.replace(re, function (matched) {
  //     return Array(matched.length+1).join("X");
  // });
}

export const hideFruntCharters = (originalString, len) => {
  if (originalString) {
    const length = originalString.length;
    const lastFourDigits = originalString.substring(len, length);
    const maskedString = "X".repeat(len) + lastFourDigits;
    return maskedString;
  }
}

export const hideFirstCharters = (originalString, len) => {
  if (originalString) {
    const length = originalString.length;
    const lastFourDigits = originalString.substring(0, len, length);
    const maskedString = lastFourDigits + "X".repeat(len);
    return maskedString;
  }
}

export const hideCCNumber = (originalString, len) => {
  if (originalString) {
    const first4 = originalString.slice(0, len);
    const last4 = originalString.slice(-len);
    // const lastFourDigits = originalString.substring(len, length);
    const maskedString = first4 + "-XXXX-XXXX-" + last4;
    return maskedString;
  }
}

export const INRFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    // style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
  }).format(value);

export const requestStatus = [
  { "label": "ALL", "value": "-1" },
  { "label": "Pending", "value": 0 },
  { "label": "Approved", "value": 1 },
  { "label": "Rejected", "value": 2 },
  { "label": "InProcess", "value": 3 },
]

export const setDefaultStatus = [
  { "value": 0, "label": 'Pending' },
  { "value": 1, "label": 'InProcess' },
  { "value": 2, "label": 'Rejected' },
  { "value": 3, "label": 'Success' }
]

export const getUtilityOfflineTypes = [
  { "value": 1, "label": 'Electricity' },
  { "value": 2, "label": 'Water' },
  { "value": 3, "label": 'Gas' },
  { "value": 4, "label": 'Insurance' }
]

export const PaymentDepositStatus = (val) => {
  // console.log("-------", val)
  if (val === 0) {
    return <div className="text-info">Pending</div>;
  } else if (val === 1) {
    return <div className="text-success">Approved</div>;
  } else if (val === 2) {
    return <div className="text-danger">Rejected</div>;
  } else if (val === 3) {
    return <div className="text-primary">Inprocess</div>;
  }
}
export const removeHtmlTags = (htmlString) => {
  // console.log("htmlString-------", htmlString.props.children)
  return htmlString.props.children;
  // const regex = /(<([^>]+)>)/gi;
  // return htmlString.replace(regex, ''); // Replace HTML tags with an empty string
}

export const ekoPartnerEkycStatus = (val) => {
  // "0 = Pending | 1 = Inprocess | 2 = Bio Metrice Pending | 3 = Bank Pending | 4 = Success | 5 = Failed | 6 = Rejected"
  if (val === 0) {
    return <div className="text-info">Pending</div>
  } else if (val === 1) {
    return <div className="text-primary">Inprocess</div>
  } else if (val === 2) {
    return <div className="text-primary">Bio Metrice Pending</div>
  } else if (val === 3) {
    return <div className="text-warning">Bank Pending</div>
  } else if (val === 4) {
    return <div className="text-success">Success</div>
  } else if (val === 5) {
    return <div className="text-danger">Failed</div>
  } else if (val === 6) {
    return <div className="text-danger">Rejected</div>
  } else {
    return <div className="text-warning">Pending</div>
  }
}

export const pancardValidation = (text) => {
  let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  if (regex.test(text)) {
    return true;
  }
  return false;
}

export const dmtDetailStatusInArray = [
  { "value": "0", "label": 'Success' },
  { "value": "1", "label": 'Fail' },
  { "value": "2", "label": 'Bank Pending' },
  { "value": "3", "label": 'Refund Pending' },
  { "value": "4", "label": 'Refunded' },
  { "value": "5", "label": 'Hold' },
  { "value": "6", "label": 'Refund' },
]

export const dmtStatusInArray = [
  { "value": "0", "label": 'Initiated' },
  { "value": "1", "label": 'Success' },
  { "value": "2", "label": 'Unknown' },
  { "value": "3", "label": 'Completed' },
  { "value": "4", "label": 'Refunded' }
]

export const aepsLoginInArray = [
  { "value": "", "label": 'All' },
  { "value": "AEPS_FINGPAY", "label": 'Fing Pay' },
  { "value": "AEPS_INSTPAY", "label": 'Instant Pay' }
]

export const aepsServiceInArray = [
  { "value": "Cash Withdrawal", "label": 'Cash Withdrawal' },
  { "value": "Mini Statement", "label": 'Mini Statement' },
  { "value": "Balance Enquiry", "label": 'Balance Enquiry' }
]

export const payoutStatusInArray = [
  { "value": "All", "label": 'ALL' },
  { "value": "-1", "label": 'Initiated' },
  { "value": "0", "label": 'Success' },
  { "value": "1", "label": 'Fail' },
  { "value": "2", "label": 'Response Awaited' },
  { "value": "3", "label": 'Refund Pending' },
  { "value": "4", "label": 'Refunded' },
  { "value": "5", "label": 'Hold' },
  { "value": "6", "label": 'Refund' },
]

export const dmtModeInArray = [
  { "value": "1", "label": 'NEFT' },
  { "value": "2", "label": 'IMPS' }
]

export const payoutModeInArray = [
  { "value": "4", "label": 'NEFT' },
  { "value": "5", "label": 'IMPS' }
]

export const ekoOnboardingStatus = (val) => {
  // console.log("-------", val)
  // "0 = Pending | 1 = Inprocess | 2 = Bio Metrice Pending | 3 = Bank Pending | 4 = Success | 5 = Failed | 6 = Rejected"
  if (val === 0) {
    return <div className="text-info">Pending</div>;
  } else if (val === 1) {
    return <div className="text-primary">Inprocess</div>;
  } else if (val === 2) {
    return <div className="text-warning">Bio Metrice Pending</div>;
  } else if (val === 3) {
    return <div className="text-warning">Bank Pending</div>;
  } else if (val === 4) {
    return <div className="text-success">Success</div>;
  } else if (val === 5) {
    return <div className="text-danger">Failed</div>;
  } else if (val === 6) {
    return <div className="text-danger">Rejected</div>;
  }
}

export const instantTopupStatus = (val) => {
  // console.log("-------", val)
  // "0 = Pending / 1 = success / 2 = failed / 3 = refund / 4 = Dispute"
  if (val === 0) {
    return <div className="text-info">Pending</div>;
  } else if (val === 1) {
    return <div className="text-success">Success</div>;
  } else if (val === 2) {
    return <div className="text-danger">Failed</div>;
  } else if (val === 3) {
    return <div className="text-warning">Refund</div>;
  } else if (val === 4) {
    return <div className="text-primary">Dispute</div>;
  }
}

export const instantTopupClaimStatus = (val) => {
  // console.log("-------", val)
  // 0 = Pending / 1 = inprocess / 2 = rejected / 3 = refund / 4 = completed
  if (val === 0) {
    return <div className="text-info">Pending</div>;
  } else if (val === 1) {
    return <div className="text-primary">Inprocess</div>;
  } else if (val === 2) {
    return <div className="text-danger">Rejected</div>;
  } else if (val === 3) {
    return <div className="text-warning">Refund</div>;
  } else if (val === 4) {
    return <div className="text-success">Completed</div>;
  }
}

export const qrCodeMiniStatusArray = [
  { "value": "0", "label": 'Pending' },
  { "value": "1", "label": 'Inprocess' },
  { "value": "2", "label": 'Success' },
  { "value": "3", "label": 'Rejected' },
  { "value": "4", "label": 'ReCheck' },
  { "value": "5", "label": 'Failed' }
]

export const qrCodeMiniStatus = (value) => {
  // console.log("-------", val)
  // "0 = Pending / 1 = InProcess / 2 = Success / 3 = Rejected / 4 = ReCheck"
  let val = parseInt(value);
  if (val === 0) {
    return "Pending";
  } else if (val === 1) {
    return "Inprocess";
  } else if (val === 2) {
    return "Success";
  } else if (val === 3) {
    return "Rejected";
  } else if (val === 4) {
    return "ReCheck";
  } else if (val === 5) {
    return "Failed";
  }
}

export const matmModeStatus = (val) => {
  if (val === "BAL") {
    return "Balance Inquiry";
  } else if (val === "MATMCW") {
    return "Cash Withdrawal";
  }
}

export const matmModeStatusDrop = [
  { "value": "", "label": '-- Select --' },
  { "value": "BAL", "label": 'Balance Inquiry' },
  { "value": "MATMCW", "label": 'Cash Withdrawal' },
]

export const matmTranStatusDrop = [
  { "value": "", "label": '-- Select --' },
  { "value": "S", "label": 'Success' },
  { "value": "F", "label": 'Failed' },
  { "value": "P", "label": 'Pending' },
]

export const matmTransStatus = (val) => {
  if (val === "S") {
    return "Success";
  } else if (val === "F") {
    return "Failed";
  } else {
    return "Pending";
  }
}

export const matmOrderStatus = (val) => {
  if (val === "1") {
    return "Approved";
  } else if (val === "2") {
    return "Rejected";
  } else {
    return "Pending";
  }
}
import React, { useEffect, useState, useRef, useCallback } from 'react';
// import CsvDownloader from 'react-csv-downloader';
import { Modal, Switch } from 'antd';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { ADMIN_GET_ALL_APPROVED_USERS, ADMIN_ACCEPT_PROFILE } from '../../../Utils/appConstants';
import { getRequest, postRequest } from '../../../Utils/AppApi';
import { kycStatusValue } from '../../../Utils/Common';
import { ToastContainer, toast } from 'react-toastify';
import ImageViewer from 'react-simple-image-viewer';
import { getUTCToLocalDateFormat } from '../../../Utils/function';
// import moment from 'moment';
// import Moment from 'react-moment';

function App(props) {
    // console.log("props -------",props.search)
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const getMode = props.mode ? props.mode : "";
    const startDate = props.startDate ? props.startDate : "";
    const endDate = props.endDate ? props.endDate : "";
    const search = props.search ? props.search : "";

    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isModalUserDetail, setIsModalUserDetail] = useState(false);
    const [isModalUserApprovedReject, setIsModalUserApprovedReject] = useState(false);
    const [isSelectedUser, setIsSelectedUser] = useState([]);

    const [isSelectedUserAadharDetail, setIsSelectedUserAadharDetail] = useState({});
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const [isReason, setIsReason] = useState("");
    const [isReasonVisble, setIsReasonVisble] = useState(false);

    const [isSuspendUser, setIsSuspendUser] = useState(false);
    const [isCreditUser, setIsCreditUser] = useState(false);
    const [isDebitUser, setIsDebitUser] = useState(false);

    const columns = [{
        name: 'User Id',
        selector: row => row.user.username,
    }, {
        name: 'Pan No.',
        selector: row => row.pancardNumber,
    }, {
        name: 'Name',
        selector: row => row.user.name,
    }, {
        name: 'Mobile',
        selector: row => row.mobile,
    }, {
        name: 'User Type',
        selector: row => row.role.name,
    },{
        name: 'Parent ID',
        selector: row => <>{row.userParentDetail && row.userParentDetail.username}</>,
    }, {
        name: 'KYC Status',
        cell: row => <>{kycStatusValue(row.user.KYCStatus)}</>,
    }, {
        name: 'Active',
        cell: row => <>{(row.user.active) ? <div className="text-success">Active</div> : <div className="text-danger">Deactive</div>}</>,
    }, {
        name: 'Date',
        cell: row => getUTCToLocalDateFormat(row.createdAt),
    }, {
        name: 'Action',
        cell: row => <><div className='icon-border border border-primary text-primary pointer' onClick={(event) => handleUserDetail(event, row)}><i className="fa fa-eye " aria-hidden="true"></i></div><div className='icon-border border border-danger text-danger pointer' onClick={(event) => handleUserModel(event, row)} ><i className="fa fa-user-lock" aria-hidden="true"></i></div></>,
    }]

    const handleUserModel = (event, data) => {
        setIsModalUserApprovedReject(true)
        setIsSelectedUser(data)
        setIsSuspendUser(data.user.status)
        setIsCreditUser(data.user.creditStatus)
        setIsDebitUser(data.user.debitStatus)
        setIsReason(data.rejectReason)
        console.log("data ---------------", data)
    }

    const handleUserDetail = (event, data) => {
        setIsModalUserDetail(true)
        setIsSelectedUser(data)

        let user = JSON.parse(JSON.stringify(data.aadharNumberVerifiedDetail));
        setIsSelectedUserAadharDetail(user)
        // console.log("data ---------------", data)
    }

    const handleReject = (data) => {
        const payload = {
            userId: data.userId,
            status: false,
            reason: isReason
        }
        console.log("isReason --------------", isReason)
        if(!isReason) {
            setIsReasonVisble(true)
            setIsReason(data.rejectReason)
        } else {
            handleSubmit(payload);
        }
    }

    const handleApproved = (data) => {
        const payload = {
            userId: data.userId,
            status: true
        }

        handleSubmit(payload);
    }

    const handleSubmit = (data) => {
        // const queryString = objectToQueryString(payload);
        postRequest(`${ADMIN_ACCEPT_PROFILE}`, data, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                setIsReason("")
                setIsReasonVisble(false)
                setIsModalUserDetail(false)
            } else {
                toast.error(response.message);
                if(!data.status) {
                    setIsReasonVisble(true)
                }
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
        // console.log("accept ----",value)
    }
    const handleHideModal = () => {
        setIsModalUserDetail(false);
        setIsModalUserApprovedReject(false)
        setIsReason("")
        setIsReasonVisble(false)
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        params.roleId = getMode;

        if (search) {
            params.search = search ? search : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${ADMIN_GET_ALL_APPROVED_USERS}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        if (search) {
            params.search = search ? search : "";
        }
        const queryString = objectToQueryString(params);
        getRequest(`${ADMIN_GET_ALL_APPROVED_USERS}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({ "Provider": e.provider, "Customer Id": e.customerId, "States": e.states, "Amount": e.amount, "Status": e.status, "Date": e.createdAt });
                });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    useEffect(() => {
        getDataReports();
    }, [page, props.handleSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     getCSVDataList();
    //     setIsDownloadCsv(isDownloadCsv ? true : false);
    // }, [isDownloadCsv]) // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeSuspendUser = (checked) => {
        setIsSuspendUser(checked)
        // console.log(`switch to ${checked}`);
    };

    const onChangeCreditStatus = (checked) => {
        setIsCreditUser(checked)
        // console.log(`switch to ${checked}`);
    };

    const onChangeDebitStatus = (checked) => {
        setIsDebitUser(checked)
        // console.log(`switch to ${checked}`);
    };

    const openImageViewer = useCallback((image) => {
        setViewImage(image)
        setIsModalUserDetail(false);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setViewImage("");
        setIsModalUserDetail(true);
        setIsViewerOpen(false);
    };

    return (
        <div>
            <div className="container-fluid pt-2 pb-5">
                <div className="col-md-12">
                    <div className='float-sm-right d-flex mb-1'>
                        <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                        <CSVLink
                            data={getCsvData}
                            filename='export_file.csv'
                            className='hidden'
                            ref={csvLink}
                            target='_blank'
                        />
                        <button type="button" className="btn btn-danger">
                            <i className="fa fa-file-pdf"></i> Export to PDF
                        </button>
                    </div>
                    <div className="box-body">
                        <DataTable
                            // title={getMode}
                            columns={columns}
                            data={getData.docs}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={getTotalRecord}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
            {isViewerOpen && (
                <ImageViewer
                    src={[viewImage]}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
            {isModalUserDetail && <Modal title={isSelectedUser.user.name} width={500} open={isModalUserDetail} onCancel={() => handleReject(isSelectedUser)} okText={"Approve"}  onOk={() => handleApproved(isSelectedUser)} cancelText={"Reject"} footer={false}>
                {isSelectedUser ?
                    <ul>
                        <li><span>Name</span> : {isSelectedUser.user.name}</li>
                        <li><span>Role Name</span> : {isSelectedUser.role.name}</li>
                        <li><span>Father Name</span> : {isSelectedUser.fatherName}</li>
                        <li><span>Mother Name</span> : {isSelectedUser.motherName}</li>
                        <li><span>Mobile Number</span> : {isSelectedUser.mobile}</li>
                        <li><span>Email</span> : {isSelectedUser.email}</li>
                        <li><span>Email Verify</span> : {isSelectedUser.emailVerify}</li>
                        <li><span>Phone Number</span> : {isSelectedUser.phone}</li>
                        <li><span>Pincode</span> : {isSelectedUser.pincode}</li>
                        <li><span>City Name</span> : {isSelectedUser.city}</li>
                        <li><span>State Name</span> : {isSelectedUser.state && isSelectedUser.state.name}</li>
                        <li><span>Outlet Name</span> : {isSelectedUser.outletName}</li>
                        <li><span>Account Number</span> : {isSelectedUser.accountNumber}</li>
                        <li><span>IFSC Code</span> : {isSelectedUser.ifscCode}</li>
                        <li><span>Bank Name</span> : {isSelectedUser.bankName}</li>
                        <li><span>Account Holder Name</span> : {isSelectedUser.accountHolderName}</li>
                        <li><span>Bank Proof</span> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectedUser.bankProof)}><u>Show Image</u></span></div></li>
                        <li><span>Pancard Number</span> : {isSelectedUser.pancardNumber}</li>
                        <li><span>Pancard Number Verify</span> : {isSelectedUser.pancardNumberVerify ? "Yes" : "No"}</li>
                        {/* <li><span>Pancard Number Verified Detail</span> : {isSelectedUser.pancardNumberVerifiedDetail}</li> */}
                        <li><span>Pan Card Photo</span> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectedUser.panCardPhoto)}><u>Show Image</u></span></div></li>
                        <li><span>Aadhar Number</span> : {isSelectedUser.aadharNumber}</li>
                        <li><span>Aadhar Number Verify</span> : {isSelectedUser.aadharNumberVerify ? "Yes" : "No"}</li>
                        <li><span>Aadhar Name</span> : {isSelectedUserAadharDetail && isSelectedUserAadharDetail.name }</li>
                        <li><span>Address</span> : {isSelectedUserAadharDetail && isSelectedUserAadharDetail.address }</li>
                        <li><span>Date Of Birth</span> : {isSelectedUserAadharDetail && isSelectedUserAadharDetail.dob }</li>
                        <li><span>Aadhar Card Photo</span> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectedUser.aadharPhoto)}><u>Show Image</u></span></div></li>
                        {/* <li><span>Aadhar Number Verified Detail</span> : {isSelectedUser.aadharNumberVerifiedDetail}</li> */}
                        <li><span>GST In Number</span> : {isSelectedUser.gstInNumber}</li>
                        <li><span>GST In Number Verify</span> : {isSelectedUser.gstInNumberVerify ? "Yes" : "No"}</li>
                        {/* <li><span>GST In Number Verified Detail</span> : {isSelectedUser.gstInVerifiedDetail}</li> */}
                        <li><span>GST In Photo</span> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectedUser.gstInPhoto)}><u>Show Image</u></span></div></li>
                        <li><span>Selfie Photo</span> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectedUser.selfiePhoto)}><u>Show Image</u></span></div></li>
                        <li><span>Communication Address</span> : {isSelectedUser.communicationAddress}</li>
                        <li><span>Registered Address</span> : {isSelectedUser.registeredAddress}</li>
                        <li><span>Business Address</span> : {isSelectedUser.businessAddress}</li>
                    </ul>
                    : ""}
            </Modal>}

            {isModalUserApprovedReject && <Modal title={"User Status"} width={600} open={isModalUserApprovedReject} onOk={() => (isSelectedUser)} onCancel={handleHideModal} okText={"Send"} cancelText={"Cancel"}>
                <div className='row'>
                    <div className='col-md-12 d-flex'>
                        <div className="form-group col-md-4">
                            <label>Suspend User: </label> <Switch size="small" defaultChecked={isSuspendUser} onChange={onChangeSuspendUser} />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Credit Status: </label> <Switch size="small" defaultChecked={isCreditUser} onChange={onChangeCreditStatus} />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Debit Status: </label> <Switch size="small" defaultChecked={isDebitUser} onChange={onChangeDebitStatus}/>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        <label>Reason: </label> 
                        <textarea className="form-group form-control" cols={"4"} rows={"5"} >{isReason}</textarea>
                    </div>
                </div>
            </Modal>}

            {isReasonVisble && <Modal title={"User reject reason"} width={600} open={isReasonVisble} onOk={() => handleReject(isSelectedUser)} onCancel={handleHideModal} okText={"Send"} cancelText={"Cancel"}>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        <label>Reason: </label> 
                        <textarea className="form-group form-control" onChange={(event) => setIsReason(event.target.value)} cols={"4"} rows={"5"} >{isReason ? isReason : isSelectedUser.rejectReason}</textarea>
                    </div>
                </div>
            </Modal>}
        </div>
    )
}

export default App
import React, { useEffect, useState, useRef } from 'react';
// import { APP_KEY, API_URL } from './../config';
import { getUser, isNumber, FTRNumberMake, getParentPageTitleLable } from './../../Utils/Common';

import { SAVE_MOVE_TO_DISTRIBUTOR, GET_PARENT_PROFILE } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
import PropagateLoader from "react-spinners/PropagateLoader";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import AfterSave from './../../container/afterpayment';
import moment from 'moment';
const user = getUser();
function MoveToDistributor() {
    // console.log("user------", user.data.capitalAmount)
    const history = useHistory(); 
    const eligibleLimit = user.data.capitalAmount ? user.data.capitalAmount : 0;
    const [isLabel, setLabel] = useState(null);
    const [getDataField, setDataField] = useState([]); // eslint-disable-line react-hooks/exhaustive-deps
    const [getDataError, setDataError] = useState({}); // eslint-disable-line react-hooks/exhaustive-deps
    const [distributorId, setDistributorId] = useState("");
    const [distributorName, setDistributorName] = useState("");
    const [distributorMobile, setDistributorMobile] = useState("");
    const [myWalletBalance, setWalletBalance] = useState(0);
    const [InEligibleLimit, setEligibleLimit] = useState(0);
    const [currentOutStanding, setCurrentOutStanding] = useState(0);
    const [isSubmit, setSubmit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    
    const amountInput = useRef(null);

    const clearFormField = () => {
        amountInput.current.value = "";
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        let array = ["amount"];

        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }

            if(getDataField.amount < 10000){
                formValid = false;
                toast.error("Minimum amount per transaction is Rs.10000");
            }
        });

        setDataError(formObject);
        return formValid;
    }

    const handleSubmit = () => {
        if (FormValidate()) {
            setLoading(true)
            const payload = {
                FTRReferenceNumber: getDataField.FTRReferenceNumber,
                outstandingBalance: currentOutStanding,
                amount: getDataField.amount,
                dateTime: moment(new Date()).format("YYYY-MM-DD HH:mm"), //.toLocaleString(),
                devicetype: "W"
                // remark: getDataField.remark,
            }

            postRequest(SAVE_MOVE_TO_DISTRIBUTOR, payload, history).then((response) => {
                if (response.success === true) {
                    setDataField([]);
                    setDataError({});
                    clearFormField();
                    setSubmit(true)
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getParentProfile = () => {
        getRequest(`${GET_PARENT_PROFILE}`, history).then((response) => {
            if (response.success === true) {
                setDistributorMobile(response.data.parentdetail.mobile);
                setDistributorId(response.data.userdetail.username);
                setDistributorName(response.data.userdetail.name);
                setWalletBalance(response.data.walletdetail.balance.toFixed(2));
                setEligibleLimit((response.data.walletdetail.balance - eligibleLimit).toFixed(2));
                setCurrentOutStanding(response.data.outstanding.closingBalance);
            } else {
                setDistributorMobile("");
                setDistributorId("");
                setDistributorName("");
                setWalletBalance(0);
                setEligibleLimit(0);
                setCurrentOutStanding(0);
            }
        }).catch(function (error) {
            console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLabel(getParentPageTitleLable());
        getParentProfile();
        setDataField({ ...getDataField, FTRReferenceNumber: FTRNumberMake(6) });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            {isSubmit ? <AfterSave status={1} message={"Money send successful"}/> :
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Move To <span className='text-red'>{isLabel}</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card rounded-0 card-body'>
                                    <div className='row'>
                                        <div className='col-sm-6 col-md-4 col-lg-4'>
                                            <div className="form-group">
                                                <label>FTR Ref. No</label>
                                                <input type="text" className={`form-control normal-light pr-110`} name="FTRReferenceNumber" value={getDataField && getDataField.FTRReferenceNumber} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-4 col-lg-4'>
                                            <div className="form-group">
                                                <label>{isLabel} ID</label>
                                                <input type="text" className={`form-control normal-light pr-110`} name="distributorId" value={distributorId} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-4 col-lg-4'>
                                            <div className="form-group">
                                                <label>{isLabel} Name</label>
                                                <input type="text" className={`form-control normal-light pr-110`} name="distributorName" value={distributorName} readOnly={true} />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-4 col-lg-4'>
                                            <div className="form-group">
                                                <label>Contact Number</label>
                                                <input type="text" className={`form-control normal-light pr-110`} name="contactNumber" value={distributorMobile} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-4 col-lg-4'>
                                            <div className="form-group">
                                                <label>Current Outstanding</label>
                                                <input type="text" className={`form-control normal-light pr-110`} name="currentOutstanding" value={currentOutStanding} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-4 col-lg-4'>
                                            <div className="form-group">
                                                <label>Available Limit</label>
                                                <input type="text" className={`form-control normal-light pr-110`} name="availableLimit" value={myWalletBalance} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-4 col-lg-4'>
                                            <div className="form-group">
                                                <label>Eligible Limit</label>
                                                <input type="text" className={`form-control normal-light pr-110`} name="eligibleLimit" value={InEligibleLimit} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-4 col-lg-4'>
                                            <div className="form-group">
                                                <label>Amount</label>
                                                <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.amount ? "is-invalid" : ""}`} name="amount" ref={amountInput} maxLength={10} onChange={(e) => onChangeValue(e)} placeholder="Amount" onKeyDown={isNumber} value={getDataField && getDataField.amount} />
                                                {getDataError && getDataError.amount && <span className="error text-danger">The field is required</span>}
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-4 col-lg-4'>
                                            <label></label>
                                            <div className='col-md-12 d-flex'>
                                                <div className="form-group  pull-right text-lg-right mr-4">
                                                    <button type="submit" className="btn btn-default rounded-pill pl-4 pr-4" onClick={() => clearFormField()} >Cancel</button>
                                                </div>
                                                <div className="form-group text-lg-right">
                                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Process</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3><u>Terms & Conditions</u></h3>
                                    <div className="row ml-2 mt-2">
                                        <div className="m-0">
                                            <p className='m-0'>* Transfer timings are between 09:00 AM to 05:00 PM daily.</p>
                                            <p className='m-0'>* Maximum 5 transaction allowed per day.</p>
                                            <p className='m-0'>* Minimum amount per transaction is Rs.10000</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
            </div>
            }
        </div>
    )
}

export default MoveToDistributor
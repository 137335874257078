import { useState, useEffect } from 'react';
// import axios from 'axios';
import { POST_DMT_PAY_SPRINT_CUSTOMER_INFO, POST_DMT_PAY_SPRINT_OTP_VERIFICATION } from './../../Utils/appConstants';
import { isNumber } from '../../Utils/Common';
import { useHistory } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
// import { ToastContainer } from 'react-toastify';
import AddCustomer from './addCustomer';
import ViewCustomer from './viewCustomer';
// import RefundHistory from './refund';
import { postRequest } from '../../Utils/AppApi';
import { toast } from 'react-toastify';
import { Modal } from 'antd';

const MyComponent = (props) => {
    const history = useHistory();
    // const tabPosition = 'left';
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [timer, setTimer] = useState(null);
    const [isCustomerInfo, setCustomerInfo] = useState(null);
    const [isCustomerNotExist, setCustomerNotExist] = useState(false);
    // const [searchButton, setSearchButton] = useState(true);
    // const [subTabs, setSubTabs] = useState('1');
    const [isOtpModal, setOtpModal] = useState(false);
    const [otpCode, setOtpCode] = useState(null)
    const [otpReference, setOtpReference] = useState(null);

    useEffect(() => {
        if(props.mobile) {
            setSearchText(props.mobile)
        }
    }, [props.mobile]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (searchText) {
            if (timer) {
                clearTimeout(timer)
                setTimer(null)
            }
            setTimer(
                setTimeout(() => {
                    if (searchText.length === 10) {
                        // setSearchButton(false)
                        getCustomerInfo()
                    } else {
                        setCustomerNotExist(false);
                        // toast.error("Please enter a valid Mobile Number.")
                    }
                }, 1000)
            )
        }
    }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

    // const handleSearch = (e) => {
    //     // console.log("searchText----",e.target.value)
    //     if (e.target.value.length === 10) {
    //         setSearchButton(false)
    //     } else {
    //         setCustomerInfo(null)
    //         setSearchButton(true)
    //     }
    //     setSearchText(e.target.value);
    // }

    // const findCustomerInfo = (mobile) => {
    //     setSearchText(null)
    //     setSearchText(mobile)
    // }
    // const getBankListData = async () => {
    // await getRequest(GET_AEPS_BANK_LIST, history).then((response) => {
    //     if (response.success === true) {
    //         setBankList(response.data.map(item => {
    //             return { "value": item.iin, "label": item.name };
    //         }));
    //     } else {
    //         toast.error(response.message);
    //     }
    // }).catch(function (error) {
    //     console.log(error)
    //     // setLoading(false);
    // })
    // }

    const getCustomerInfo = async () => {
        setLoading(true)
        if (!searchText) {
            toast.error("Please enter a Customer Number");
            setLoading(false)
            return false;
        } else if (searchText.length !== 10) {
            toast.error("Please enter a valid Mobile Number");
            setLoading(false)
            return false;
        }
        let payload = { "customerMobile": searchText }
        await postRequest(POST_DMT_PAY_SPRINT_CUSTOMER_INFO, payload, history).then((response) => {
            if (response.success === true) {
                if (response.isotp === true) {
                    setOtpModal(true);
                    setOtpReference(response.otpReference);
                } else {
                    setCustomerInfo(response.data);
                    setCustomerNotExist(false)
                }
                // console.log("----------------", response.data);
            } else {
                setCustomerNotExist(true);
                // console.log("----------------", response.success);
            }
            setLoading(false)
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    }

    const handleCustomerVerify = () => {
        console.log("verify ========== ")
        if (otpCode) {
            setLoading(true)
            console.log("otpCode ===================== ", otpCode)
            const payload = {
                otpReference: otpReference,
                otp: otpCode,
                customerMobile: searchText,
            };

            postRequest(`${POST_DMT_PAY_SPRINT_OTP_VERIFICATION}`, payload, history).then((response) => {
                if (response.success === true) {
                    setOtpModal(false);
                    getCustomerInfo();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        } else {
            toast.error("OTP code is required");
        }
    }

    const onChangeOtpValue = (value) => {
        setOtpCode(value);
    }

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }
    return (
        <div>
            <div className="">
                <div className="">
                    {/* <div className='row mb-3'>
                        <div className="input-group">
                            <div className="col-md-2"></div>
                            <div className="col-md-4">
                                <input className="form-control py-2 border" type="search" defaultValue={searchText} value={searchText} onKeyDown={isNumber} placeholder='Search ...' maxLength={10} onChange={(e) => handleSearch(e)} />
                            </div>
                            <button onClick={() => getCustomerInfo()} className="btn btn-primary ml-3 border-left-0 border" disabled={searchButton} type="button">
                                <i className="fa fa-search" /> Search
                            </button>
                        </div>
                    </div> */}
                    {(isCustomerNotExist) && <AddCustomer mobile={searchText} findCustomerInfo={getCustomerInfo} />}
                    {(isCustomerInfo && !isCustomerNotExist) && <ViewCustomer isCustomerInfo={isCustomerInfo} />}
                </div>
                {isOtpModal && <Modal title={"Customer Verification OTP"} width={600} open={isOtpModal} onOk={() => handleCustomerVerify()} onCancel={() => setOtpModal(false)} okText={"Submit"} >
                    <div className="form-group">
                        <label>OTP Code</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="otpCode" placeholder="OTP Code" onChange={(e) => onChangeOtpValue(e.target.value)} autoComplete="off" value={otpCode} maxLength={6} onKeyDown={isNumber} />
                            </div>
                        </div>
                    </div>
                </Modal>}
            </div>
        </div>
    )
}

export default MyComponent;
import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { USER_ROLE, GET_ADMIN_COMMISSION_TRANSACTION_REPORT } from './../../../Utils/appConstants';
import { getRequest } from './../../../Utils/AppApi';
import { INRFormat } from './../../../Utils/Common';
import { useHistory } from "react-router-dom";

import { DatePicker, Tooltip, Select } from 'antd';
import { getUTCToLocalDateTimeFormat, toDayDate, queryDate, getExportFormat } from './../../../Utils/function';
import { toast } from 'react-toastify';

function CommissionReport() {

    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const modeSelected = useRef(null);

    const dateFormat = 'DD-MM-YYYY';

    const [getReportMode, setReportMode] = useState([]);
    const [isLoading, setLoading] = useState(false);
    // const [searchUserId, setSearchUserId] = useState(null);
    const [searchServiceName, setSearchServiceName] = useState(null);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [getRole, setRole] = useState(null);

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.transactionId && row.transactionId}>{row.transactionId && row.transactionId}</Tooltip>,
        width: "150px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Ref ID / Name',
        selector: row => row.users && row.users.username + " / " + row.users.name,
        width: "250px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Role Name',
        selector: row => row.roles && row.roles.name,
        width: "250px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Pancard',
        selector: row => row.users && row.users.pancard,
        width: "150px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Service Name',
        selector: row => <Tooltip placement="bottom" title={row.services && row.services.name}>{row.services && row.services.name}</Tooltip>,
        width: "200px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Tran Amt',
        selector: row => row.amount ? (row.commissionAmount < 0) ? "-"+INRFormat(row.amount) : INRFormat(row.amount) : "-",
        width: "150px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Ser. Charge',
        selector: row => row.serviceCharge ? (row.commissionAmount < 0) ? "-"+INRFormat(row.serviceCharge) : INRFormat(row.serviceCharge)  : "",
    }, {
        name: 'Commission/Discount',
        selector: row => row.commissionAmount ? INRFormat(row.commissionAmount) : "-",
    }, {
        name: 'TDS',
        selector: row => row.tdsAmount ? INRFormat(row.tdsAmount) : "-",
    }, {
        name: 'TDS Applicable',
        selector: row => (row.tdsAmount > 0) ? "YES" : "NO",
    // }, {
    //     name: 'Closing Balance',
    //     selector: row => INRFormat(row.closingBalance),
    //     width: "150px",
    //     headerStyle: (selector, id) => {
    //         return { textAlign: "center" };   // removed partial line here
    //     },
    // }, {
        // name: 'Description',
        // selector: row => row.description ? <Tooltip placement="bottom" title={row.description && row.description}>{row.description && row.description}</Tooltip> : "-",
        // width: "250px",
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        setLoading(true)
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.role = getRole ? getRole : "";
        params.searchServiceName = searchServiceName ? searchServiceName : "";

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ADMIN_COMMISSION_TRANSACTION_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
            setLoading(false);
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.role = getRole ? getRole : "";
        params.searchServiceName = searchServiceName ? searchServiceName : "";

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ADMIN_COMMISSION_TRANSACTION_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Created Date": getExportFormat(e.createdAt),
                        "Updated Date": getExportFormat(e.updatedAt),
                        "TXT Id": e.transactionId,
                        "Ref ID": e.users.username,
                        "Role Name": e.roles && e.roles.name,
                        "Pancard": e.users.pancard,
                        "Name": e.users.name,
                        "Transaction Amt": e.amount ? (e.commissionAmount < 0) ? "-"+INRFormat(e.amount) : INRFormat(e.amount) : "-",
                        "Service Name": e.services && e.services.name,
                        "Service Charge": e.serviceCharge ? (e.commissionAmount < 0) ? "-"+INRFormat(e.serviceCharge) : INRFormat(e.serviceCharge) : "",
                        "Commission/Discount": e.commissionAmount,
                        "TDS": e.tdsAmount,
                        "TDS Applicable": e.tdsAmount ? "YES" : "NO",
                    });
                });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };
    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate("");
        setEndDate("");
        // setSearchUserId('');
        setSearchServiceName('');
    }

    const onChangeType = (value) => {
        setRole(value);
    }

    const getRoles = () => {
        getRequest(USER_ROLE+'/2', history).then( async (response) => {
            if (response.success === true) {
                let values = await response.data.filter(((items) => items.code !== "V")).map((item) => {
                    return { "value" : item._id, "label" : item.name};
                })
                setReportMode(values);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    useEffect(() => {
        getRoles();
        if(startDate || page>0) {
            getDataReports();
        }
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Commission / Discount <span className='text-red'> Report</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className='card col-md-12'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className='col-md-10 date-search mb-0'>
                                        <div className='row'>
                                            <div className="form-group ml-5">
                                                <label className='date-search-label mb-0 text-blue'>From</label>
                                                <DatePicker value={startDate} className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
                                            </div>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'>To</label>
                                                <DatePicker value={endDate} className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
                                            </div>
                                            <div className="input-group col-sm-2 ">
                                                <label className="form-label">User Type:</label>
                                                <Select options={getReportMode} className={`normal-light w-100`} classNamePrefix="select" placeholder="Select a Type" ref={modeSelected} name='mode' onChange={(e) => onChangeType(e)} value={getRole ? getRole : []} />
                                            </div>
                                            <div className='col-md-3 col-lg-3'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'></label>
                                                    <input type="text" className="form-control form-control-border" placeholder="Service Name" onChange={(e) => setSearchServiceName(e.target.value)} value={searchServiceName} />
                                                </div>
                                            </div>
                                            <div className='col-md-1 col-lg-1'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="submit" className="btn btn-primary rounded-pill px-3" onClick={() => handleSearch()}>Search</button>
                                            </div>
                                            <div className='col-md-1 col-lg-1'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="button" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className='float-sm-right'>
                                            <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                            <CSVLink
                                                data={getCsvData}
                                                filename={queryDate(startDate)+'-commission-report'}
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                            {/* <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf"></i> Export to PDF</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid pt-2 pb-5">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        progressPending={isLoading}
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommissionReport;
import moment from 'moment';
import xmlJs from 'xml-js';
import generateUniqueId from 'generate-unique-id';

export const getPaymentMode = () => {
    return ["NEFT", "IMPS"]
}

export const getBankMode = () => {
    return ["SBI Bank", "HDFC Bank"]
}

export const getIntercomTime = () => {
    return moment.utc(new Date()).local().format('DD-MM-YYYY hh:mm A')
}

export const saveDateInDatabase = (date) => {
    return moment(date, "DD-MM-YYYY").format('YYYY-MM-DD')
}

export const queryDate = (date) => {
    return moment(date).format("DD-MM-YYYY");
}

export const toDayDate = () => {
    return moment();
}

export const dateofBirth = (date) => {
    return moment(date, "YYYY-MM-DD").format('DD-MM-YYYY')
}

export const getUTCToLocalTime = (utcTimeInMilliseconds) => {
    return moment.utc(utcTimeInMilliseconds).local().format('D MMM, YYYY hh:mm A')
}

export const getUTCToLocalTimeFormat = (utcTimeInMilliseconds) => {
    return moment.utc(utcTimeInMilliseconds).local().format('hh:mm A')
}
// this is a UTC to India timezone 
export const getUTCToLocalDateFormat = (utcTimeInMilliseconds) => {
    return moment.utc(utcTimeInMilliseconds).local().format('D MMM YYYY')
}
// this is a UTC to India timezone 
export const getUTCToLocalDateTimeFormat = (utcTimeInMilliseconds) => {
    return moment.utc(utcTimeInMilliseconds).local().format('D MMM YYYY, hh:mm:ss A')
}

export const getExportFormat = (utcTimeInMilliseconds) => {
    return moment.utc(utcTimeInMilliseconds).local().format('DD-MM-YYYY HH:mm:ss')
}

export const getUTCToLocalTimeForCalender = (utcTimeInMilliseconds) => {
    return moment.utc(utcTimeInMilliseconds).local().format('yyyyMMDD[T]HHmmss')
}

export const formatResourceUploadDate = (date) => {
    return moment(date, "DD-MM-YYYY").format('MMM DD, YYYY')
}

export const formatDate = (inputDate) => {
    return moment(inputDate).format('DD MMM YYYY');
};

export const invoiceDate = (date) => {
    return moment(date).format('D MMM YYYY, hh:mm A');
}

export const refId = function (len = 10) {
    return generateUniqueId({
        length: len,
        useLetters: false
    });
}

export const isIfscCodeValid = (ifsc_Code) => {
    let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
    if (ifsc_Code === null) {
        return false;
    }
    if (regex.test(ifsc_Code) === true) {
        return true;
    }
    else {
        return false;
    }
}

export const getGeoLocation = () => {
    return new Promise(async (resolve) => {
        return navigator.geolocation.getCurrentPosition((data) => {
            // resolve({ latitude: data.coords.latitude, longitude: data.coords.longitude });
            resolve(data.coords);
        })
    });
    // console.log("----------", dataLoca)
}
export const biometricDataToJson = (XML) => {
    if (XML) {
        const jsonData = xmlJs.xml2json(XML, { compact: true, spaces: 4 });
        let XMLjson = JSON.parse(jsonData);
        let xmlData = XMLjson.PidData
        let biometricData = {}
        biometricData.encryptedAadhaar = "";
        biometricData.dc = xmlData.DeviceInfo._attributes.dc;
        biometricData.ci = xmlData.Skey._attributes.ci;
        biometricData.hmac = xmlData.Hmac._text;
        biometricData.dpId = xmlData.DeviceInfo._attributes.dpId;
        biometricData.mc = xmlData.DeviceInfo._attributes.mc;
        biometricData.pidDataType = xmlData.Data._attributes.type;
        biometricData.sessionKey = xmlData.Skey._text;
        biometricData.mi = xmlData.DeviceInfo._attributes.mi;
        biometricData.rdsId = xmlData.DeviceInfo._attributes.rdsId;
        biometricData.errCode = xmlData.Resp._attributes.errCode;
        biometricData.errInfo = xmlData.Resp._attributes.errInfo;
        biometricData.fCount = xmlData.Resp._attributes.fCount;
        biometricData.fType = "2";
        biometricData.iCount = "0";
        biometricData.iType = "";
        biometricData.pCount = "0";
        biometricData.pType = "";
        biometricData.srno = xmlData.DeviceInfo.additional_info.Param[0]._attributes.value;
        biometricData.sysid = xmlData.DeviceInfo.additional_info.Param[1]._attributes.value;
        biometricData.ts = xmlData.DeviceInfo.additional_info.Param[2]._attributes.value;
        biometricData.pidData = xmlData.Data._text;
        biometricData.qScore = xmlData.Resp._attributes.qScore;
        biometricData.nmPoints = xmlData.Resp._attributes.nmPoints;
        biometricData.rdsVer = xmlData.DeviceInfo._attributes.rdsVer;
        return biometricData;
    }
}

// export const convertDmtAmount = (amount) => {
//     const noteValue = 5000;
//     const numberOfNotes = Math.floor(amount / noteValue);
//     const remainingAmount = amount % noteValue;
//     console.log("numberOfNotes------",numberOfNotes);
//     console.log("remainingAmount------",remainingAmount);
//     let amtArray = []
//     let i = 0;
//     for (let index = i; index < numberOfNotes; index++) {
//         ++i
//         amtArray[index] = 5000;   
//     }
//     amtArray[i] = remainingAmount;  
//     console.log("amtArray------",amtArray)
//     return amtArray;
// }


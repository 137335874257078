import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../../Utils/AppApi';
// import { APP_KEY, API_URL } from './../config';
// import DataTable from 'react-data-table-component';
import { APP_INFO_URL } from './../../../Utils/appConstants';
// import { getRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
// import Moment from 'react-moment';
// import moment from 'moment';
import { Switch } from 'antd';
// import { getUTCToLocalDateTimeFormat } from '../../../Utils/function';
// const { Panel } = Collapse;
// const { Option } = Select;

function AppInfo(props) {

    // const [getDataField, setDataField] = useState({});
    // const [getDataError, setDataError] = useState({});
    const history = useHistory();
    // const csvLink = useRef()
    // const limit = 10;
    // const dateFormat = 'DD-MM-YYYY';
    const [isDmtLimit, setDmtLimit] = useState(false);
    const [isOtpLogin, setOtpLogin] = useState(false);
    // const [endDate, setEndDate] = useState(null);
    // const [startDate, setStartDate] = useState(null);
    const [getDataInfo, setDataInfo] = useState({});
    // const [getCsvData, setCsvData] = useState([]);
    // const [getTotalRecord, setTotalRecord] = useState(0);
    // const [page, setPage] = useState(1);
    // const [isLimit, setLimit] = useState(limit);

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        console.log('name-----', name)
        console.log('value-----', value)
        setDataInfo({ ...getDataInfo, [name]: value });
    }

    const onChangeSwitch = (checked) => {
        console.log(`switch to ${checked}`);
        setDmtLimit(checked)
        let data = getDataInfo;
        data.dmtTransactionLimit = checked;
        // console.log('data-----', data)
        setDataInfo(data);
      };

      const onChangeOtpLoginSwitch = (checked) => {
        console.log(`switch to ${checked}`);
        setOtpLogin(checked)
        let data = getDataInfo;
        data.isOtpLoginEnable = checked;
        // console.log('data-----', data)
        setDataInfo(data);
      };

    const handleSubmit = () => {
        // console.log("getDataInfo ----------------", getDataInfo)
        postRequest(APP_INFO_URL, getDataInfo).then((response) => {
            console.log("response ----------------", response)
            // setDataField(Object.keys(response));
            if(response.success === true) {
                toast.success(response.message)
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const getAppData = () => {
        getRequest(APP_INFO_URL, history).then((response) => {
            console.log("response ----------------", response)
            // setDataField(Object.keys(response));
            setDmtLimit(response.dmtTransactionLimit);
            setOtpLogin(response.isOtpLoginEnable);
            setDataInfo(response);
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    useEffect(() => {
        console.log("response.data ------=======", getDataInfo)
        // getAppData()
    }, [getDataInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getAppData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="row">
                <div className='card col-md-12 py-3'>
                    <div className='card-body p-0'>
                        <div className="row d-flex">
                            { getDataInfo && getDataInfo.email &&
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="text" className={`form-control normal-light`} name="email" onChange={(e) => onChangeValue(e)} placeholder="Email" value={getDataInfo && getDataInfo.email} />
                                    </div>
                                </div>
                            }
                            { getDataInfo && getDataInfo.helpNumber &&
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <div className="form-group">
                                        <label>Help Number</label>
                                        <input type="text" className={`form-control normal-light`} name="helpNumber" onChange={(e) => onChangeValue(e)} placeholder="helpNumber" value={getDataInfo && getDataInfo.helpNumber} />
                                    </div>
                                </div>
                            }
                            { getDataInfo && getDataInfo.facebook &&
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <div className="form-group">
                                        <label>Facebook</label>
                                        <input type="text" className={`form-control normal-light`} name="facebook" onChange={(e) => onChangeValue(e)} placeholder="facebook" value={getDataInfo && getDataInfo.facebook} />
                                    </div>
                                </div>
                            }
                            { getDataInfo && getDataInfo.youtube &&
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <div className="form-group">
                                        <label>Youtube</label>
                                        <input type="text" className={`form-control normal-light`} name="youtube" onChange={(e) => onChangeValue(e)} placeholder="youtube" value={getDataInfo && getDataInfo.youtube} />
                                    </div>
                                </div>
                            }
                            { getDataInfo && getDataInfo.linkedin &&
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <div className="form-group">
                                        <label>Linkedin</label>
                                        <input type="text" className={`form-control normal-light`} name="linkedin" onChange={(e) => onChangeValue(e)} placeholder="linkedin" value={getDataInfo && getDataInfo.linkedin} />
                                    </div>
                                </div>
                            }
                            { getDataInfo && getDataInfo.mobileVersion &&
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <div className="form-group">
                                        <label>Mobile Version</label>
                                        <input type="text" className={`form-control normal-light`} name="mobileVersion" onChange={(e) => onChangeValue(e)} placeholder="Mobile Version" value={getDataInfo && getDataInfo.mobileVersion} />
                                    </div>
                                </div>
                            }
                            { getDataInfo && getDataInfo.defaultPassword &&
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <div className="form-group">
                                        <label>Default Password</label>
                                        <input type="password" className={`form-control normal-light`} name="defaultPassword" onChange={(e) => onChangeValue(e)} placeholder="Default Password" value={getDataInfo && getDataInfo.defaultPassword} />
                                    </div>
                                </div>
                            }
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <div className="form-group">
                                    <label>DMT Next Trans Time (in Minutes)</label>
                                    <input type="number" className={`form-control normal-light`} name="dmtNextTransactionTime" onChange={(e) => onChangeValue(e)} placeholder="DMT Next Transaction Time" value={getDataInfo && getDataInfo.dmtNextTransactionTime} />
                                </div>
                            </div>
                                
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <div className="form-group">
                                    <label>SRPAY DMT Limit</label>
                                    <div className="text-right"><Switch style={{backgroundColor: isDmtLimit ? 'green' : ''}} checked={isDmtLimit} className={``} onChange={(e) => onChangeSwitch(e)} size="default" /></div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <div className="form-group">
                                    <label>Payout Next Trans Time (in Minutes)</label>
                                    <input type="number" className={`form-control normal-light`} name="payoutNextTransactionTime" onChange={(e) => onChangeValue(e)} placeholder="Payout Next Transaction Time" value={getDataInfo && getDataInfo.payoutNextTransactionTime} />
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <div className="form-group">
                                    <label>Payout Amount Limit</label>
                                    <input type="number" className={`form-control normal-light`} name="payoutTransactionAmountLimit" onChange={(e) => onChangeValue(e)} placeholder="Payout Amount Limit" value={getDataInfo && getDataInfo.payoutTransactionAmountLimit} />
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <div className="form-group">
                                    <label>OTP Login Enable</label>
                                    <div className="text-right"><Switch style={{backgroundColor: isOtpLogin ? 'green' : ''}} checked={isOtpLogin} className={``} onChange={(e) => onChangeOtpLoginSwitch(e)} size="default" /></div>
                                </div>
                            </div>
                        </div>
                        <div className="float-right"><button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Submit</button></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppInfo
import React, { useEffect, useState, useRef } from 'react';
import { Switch, Tooltip} from 'antd';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { ADMIN_GET_ALL_APPROVED_USERS, FING_PAY_KYC_STATUS_CHANGED } from './../../../Utils/appConstants';
import { getRequest, postRequest } from './../../../Utils/AppApi';
import { hideCharters, ekoOnboardingStatus, FundRequestValueCSV } from './../../../Utils/Common';
import moment from 'moment';
import { getUTCToLocalDateFormat } from '../../../Utils/function';
import { toast } from 'react-toastify';
// import Moment from 'react-moment';

function App(props) {
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const getMode = props.mode ? props.mode : "";
    const startDate = props.startDate ? props.startDate : "";
    const endDate = props.endDate ? props.endDate : "";
    const search = props.search ? props.search : "";

    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [isLimit, setLimit] = useState(limit)
    const [page, setPage] = useState(1);

    const columns = [{
        name: 'ID Created',
        cell: row => getUTCToLocalDateFormat(row.createdAt),
        width: "120px"
    }, {
        name: 'User Id',
        selector: row => <><Tooltip placement="bottom" title={row.user.username}>{row.user.username}</Tooltip></>,
        sortable: true,
        width: "120px"
    }, {
        name: 'User Type',
        selector: row => <Tooltip placement="bottom" title={row.role.name}>{row.user.isStaff ? "STAFF" : row.role.name}</Tooltip>,
        width: "120px",
    }, {
        name: 'Pan No.',
        selector: row => <Tooltip placement="bottom" title={hideCharters(row.pancardNumber)}>{hideCharters(row.pancardNumber)}</Tooltip>,
        width: "120px"
    }, {
        name: 'Name',
        selector: row => <Tooltip placement="bottom" title={row.user.name}>{row.user.name}</Tooltip>,
        width: "150px"
    }, {
        name: 'Mobile',
        selector: row => hideCharters(row.mobile),
        width: "120px"
    }, {
        name: 'Parent ID',
        selector: row => <><Tooltip placement="bottom" title={row.userParentDetail && row.userParentDetail.username}>{row.userParentDetail && row.userParentDetail.username}</Tooltip></>,
        width: "150px"
    }, {
        name: 'FP Status',
        cell: row => <>{(row.fingusers.length > 0 && row.fingusers[0].merchantBioMetricStatus) ? <div className="text-success">Success</div> : <div className="text-warning">Pending</div>}</>,
        width: "120px"
    }, {
        name: 'FP ID',
        cell: row => <>{row.fingusers.length > 0 ? row.fingusers[0].merchantLoginId : "-"}</>,
        width: "100px"
    }, {
        name: 'FP Date',
        cell: row => row.fingusers.length > 0 ? getUTCToLocalDateFormat(row.fingusers[0].createdAt) : "-",
        width: "120px"
    }, {
        name: 'FP Service',
        cell: row => row.fingusers.length > 0 ? <Switch checked={row.fingusers[0].isCompleted} onChange={(e) => handleFingPayCompleted(e, row.fingusers[0]._id)}></Switch> : "-",
        width: "120px"
    }, {
        name: 'IP Status',
        cell: row => <>{(row.outletId) ? <div className="text-success">Success</div> : <div className="text-warning">Pending</div>}</>,
        width: "120px"
    }, {
        name: 'IP ID',
        cell: row => <>{row.outletId}</>,
        width: "100px"
    }, {
        name: 'IP Date',
        cell: row => getUTCToLocalDateFormat(row.createdAt),
        width: "120px"
    }, {
        name: 'E Status',
        cell: row => <>{row.ekoOnboardStatus ? ekoOnboardingStatus(row.ekoOnboardStatus) : '-'}</>,
        width: "120px"
    }, {
        name: 'E ID',
        cell: row => <>{row.ekoOnboardCode ? row.ekoOnboardCode : "-"}</>,
        width: "120px"
    }, {
        name: 'E Date',
        cell: row => row.ekoOnboardDate ? getUTCToLocalDateFormat(row.ekoOnboardDate) : "-",
        width: "120px"
    }]

    const handleFingPayCompleted = (checked, data) => {
        // setIsCreditUser(checked)
        console.log(`data to ${data}`);
        console.log(`switch to ${checked}`);
        let payload = { id : data, status : checked }
        postRequest(FING_PAY_KYC_STATUS_CHANGED, payload, history).then((response) => {
            if(response.status === true) {
                toast.success(response.message);
            }
            getDataReports()
        })
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if(data) {
            setLimit(data)
            params.limit = data
        }
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        params.roleId = getMode;

        if (search) {
            params.search = search ? search : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${ADMIN_GET_ALL_APPROVED_USERS}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        params.roleId = getMode;

        if (search) {
            params.search = search ? search : "";
        }
  
        const queryString = objectToQueryString(params);
        getRequest(`${ADMIN_GET_ALL_APPROVED_USERS}?${queryString}`, history).then((response) => {
          if (response.success === true) {
            let csvdataRecord = [];
            let csvdata = response.data;
            csvdata.map((e) => {
              return csvdataRecord.push({ "Date": moment(e.createdAt).format("DD/MM/YYYY"), "User Id": e.user.username, "Name": e.user.name, "Mobile": e.mobile, "User Type": e.role.name, "Parent ID": e.userParentDetail && e.userParentDetail.username, "Parent Name": e.userParentDetail && e.userParentDetail.name,  "Outlet ID": e.outletId, "Outlet Name": e.outletName, "KYC Status": FundRequestValueCSV(e.user.KYCStatus), "Reason": e.rejectReason,});
            });
            setCsvData(csvdataRecord);
            csvLink.current.link.click()
          } else {
            setCsvData([]);
          }
        }).catch(function (error) {
          console.log(error)
          // setLoading(false);
        })
      } 

    const handlePageChange = page => {
        setPage(page);
    };

    useEffect(() => {
        getDataReports();
    }, [page, props.handleSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
        // setIsDownloadCsv(isDownloadCsv ? true : false);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <div className="container-fluid pt-2 pb-5">
                <div className="col-md-12">
                    <div className='float-sm-right d-flex mb-1'>
                        <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                        <CSVLink
                            data={getCsvData}
                            filename='E-kyc-verified.csv'
                            className='hidden'
                            ref={csvLink}
                            target='_blank'
                        />
                        {/* <button type="button" className="btn btn-danger">
                            <i className="fa fa-file-pdf"></i> Export to PDF
                        </button> */}
                    </div>
                    <div className="box-body">
                        <DataTable
                            // title={getMode}
                            columns={columns}
                            data={getData.docs}
                            highlightOnHover
                            pagination
                            paginationServer
                            striped
                            paginationTotalRows={getTotalRecord}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App
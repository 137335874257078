import React, { useEffect, useState } from 'react';
// import { APP_KEY, API_URL } from './../config';
import DataTable from 'react-data-table-component';
import { GET_AEPS_HISTORY_LIST } from './../../Utils/appConstants';
import { getRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
// import Moment from 'react-moment';
// import moment from 'moment';
import { DatePicker, Tooltip, Select } from 'antd';
import {getUTCToLocalDateTimeFormat, queryDate, toDayDate } from '../../Utils/function';
import { aepsServiceInArray } from '../../Utils/Common';

function AepsHistory(props) {

    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';
    const [searchTransId, setSearchTransId] = useState(null);
    const [searchMobile, setSearchMobile] = useState(null);
    const [searchServiceName, setSearchServiceName] = useState(null);
    const [searchAadhar, setSearchAadhar] = useState(null);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };
        },
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.transactionId && row.transactionId}>{row.transactionId && row.transactionId}</Tooltip>,
        width: "150px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };
        },
    }, {
    //     name: 'Reference ID',
    //     selector: row => row.userData && row.userData.username,
    //     width: "120px",
    //     headerStyle: (selector, id) => {
    //         return { textAlign: "center" };   // removed partial line here
    //     },
    // }, {
        name: 'Service Name',
        selector: row => <Tooltip placement="bottom" title={row.mode}>{row.mode}</Tooltip>,
        width: "180px",
        headerStyle: (selector, id) => {
            return { textAlign: "center", wordWrap: "break-word" };   // removed partial line here
        },
    }, {
    //     name: 'Name',
    //     selector: row => <Tooltip placement="bottom" title={row.userData && row.userData.name}>{row.userData && row.userData.name}</Tooltip>,
    //     width: "200px",
    //     headerStyle: (selector, id) => {
    //         return { textAlign: "center" };   // removed partial line here
    //     },
    // }, {
        name: 'Bank',
        selector: row => (!row.bankName) ? "-" : row.bankName,
        width: "130px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Aadhaar',
        selector: row => (!row.aadhaar) ? "-" : "XXXXXXXX"+row.aadhaar,
        width: "130px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Mobile',
        selector: row => row.mobile,
        width: "140px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Amount',
        selector: row => row.amount ? row.amount : 0,
        width: "100px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Bank RRN',
        selector: row => row.operatorId,
        width: "130px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Status',
        selector: row => (row.status === 1) ? "Success" : "Failed",
        width: "150px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Message',
        selector: row => <Tooltip placement="bottom" title={row.message && row.message}>{row.message && row.message}</Tooltip>,
        width: "200px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchTransId = searchTransId ? searchTransId : "";
        params.searchMobile = searchMobile ? searchMobile : "";
        params.searchServiceName = searchServiceName ? searchServiceName : "";
        params.searchAadhar = searchAadhar ? searchAadhar : "";

        const queryString = objectToQueryString(params);
        getRequest(`${GET_AEPS_HISTORY_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page, size) => {
        console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setSearchAadhar('')
        setSearchTransId('')
        setSearchMobile('')
        setSearchServiceName(null);
        setStartDate('');
        setEndDate('');
    }

    // useEffect(() => {
    //     handleSearch();
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="row">
                <div className='col-md-12 date-search mb-0'>
                    <div className='row'>
                        <div className='col-md-2 col-lg-2'>
                            <div className="form-group">
                                <label className='date-search-label mb-0 text-blue'>From</label>
                                <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                            </div>
                        </div>
                        <div className='col-md-2 col-lg-2'>
                            <div className="form-group">
                                <label className='date-search-label mb-0 text-red'>To</label>
                                {/* <input type="text" className="form-control form-control-border" placeholder="27 September 2022" /> */}
                                <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                            </div>
                        </div>
                        <div className='col-md-3 col-lg-3 d-flex'>
                            <div className="form-group mr-3">
                                <label className='date-search-label mb-0 text-red'></label>
                                <input type="text" className="form-control form-control-border" placeholder="Aadhar Number" onChange={(e) => setSearchAadhar(e.target.value)} value={searchAadhar} />
                            </div>
                            <div className="form-group">
                                <label className='date-search-label mb-0 text-red'></label>
                                <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                            </div>
                        </div>
                        <div className='col-md-3 col-lg-3 d-flex'>
                            <div className="form-group mr-3">
                                <label className='date-search-label mb-0 text-red'></label>
                                <input type="text" className="form-control form-control-border" placeholder="Mobile Number" onChange={(e) => setSearchMobile(e.target.value)} value={searchMobile} />
                            </div>
                            <div className="form-group">
                                <label className='date-search-label mb-0 text-red'></label>
                                <Select value={searchServiceName} options={aepsServiceInArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Service Name --" name='mode' onChange={(e) => setSearchServiceName(e)} />
                                {/* <input type="text" className="form-control form-control-border" placeholder="Service Name" onChange={(e) => setSearchServiceName(e.target.value)} value={searchServiceName} /> */}
                            </div>
                        </div>
                        <div className='col-md-4 col-lg-1'>
                            <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                            <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                        </div>
                        <div className='col-md-4 col-lg-1'>
                            <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                            <button type="button" className="btn btn-default rounded-pill ml-3 pl-4 pr-4 pull-right" onClick={() => handleClear()}>Reset</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-2 pb-5">
                <DataTable
                    title={""}
                    columns={columns}
                    data={getData.docs}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={getTotalRecord}
                    onChangeRowsPerPage={getDataReports}
                    onChangePage={handlePageChange}
                />
            </div>
        </div>
    )
}

export default AepsHistory
import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { getRequest } from '../Utils/AppApi';
import { GET_ADVERTISEMENT_LIST_API } from '../Utils/appConstants';

function DemoCarousel() {

    const [getData, setData] = useState([]);

    useEffect(() => {
        getDataReports();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getDataReports = () => {
        getRequest(`${GET_ADVERTISEMENT_LIST_API}`).then((response) => {
            if (response.success === true) {
                setData(response.data);
            } else {
                setData([]);
            }
        }).catch(function (error) {
            console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} >
            {getData && getData.map((element) => {
                return <div>
                    <img src={element.image} alt={element.name} />
                </div>
            })}
        </Carousel>
    );

};

export default DemoCarousel;
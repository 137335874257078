import React, { useEffect, useState } from 'react';

import { GET_MYPROFILE, UPLOAD_FILE, UPDATE_USER_PROFILE, GST_IN_VERIFY, MOBILE_SEND_OTP, MOBILE_SEND_VERFIY, CHANGE_PASSWORD, SAVE_MPIN_NUMBER } from './../Utils/appConstants';
import { getRequest, postRequest, putRequest } from '../Utils/AppApi';
// import AppHeader from './Header/Header';
import { isNumber, getUser, hideCharters } from '../Utils/Common';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import { passwordStrength } from 'check-password-strength';
// import CheckPassword from './checkPassword'
import { Modal } from 'antd';

function AccountSettings(props) {
  const history = useHistory();
  const userData = getUser();
  const [getData, setGetData] = useState({});
  const [isProfileCheck, setIsProfileCheck] = useState(false);
  const [isMpinModel, setMpinModel] = useState(false);
  const [isMobileNumber, setMobileNumber] = useState(null);
  const [isOtpNumberValue, setOtpNumberValue] = useState(null);
  const [isNewPassword, setNewPassword] = useState(null);
  const [isConfirmPassword, setConfirmPassword] = useState(null);
  const [isMobileNumberVerify, setMobileNumberVerify] = useState(true);
  const [isMpinNumber, setMpinNumber] = useState(null);
  const [isConfirmMpinNumber, setConfirmMpinNumber] = useState(null);
  const [strength, setStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [isOtpNumber, setOtpNumber] = useState(false);
  const [isChangePassword, setChangePassword] = useState(false);

  const [isLowUpper, setLowUpper] = useState(false);
  const [isPassNumber, setPassNumber] = useState(false);
  const [isSpecial, setSpecial] = useState(false);
  const [isEightPassword, setEightPassword] = useState(false);

  const getProfile = () => {
    getRequest(GET_MYPROFILE, history).then((response) => {
      if (response.success === true) {
        setGetData(response.data[0].userdetail);
        // console.log("response.data ========", response.data[0].userdetail)
        setIsProfileCheck(true);
      } else {
        toast.error(response.message);
        setIsProfileCheck(false);
      }
    }).catch(function (error) {
      // console.log(error)
    })
  }

  const handleUpdateProfile = (image) => {
    const payload = {
      outletName: getData.outletName,
      email: getData.email,
      whatsapp: getData.whatsapp,
      mobile: getData.mobile,
      communicationAddress: getData.communicationAddress,
      businessAddress: getData.businessAddress,
      registeredAddress: getData.registeredAddress,
      gstInPhoto: image ? image : getData.gstInPhoto,
    };
    putRequest(`${UPDATE_USER_PROFILE}/${getData._id}`, payload).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      // console.log(error)
      // setLoading(false);
    })
  }

  const handleVerifyGSTIn = () => {
    const payload = { gstin: getData.gstInNumber };
    postRequest(`${GST_IN_VERIFY}`, payload, history).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      // console.log(error)
      // setLoading(false);
    })
  }


  const handleFileChange = function (event, name) {
    // console.log("name ------", name);
    // console.log("event.target.files[0] ------", event.target.files[0]);
    event.preventDefault();
    const data = new FormData();
    data.append('avatar', event.target.files[0], event.target.files[0].name);
    postRequest(UPLOAD_FILE, data, history).then(async (response) => {
      if (response.success === true) {
        // await setGetData({ ...getData, [name]: response.data.fullpath });
        await handleUpdateProfile(response.data.fullpath);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      // console.log(error);
    });
  } // eslint-disable-line react-hooks/exhaustive-deps

  const handleHideModal = () => {
    setMpinModel(false);
    setOtpNumber(false);
    setMobileNumberVerify(false);
    setChangePassword(false);
    setNewPassword(null);
    setConfirmPassword(null);
  }

  const onChangeValue = (event) => {
    const { name, value } = event.target;
    setGetData({ ...getData, [name]: value });
  }

  const FormValidate = () => {
    let formValid = true;
    let formObject = {};

    if (isMobileNumber === "" || typeof isMobileNumber === "undefined") {
      formObject.userRole = true;
      formValid = false;
      toast.error("Mobile number is required");
    }

    return formValid;
  } // eslint-disable-line react-hooks/exhaustive-deps

  const handleVerifyMobileNumber = () => {
    if (FormValidate()) {
      const payload = {
        mobile: isMobileNumber
      };
      postRequest(MOBILE_SEND_OTP, payload, history).then((response) => {
        if (response.success === true) {
          toast.success(response.message);
          setOtpNumber(true)
        } else {
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      })
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  const handleVerifyOtpMobileNumber = () => {
    const payload = {
      mobile: isMobileNumber,
      otp: isOtpNumberValue
    }
    postRequest(MOBILE_SEND_VERFIY, payload, history).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
        setOtpNumber(true)
        setMobileNumberVerify(true)
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      // console.log(error)
      // setLoading(false);
    })
  } // eslint-disable-line react-hooks/exhaustive-deps

  const handleMpinSubmit = () => {
    if (isConfirmMpinNumber === isMpinNumber) {
      const payload = {
        mpin: isMpinNumber,
      }
      postRequest(SAVE_MPIN_NUMBER, payload, history).then((response) => {
        if (response.success === true) {
          toast.success(response.message);
          setMobileNumber("");
          setConfirmMpinNumber("");
          handleHideModal()
        } else {
          setConfirmMpinNumber("");
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        setConfirmMpinNumber("");
      })
    } else {
      // setMpinNumber(password);
      setConfirmMpinNumber("");
      toast.error("New Mpin and Confirm Mpin did not match");
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  const FormValidateChangePassword = () => {
    let formValid = true;
    // let formObject = {};
    if (!isNewPassword || isNewPassword === "" || typeof isNewPassword === "undefined") {
      formValid = false;
      toast.error("New password is required");
    } else if(strength < 4) {
      formValid = false;
      toast.error("Password strength is not good");
    } else if (!isConfirmPassword || isConfirmPassword === "" || typeof isConfirmPassword === "undefined") {
      formValid = false;
      toast.error("Confirm password is required");
    } else if (isConfirmPassword !== isNewPassword) {
      formValid = false;
      toast.error("Confirm password did not match");
    }

    return formValid;
  } // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePasswordSubmit = () => {
    if (FormValidateChangePassword()) {
      const payload = {
        newPassword: isNewPassword,
        confirmPassword: isConfirmPassword,
      }
      postRequest(CHANGE_PASSWORD, payload, history).then((response) => {
        if (response.success === true) {
          toast.success(response.message);
          handleHideModal()
        } else {
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      })
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const checkStrength = (password) => {
    let strength = 0;
    // If password contains both lower and uppercase characters
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      setLowUpper(true);
      strength += 1;
    } else {
      setLowUpper(false);
    }
    // If it has numbers and characters
    if (password.match(/([0-9])/)) {
      setPassNumber(true);
      strength += 1;
    } else {
      setPassNumber(false);
    }
    // If it has one special character
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      setSpecial(true)
      strength += 1;
    } else {
      setSpecial(false)
    }
    // If password is greater than 7
    if (password.length > 7) {
      setEightPassword(true)
      strength += 1;
    } else {
      setEightPassword(false)
    }
    // console.log('strength-----------', strength)
    setStrength(strength);
  };

  const changePassword = (value) => {
    // console.log('-----------', passwordStrength(value).value)
    // console.log('strength-----------', strength)
    checkStrength(value);
    setNewPassword(value);
  }
  const getStrengthColor = () => {
    if (strength < 2) {
      return 'progress-bar bg-danger';
    } else if (strength === 3) {
      return 'progress-bar bg-warning';
    } else if (strength === 4) {
      return 'progress-bar bg-success';
    }
    return 'progress-bar bg-danger';
  }

  const getStrengthPercentage = () => {
    return (strength / 4) * 100;
  }

  useEffect(() => {
    getStrengthPercentage();
  }, [strength]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getProfile();
  }, [isProfileCheck]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="content-wrapper pt-2">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-0 align-items-center">
              <div className="col-sm-6">
                <h1 className='page-title text-blue font-merriweather'>Account <span className='text-red'> Settings</span></h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid pt-2 pb-5">
            <div className="row">
              <div className="col-md-6">
                <div className='card rounded-0'>
                  <div className='card-body'>
                    <div className="form-group">
                      <label>Pan Number</label>
                      <div className="input-group">
                        <div className="custom-file">
                          <input type="text" className="form-control" placeholder="Pancard Number" value={getData && getData.pancardNumber && hideCharters(getData.pancardNumber)} disabled />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Outlet Name</label>
                      <div className="input-group">
                        <div className="custom-file">
                          <input type="text" className="form-control" name="outletName" placeholder="Outlet Name" defaultValue={getData && getData.outletName} disabled={userData.data.userDetails.gstInNumberVerify ? true : false} onChange={(e) => onChangeValue(e)} />
                        </div>
                        {userData.data && !userData.data.userDetails.gstInNumberVerify && <div className="input-group-append uploadbtn cursor-pointer" onClick={() => handleUpdateProfile()}>
                          <span className="input-group-text text-blue">Update</span>
                        </div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>What's App Number</label>
                      <div className="input-group">
                        <div className="custom-file">
                          <input type="text" className="form-control" name="whatsapp" placeholder="Whatsapp Number" defaultValue={getData && getData.whatsapp} onChange={(e) => onChangeValue(e)} />
                        </div>
                        <div className="input-group-append uploadbtn cursor-pointer" onClick={() => handleUpdateProfile()}>
                          <span className="input-group-text text-blue">Update</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Registered Mobile Number</label>
                      <div className="input-group">
                        <div className="custom-file">
                          <input type="text" className="form-control" name="mobile" placeholder="Mobile Number" defaultValue={getData && getData.mobile} onChange={(e) => onChangeValue(e)} />
                        </div>
                        <div className="input-group-append uploadbtn cursor-pointer" onClick={() => handleUpdateProfile()}>
                          <span className="input-group-text text-blue">Update</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Email ID</label>
                      <div className="input-group">
                        <div className="custom-file">
                          <input type="text" className="form-control" name="email" placeholder="Email Id" defaultValue={getData && getData.email} onChange={(e) => onChangeValue(e)} />
                        </div>
                        <div className="input-group-append uploadbtn cursor-pointer" onClick={() => handleUpdateProfile()}>
                          <span className="input-group-text text-blue">Update</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Update GST Detail</label>
                      <div className="input-group">
                        <div className="custom-file">
                          <input type="text" className="form-control" name="gstInNumber" placeholder="Enter your GST Number" value={getData && getData.gstInNumber} disabled={userData.data.userDetails.gstInNumberVerify ? true : false} onChange={(e) => onChangeValue(e)} />
                        </div>
                        {userData.data && !userData.data.userDetails.gstInNumberVerify ? <div className="input-group-append uploadbtn cursor-pointer" onClick={() => handleVerifyGSTIn()}>
                          <span className="input-group-text">Verify</span>
                        </div> : <div className="input-group-append uploadbtn cursor-pointer">
                          <span className="input-group-text">Re-Verifed</span>
                        </div>}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>GST Certificate Upload</label>
                      <div className="input-group">
                        <div className="custom-file">
                          <input type="file" className="custom-file-input" onChange={(event) => handleFileChange(event, "gstInPhoto")} />
                          <label className="custom-file-label">Choose file</label>
                        </div>
                        <div className="input-group-append uploadbtn cursor-pointer">
                          <span className="input-group-text">Upload</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className='card rounded-0'>
                  <div className='card-body'>
                    <div className="form-group">
                      <label>Communication Address</label>
                      <textarea className='form-control normal-dark' name="communicationAddress" onChange={(e) => onChangeValue(e)} rows={"4"} defaultValue={getData && getData.communicationAddress}></textarea>
                    </div>
                    <div className="form-group">
                      <label>Business Address</label>
                      <textarea className='form-control normal-dark' readOnly name="businessAddress" onChange={(e) => onChangeValue(e)} rows={"4"} defaultValue={getData && getData.businessAddress} ></textarea>
                    </div>
                    <div className="form-group">
                      <label>Registered Address </label>
                      <textarea className='form-control normal-dark' readOnly name="registeredAddress" onChange={(e) => onChangeValue(e)} rows={"4"} defaultValue={getData && getData.registeredAddress}></textarea>
                    </div>

                    {getData && <div className='pt-4 pb-4'>
                      <div className="text-right" style={{ float: "right" }}>
                        <button type="submit" className="btn btn-primary btn-shadow rounded-pill pl-4 pr-4 mr-3 mb-3" onClick={() => handleUpdateProfile()}>Submit</button>
                      </div>
                      <div>
                        <button type="button" className="btn btn-danger btn-shadow rounded-pill pl-4 pr-4 mr-3 mb-3" onClick={() => setMpinModel(true)}>Change mPIN</button>
                        {/* <button type="submit" className="btn btn-warning btn-shadow rounded-pill pl-4 pr-4 mr-3 mb-3">Reset mPIN</button> */}
                        {/* <button type="submit" className="btn btn-primary btn-shadow rounded-pill pl-4 pr-4 mr-3 mb-3">Bank Setting</button> */}
                        <button type="button" className="btn btn-warning btn-shadow rounded-pill pl-4 pr-4 mr-3 mb-3" onClick={() => setChangePassword(true)}>Change Password</button>
                      </div>

                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={10000}
          limit={3}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>

      {isMpinModel && <Modal title={"Change MPIN"} width={600} open={isMpinModel} onOk={""} onCancel={handleHideModal} footer={null}>
        {isMobileNumberVerify ?
          <>
            <div className='row'>
              <div className='col-md-12 form-group'>
                <label>New Mpin: </label>
                <input type={'text'} className="form-group form-control" onKeyPress={isNumber} onChange={(e) => setMpinNumber(e.target.value)} maxLength={4} value={isMpinNumber} />
              </div>
              <div className='col-md-12 form-group'>
                <label>Confirm Mpin: </label>
                <input type={'text'} className="form-group form-control" onChange={(e) => setConfirmMpinNumber(e.target.value)} maxLength={4} value={isConfirmMpinNumber} onKeyPress={isNumber} />
              </div>
              <div className='col-md-12 form-group text-right'>
                <button type='button' onClick={() => handleMpinSubmit()} className='btn btn-sm btn-primary'>Submit</button>
              </div>
            </div>
          </>
          :
          <><div className='row'>
            <div className='col-md-12 form-group'>
              <label>Mobile Number: </label>
              <input type={'text'} className="form-group form-control" onChange={(e) => setMobileNumber(e.target.value)} maxLength={10} readOnly={isOtpNumber ? true : false} value={isMobileNumber} onKeyDown={isNumber} />
            </div>
            {isOtpNumber && <div className='col-md-12 form-group'>
              <label>OTP Number: </label>
              <input type={'text'} className="form-group form-control" onChange={(e) => setOtpNumberValue(e.target.value)} maxLength={4} value={isOtpNumberValue} onKeyDown={isNumber} />
            </div>}
            <div className='col-md-12 form-group text-right'>
              <button type='button' onClick={() => handleHideModal()} className='btn btn-sm btn-default mr-2'>Cancel</button>
              {isOtpNumber ?
                <><button type='button' onClick={() => handleVerifyOtpMobileNumber()} className='btn btn-sm btn-primary'>Verify</button></>
                :
                <><button type='button' onClick={() => handleVerifyMobileNumber()} className='btn btn-sm btn-primary'>Send</button></>
              }
            </div>
          </div></>}
      </Modal>}

      {isChangePassword && <Modal title={"Change Password"} width={600} open={isChangePassword} onOk={""} onCancel={handleHideModal} footer={null}>
        <div className='row container'>
          <div className='col-md-12 form-group'>
            <label>New Password:</label>
            <div class="input-group">
              <input type={showPassword ? 'text' : 'password'} className="form-group form-control" onChange={(e) => changePassword(e.target.value)} maxLength={20} value={isNewPassword} />
              <div className="input-group-addon-eye" onClick={togglePasswordVisibility}>
                <i style={{ marginTop : "10px"}} className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} />
              </div>
            </div>
            
            {strength > 0 && <><div className="progress m-2">
              <div className={`${getStrengthColor()}`} role="progressbar" style={{ width: getStrengthPercentage() + '%' }} aria-valuenow={25} aria-valuemin={strength} aria-valuemax={100}>{getStrengthPercentage()}%</div>
            </div></>}
            <ul class="list-unstyled pt-2">
              <li class={isLowUpper ? "text-success" : "text-danger"}>
                <span class="low-upper-case">
                  {isLowUpper ? <i>✓</i> : '1.'}&nbsp;Lowercase &amp; Uppercase characters
                </span>
              </li>
              <li class={isPassNumber ? "text-success" : "text-danger"}>
                <span class="one-number">
                  {isPassNumber ? <i>✓</i> : '2.'}&nbsp;Number (0-9)
                </span>
              </li>
              <li class={isSpecial ? "text-success" : "text-danger"}>
                <span class="one-special-char">
                  {isSpecial ? <i>✓</i> : '3.'}&nbsp;Special Character (!@#$%^&*)
                </span>
              </li>
              <li class={isEightPassword ? "text-success" : "text-danger"}>
                <span class="eight-character">
                  {isEightPassword ? <i>✓</i> : '4.'}&nbsp;Atleast 8 Character
                </span>
              </li>
            </ul>
          </div>
          <div className='col-md-12 form-group'>
            <label>Confirm Password: </label>
            <input type={'password'} className="form-group form-control" onChange={(e) => setConfirmPassword(e.target.value)} maxLength={15} value={isConfirmPassword} />
          </div>
          <div className='col-md-12 form-group text-right'>
            <button type='button' onClick={() => handleChangePasswordSubmit()} className='btn btn-sm btn-primary'>Submit</button>
          </div>
        </div>
      </Modal>}
    </div>
  )
}

export default AccountSettings
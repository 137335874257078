import React, { useEffect, useState, useRef } from 'react';
// import { APP_KEY, API_URL } from './../config';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
// import { Tabs, Tab } from 'react-bootstrap-tabs';
import { GET_ALL_CHANNEL_WALLET_LEDGER_REPORTS, GET_MY_CHILD_USERS } from './../../Utils/appConstants';
import { getRequest } from './../../Utils/AppApi';
// import AppHeader from './../Header/Header';
import { useHistory } from "react-router-dom";
// import { /* ToastContainer,*/ toast } from 'react-toastify';
import { getUser, INRFormat } from './../../Utils/Common';
// import AppFooter from './../Footer/Footer';
// import Moment from 'react-moment';
// import moment from 'moment';

import { DatePicker, Select } from 'antd';
import { getExportFormat, getUTCToLocalDateTimeFormat } from '../../Utils/function';

const user = getUser();

function ChannelWalletLedger(props) {

    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';
    const [isLimit, setLimit] = useState(limit)
    // const [search, setSearch] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [myUsers, setMyUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null)

    const columns = [{
        name: 'Date',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px",
    }, {
        name: 'Description',
        selector: row => row.transferMode,
        width: "180px",
        headerStyle: () => {
            return { width: "380px", textAlign: "center" };
        }
    }, {
        name: 'FTR No.',
        selector: row => row.FTRReferenceNumber,
        width: "160px",
    }, {
        name: 'Name',
        selector: row => row.fromUserDetails.name,
    }, {
        name: 'Parent ID',
        selector: row => row.fromUserDetails.username,
    }, {
        name: 'Debit',
        selector: row => (!row.debit) ? "-" : INRFormat(row.debit),
    }, {
        name: 'Credit',
        selector: row => (!row.credit) ? "-" : INRFormat(row.credit),
    }, {
        name: 'Closing Balance',
        selector: row => INRFormat(row.closingBalance),
    }, {
        name: 'Payment Mode',
        cell: row => row.paymentMode,
    }]

    const getMyUser = () => {
        getRequest(GET_MY_CHILD_USERS, history).then((response) => {
            if (response.success === true) {
                setMyUsers(response.data.map((item) => {
                    let name = item.username + ' - ' + item.name + ' - ' + item.rolecode;
                    return { "value": item._id, "label": name };
                }));
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };
    const getDataReports = (data) => {

        if (data) {
            setLimit(data)
            params.limit = data
        }

        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        if (selectedUser) {
            params.userId = selectedUser;
        }

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ALL_CHANNEL_WALLET_LEDGER_REPORTS}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        if (user.data.role.code !== "P") {
            params.userId = selectedUser;
        }

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ALL_CHANNEL_WALLET_LEDGER_REPORTS}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date": getExportFormat(e.createdAt),
                        "Description": e.transferMode,
                        "FTR No.": e.FTRReferenceNumber,
                        "Name": e.fromUserDetails.name,
                        "Parent ID": e.fromUserDetails.username,
                        "Debit": e.debit,
                        "Credit": e.credit,
                        "Closing Balance": e.closingBalance,
                        "Payment Mode": e.paymentMode,
                        "Remark": e.remark
                    });
                });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(dateString)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(dateString)
    };

    const onChangeValueSelect = (event) => {
        // const { value } = event.target;
        console.log("-----------", event)
        setSelectedUser(event);
    }

    const handleSearch = () => {
        getDataReports();
    }
    useEffect(() => {
        if (user.data.role.code !== "P") {
            getMyUser()
        }
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedUser) {
            getDataReports();
        } else {
            getDataReports();
        }
        // if(user.data.role.code === "P") {
        // getDataReports();
        // }
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Channel Wallet <span className='text-red'>Ledger</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className='card col-md-12'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className='col-md-10 date-search mb-0'>
                                        <div className='row'>
                                            {(user.data.role.code !== "P") && <div className='col-sm-6 col-lg-4'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-blue'>Select User</label>
                                                    <Select options={myUsers} className={`normal-light w-100`} showSearch filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    } classNamePrefix="select" placeholder="Select " onChange={(e) => onChangeValueSelect(e)} />
                                                </div>
                                            </div>}
                                            <div className='col-sm-3 col-lg-2'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-blue'>From</label>
                                                    <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
                                                </div>
                                            </div>
                                            <div className='col-sm-3 col-lg-2'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'>To</label>
                                                    {/* <input type="text" className="form-control form-control-border" placeholder="27 September 2022" /> */}
                                                    <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
                                                </div>
                                            </div>
                                            <div className='col-sm-4 col-lg-2'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className='float-sm-right'>
                                            <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                            <CSVLink
                                                data={getCsvData}
                                                filename='khatabook-reports'
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                            {/* <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf"></i> Export to PDF</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid pt-2 pb-5">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <AppFooter /> */}
        </div>
    )
}

export default ChannelWalletLedger